import axios from "axios";
import React, { useEffect, useState } from "react";
import AXIOS from "../utils/AXIOS";
import { engagementStatus } from "../utils/Constants";

function useContactLoading(
  pageNumber,
  LOGIN_ID,
  selectedSearchKey,
  searchValue,
  setPageNumber,
  dashboardQuery,
  selectedEngagementStatus,
  isAllSelected
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [ContactList, setContactList] = useState([]);

  useEffect(() => {
    setContactList([]);
  }, []);
  useEffect(() => {
    if (LOGIN_ID) {
      setLoading(true);
      setError(false);
      let cancel;
      axios({
        method: "POST",
        url: AXIOS.defaultPort + AXIOS.contactDataGet,
        data: {
          PAGE_NUMBER: pageNumber,
          SEARCH_KEY: selectedSearchKey,
          SEARCH_VALUE: searchValue,
          START_DATE: dashboardQuery.START_DATE,
          END_DATE: dashboardQuery.END_DATE,
          AUTH_ID: LOGIN_ID,
          ENGAGEMENT_STATUS: selectedEngagementStatus,
        },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          if (res.data.value.length > 0) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          let allContactList = [];
          if (pageNumber == 1) {
            allContactList = [...res.data.value];
          } else {
            allContactList = [...ContactList, ...res.data.value];
          }

          let uniqueContacts = [];
          allContactList.map((val) => {
            let index = uniqueContacts.findIndex((item) => {
              return item._id == val._id;
            });
            if (index == -1) {
              uniqueContacts.push({
                ...val,
              });
            }
          });
          let temp = uniqueContacts.filter((val) => val.isRemoved == "No");
          temp.map((val) => {
            val.checked = isAllSelected;
            val.ID = val.ID.toString();
            val.ENGAGEMENT_STATUS =
              engagementStatus.find(
                (item) => item.value == val.engagementStatus
              )?.label || "";
          });

          setContactList(temp);

          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setLoading(false);
          setError(true);
        });
      return () => cancel();
    }
  }, [pageNumber, LOGIN_ID]);

  useEffect(() => {
    if (LOGIN_ID) {
      setContactList([]);
      setLoading(true);
      setError(false);

      let cancel;
      axios({
        method: "POST",
        url: AXIOS.defaultPort + AXIOS.contactDataGet,
        data: {
          PAGE_NUMBER: 1,
          SEARCH_KEY: selectedSearchKey,
          SEARCH_VALUE: searchValue,
          START_DATE: dashboardQuery.START_DATE,
          END_DATE: dashboardQuery.END_DATE,
          AUTH_ID: LOGIN_ID,
          ENGAGEMENT_STATUS: selectedEngagementStatus,
        },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          setPageNumber(1);

          if (res.data.value.length > 0) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          let allContactList = [];
          allContactList = [...res.data.value];
          let uniqueContacts = [];
          allContactList.map((val) => {
            let index = uniqueContacts.findIndex((item) => {
              return item._id == val._id;
            });
            if (index == -1) {
              uniqueContacts.push({
                ...val,
              });
            }
          });
          let temp = uniqueContacts.filter((val) => val.isRemoved == "No");
          temp.map((val) => {
            val.checked = isAllSelected;
            val.ID = val.ID.toString();
            val.ENGAGEMENT_STATUS =
              engagementStatus.find(
                (item) => item.value == val.engagementStatus
              )?.label || "";
          });
          setContactList(temp);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setLoading(false);
          setError(true);
        });
      return () => cancel();
    }
  }, [
    selectedSearchKey,
    searchValue,
    selectedEngagementStatus,
    dashboardQuery,
  ]);
  return {
    loading,
    error,
    ContactList,
    hasMore,
  };
}

export default useContactLoading;

// import { useEffect, useState } from "react";
// import axios from "axios";
// import AXIOS from "../utils/AXIOS";
// import { engagementStatus } from "../utils/Constants";

// function UseContactLoading(
//   pageNumber,
//   LOGIN_ID,
//   selectedSearchKey,
//   searchValue,
//   setPageNumber,
//   dashboardQuery,
//   selectedEngagementStatus
// ) {
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const [ContactList, setContactList] = useState([]);

//   // useEffect(() => {
//   //   setContactList([]);
//   // }, []);

//   const fetchContacts = async (page) => {
//     if (!LOGIN_ID) return;

//     setLoading(true);
//     setError(false);

//     let cancelTokenSource = axios.CancelToken.source();
//     console.log("Asdasbdasdsa", {
//       PAGE_NUMBER: page,
//       SEARCH_KEY: selectedSearchKey,
//       SEARCH_VALUE: searchValue,
//       START_DATE: dashboardQuery.START_DATE,
//       END_DATE: dashboardQuery.END_DATE,
//       AUTH_ID: LOGIN_ID,
//       ENGAGEMENT_STATUS: selectedEngagementStatus,
//     });

//     try {
//       const { data } = await axios.post(
//         AXIOS.defaultPort + AXIOS.contactDataGet,
//         {
//           PAGE_NUMBER: page,
//           SEARCH_KEY: selectedSearchKey,
//           SEARCH_VALUE: searchValue,
//           START_DATE: dashboardQuery.START_DATE,
//           END_DATE: dashboardQuery.END_DATE,
//           AUTH_ID: LOGIN_ID,
//           ENGAGEMENT_STATUS: selectedEngagementStatus,
//         },
//         { cancelToken: cancelTokenSource.token }
//       );

//       const newContacts = data?.value || [];

//       setHasMore(newContacts.length > 0);

//       // Use empty array if `pageNumber` is 1, else append to existing contacts
//       let allContacts =
//         page === 1 ? newContacts : [...ContactList, ...newContacts];

//       // Remove duplicates
//       let uniqueContacts = allContacts.filter(
//         (val, index, self) =>
//           self.findIndex((item) => item._id === val._id) === index
//       );

//       // Filter only non-removed contacts and format them
//       let processedContacts = uniqueContacts.map((val) => ({
//         ...val,
//         checked: false,
//         ID: val.ID.toString(),
//         ENGAGEMENT_STATUS:
//           engagementStatus.find((item) => item.value === val.engagementStatus)
//             ?.label || "",
//       }));

//       setContactList(processedContacts);

//       if (page === 1) {
//         setPageNumber(1);
//       }
//     } catch (error) {
//       if (!axios.isCancel(error)) {
//         setError(true);
//       }
//     } finally {
//       setLoading(false);
//     }

//     return () => cancelTokenSource.cancel();
//   };

//   useEffect(() => {
//     fetchContacts(pageNumber);
//   }, [pageNumber, LOGIN_ID]);

//   useEffect(() => {
//     setContactList([]); // Clear contacts when search filters change
//     fetchContacts(1);
//   }, [
//     selectedSearchKey,
//     searchValue,
//     selectedEngagementStatus,
//     dashboardQuery,
//   ]);

//   return { loading, error, ContactList, hasMore };
// }

// export default UseContactLoading;
