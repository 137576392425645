import React, { useState, useEffect } from "react";
import "./CompanyView.css";
import { Box, Card, Typography } from "@mui/material";
import { COLORS } from "../constants/theme";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ContactDataGetAction } from "../Redux/Action/ContactDataGetAction";
import { connect, useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import Lottie from "react-lottie-player";
import noData from "../assets/lottieFiles/noData.json";
import MainScreen from "../components/Navbar/MainScreen";
import axios from "axios";
import { AxiosDefaultPort } from "../constants/Axios";

function CompanyView(props) {
  const designationLevels = {
    CXO: [
      ["CEO", "Chief Excecutive Officer"],
      ["CFO", "Chief Financial Officer"],
      ["COO", "Chief Operating Officer"],
      ["BO", "CLO", "Chief Business Officer", "Chief Legal Officer"],
    ],
    SM: [
      ["SVP", "Senior Vice President"],
      ["VP", "Vice President"],
      ["AVP", "Assistant Vice President", "Asst. Vice President"],
      ["DVP", "Deputy Vice President"],
      ["SGM", "Senior General Manager"],
      ["GM", "General Manager"],
    ],
    MM: [
      ["AGM", "Assistant General Manager", "Asst. General Manager"],
      ["DGM", "Deputy General Manager"],
      ["SM", "Senior Manager"],
      ["Manager"],
      ["AM", "Assistant Manager", "Asst. Manager"],
      ["DM", "Deputy Manager"],
      ["SE", "Senior Executive"],
      ["E", "Executive"],
      ["Officer"],
    ],
  };

  const location = useLocation();
  const navigate = useNavigate();
  const companyName = location?.state?.companyName;
  const [companyData, setCompanyData] = useState([]);
  const [showMoreCxo, setShowMoreCxo] = useState(false);
  const [showMoreSm, setShowMoreSm] = useState(false);
  const [showMoreMm, setShowMoreMm] = useState(false);
  const [showMoreGen, setShowMoreGen] = useState(false);

  useEffect(() => {
    // let tempContactData = props.contact_data.filter(
    //   (val) => val.company == location?.state?.companyName
    // );
    axios
      .post(`${AxiosDefaultPort}cms/contacts/getContactsByCompany`, {
        company: location?.state?.companyName,
      })
      .then((res) => {
        let tempContactData = res.data;
        tempContactData.map((contactItem) => {
          let tempLevelSeq;
          let num;
          if (contactItem.level == "CXO") {
            tempLevelSeq = designationLevels.CXO;
            num = 30;
          } else if (contactItem.level == "Senior Mgmt") {
            tempLevelSeq = designationLevels.SM;
            num = 20;
          } else if (contactItem.level == "Mid Mgmt") {
            tempLevelSeq = designationLevels.MM;
            num = 10;
          }

          // designationLevels.CXO.map((val, index) => {
          for (let i = 0; i < tempLevelSeq?.length; i++) {
            if (
              tempLevelSeq[i].some(
                (checkItem) =>
                  checkItem?.toUpperCase() ==
                  contactItem?.designation?.toUpperCase()
              )
            ) {
              contactItem.index = i;
              break;
            } else if (i == tempLevelSeq?.length - 1) {
              contactItem.index = tempLevelSeq?.length;
            }
          }
        });
        tempContactData.sort(function (a, b) {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return 1;
          }
          return 0;
        });
        setCompanyData(tempContactData);
      });
  }, [props.contact_data]);

  const levelTextHead = { fontSize: 16, fontWeight: 600, color: COLORS.gray60 };
  const levelTextBody = { fontSize: 16, color: COLORS.gray50 };
  // const filteredData = companyData.filter((val) => val.company == companyName);

  const data = {
    company: companyName,
    contacts: companyData,
  };
  // -----------------------

  // const data = {
  //   company: "TATA Power",
  //   contacts: [
  //     {
  //       name0: "Amit Kumar",
  //       designation: "CEO",
  //       level: "CXO",
  //     },
  //     {
  //       name0: "Amit Kumar",
  //       designation: "CEO",
  //       level: "CXO",
  //     },
  //     {
  //       name0: "Amit Kumar",
  //       designation: "CEO",
  //       level: "CXO",
  //     },

  //     {
  //       name0: "Vijay Mishra",
  //       designation: "Group CFO",
  //       level: "CXO",
  //     },

  //     {
  //       name0: "Dinesh Singh",
  //       designation: "Head_India",
  //       level: "Senior Mgmt",
  //     },
  //     {
  //       name0: "Priyanka Rao",
  //       designation: "VP_IT",
  //       level: "Senior Mgmt",
  //     },

  //     {
  //       name0: "Saurav Das",
  //       designation: "Manager",
  //       level: "Mid Mgmt",
  //     },
  //     {
  //       name0: "Saurav Das",
  //       designation: "Manager",
  //       level: "Mid Mgmt",
  //     },
  //     {
  //       name0: "Saurav Das",
  //       designation: "Manager",
  //       level: "Mid Mgmt",
  //     },
  //     {
  //       name0: "Saurav Das",
  //       designation: "Manager",
  //       level: "Mid Mgmt",
  //     },
  //     {
  //       name0: "Saurav Das",
  //       designation: "Manager",
  //       level: "Mid Mgmt",
  //     },
  //   ],
  // };
  // -----------------------
  const MoreCollapsible = ({ level, showMore, setShowMore, dataArr }) => {
    {
      level == level && dataArr?.length > 3 && (
        <>
          <Box></Box>
          <Box></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            {!showMore && (
              <Typography
                sx={{ mr: 1, color: COLORS.blue }}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                More...
              </Typography>
            )}
            {showMore && (
              <Typography
                sx={{ mr: 1, color: COLORS.blue }}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                Less...
              </Typography>
            )}
            {/* <Collapsible
                trigger="More..."
                // style={{ cursor: "pointer" }}
              >
                <p>
                  This is the collapsible content. It can be any element or
                  React component you like.
                </p>
              </Collapsible> */}
          </Box>
        </>
      );
    }
  };

  const MoreDataCard = ({ level, showMore, dataArr }) => {
    let color;
    if (level == "CXO") {
      color = "#edf4f9";
    } else if (level == "Senior Mgmt") {
      color = "#fff2cc";
    } else if (level == "Mid Mgmt") {
      color = "#e6fbe6";
    } else if (level == "General") {
      color = "#f2e7fe";
    }
    return (
      <>
        {level == level && showMore && (
          <>
            {dataArr.map((val, index) => {
              return (
                <>
                  {index >= 3 && (
                    <Card
                      className="contact-card"
                      sx={{
                        // ...levelStyle,
                        backgroundColor: color,
                        // boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
                      }}
                      onClick={() => {
                        navigate("/contactDetails", {
                          state: {
                            searchContact: val,
                          },
                        });
                      }}
                    >
                      <Typography sx={{ ...levelTextHead }}>
                        {val.name0}
                      </Typography>
                      <Typography sx={{ ...levelTextBody }}>
                        {val.designation}
                      </Typography>
                    </Card>
                  )}
                </>
              );
            })}
          </>
        )}
      </>
    );
  };

  const LevelBox = ({ level }) => {
    let color;
    let colorLevel;
    let cxoArr = [];
    let smArr = [];
    let mmArr = [];
    let genArr = [];
    let myData;
    if (level == "CXO") {
      colorLevel = "#a1c5e0";
    } else if (level == "Senior Mgmt") {
      colorLevel = "#fde089";
    } else if (level == "Mid Mgmt") {
      colorLevel = "#c5fac5";
    } else if (level == "General") {
      colorLevel = "#dbb2ff";
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="levelname-box"
          sx={{
            paddingTop: level == "CXO" ? 2 : 4,
            paddingBottom: level == "CXO" ? 2 : 4,
            backgroundColor: colorLevel,
            // mb: 3,
            // padding: 4,
            // width: "1%",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            // border: "1px solid #c4c4c4",
          }}
        >
          {level == "CXO" && (
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 500,
                textAlign: "center",
                color: COLORS.gray80,
              }}
            >
              C<br />X<br />O
            </Typography>
          )}
          {level == "Senior Mgmt" && (
            <Typography
              sx={{
                fontSize: 17,
                fontWeight: 500,
                textAlign: "center",
                color: COLORS.gray80,
              }}
            >
              Senior
              <br /> Mgmt
            </Typography>
          )}
          {level == "Mid Mgmt" && (
            <Typography
              sx={{
                fontSize: 17,
                fontWeight: 500,
                textAlign: "center",
                color: COLORS.gray80,
              }}
            >
              Mid
              <br /> Mgmt
            </Typography>
          )}
          {level == "General" && (
            <Typography
              sx={{
                fontSize: 17,
                fontWeight: 500,
                textAlign: "center",
                color: COLORS.gray80,
              }}
            >
              General
            </Typography>
          )}
        </Box>
        <Box className="level-box">
          {data.contacts.map((val, index) => {
            if (val.level == level) {
              if (val.level == "CXO") {
                color = "#edf4f9";
                cxoArr.push(val);
              } else if (val.level == "Senior Mgmt") {
                color = "#fff2cc";
                smArr.push(val);
              } else if (val.level == "Mid Mgmt") {
                color = "#e6fbe6";
                mmArr.push(val);
              } else if (val.level == "General") {
                color = "#f2e7fe";
                genArr.push(val);
              }
              return (
                <>
                  {((level == "CXO" && cxoArr?.length <= 3) ||
                    (level == "Senior Mgmt" && smArr?.length <= 3) ||
                    (level == "Mid Mgmt" && mmArr?.length <= 3) ||
                    (level == "General" && genArr?.length <= 3)) && (
                    <Card
                      className="contact-card"
                      sx={{
                        // ...levelStyle,
                        backgroundColor: color,
                        // boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
                      }}
                      onClick={() => {
                        navigate("/contactDetails", {
                          state: {
                            searchContact: val,
                          },
                        });
                      }}
                    >
                      <Typography sx={{ ...levelTextHead }}>
                        {val.name0}
                      </Typography>
                      <Typography sx={{ ...levelTextBody }}>
                        {val.designation}
                      </Typography>
                    </Card>
                  )}
                </>
              );
            }
          })}
          {level == "CXO" && cxoArr?.length > 3 && (
            <>
              <Box></Box>
              <Box></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
              >
                {!showMoreCxo && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreCxo(!showMoreCxo);
                    }}
                  >
                    More...
                  </Typography>
                )}
                {showMoreCxo && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreCxo(!showMoreCxo);
                    }}
                  >
                    Less...
                  </Typography>
                )}
                {/* <Collapsible
                trigger="More..."
                // style={{ cursor: "pointer" }}
              >
                <p>
                  This is the collapsible content. It can be any element or
                  React component you like.
                </p>
              </Collapsible> */}
              </Box>
            </>
          )}
          {level == "Senior Mgmt" && smArr?.length > 3 && (
            <>
              <Box></Box>
              <Box></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
              >
                {!showMoreSm && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreSm(!showMoreSm);
                    }}
                  >
                    More...
                  </Typography>
                )}
                {showMoreSm && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreSm(!showMoreSm);
                    }}
                  >
                    Less...
                  </Typography>
                )}
              </Box>
            </>
          )}
          {level == "Mid Mgmt" && mmArr?.length > 3 && (
            <>
              <Box></Box>
              <Box></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
              >
                {!showMoreMm && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreMm(!showMoreMm);
                    }}
                  >
                    More...
                  </Typography>
                )}
                {showMoreMm && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreMm(!showMoreMm);
                    }}
                  >
                    Less...
                  </Typography>
                )}
              </Box>
            </>
          )}
          {level == "General" && genArr?.length > 3 && (
            <>
              <Box></Box>
              <Box></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  cursor: "pointer",
                }}
              >
                {!showMoreGen && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreGen(!showMoreGen);
                    }}
                  >
                    More...
                  </Typography>
                )}
                {showMoreGen && (
                  <Typography
                    sx={{ mr: 1, color: COLORS.blue }}
                    onClick={() => {
                      setShowMoreGen(!showMoreGen);
                    }}
                  >
                    Less...
                  </Typography>
                )}
              </Box>
            </>
          )}

          {/* <MoreCollapsible
            level="CXO"
            showMore={showMoreCxo}
            setShowMore={setShowMoreCxo}
            dataArr={cxoArr}
          />

          {level == "Senior Mgmt" && (
            <MoreCollapsible
              level="Senior Mgmt"
              showMore={showMoreSm}
              setShowMore={setShowMoreSm}
              dataArr={smArr}
            />
          )}
          {level == "Mid Mgmt" && (
            <MoreCollapsible
              level="Mid Mgmt"
              showMore={showMoreMm}
              setShowMore={setShowMoreMm}
              dataArr={mmArr}
            />
          )} */}
          <MoreDataCard level="CXO" showMore={showMoreCxo} dataArr={cxoArr} />
          <MoreDataCard
            level="Senior Mgmt"
            showMore={showMoreSm}
            dataArr={smArr}
          />
          <MoreDataCard
            level="Mid Mgmt"
            showMore={showMoreMm}
            dataArr={mmArr}
          />
          <MoreDataCard
            level="General"
            showMore={showMoreGen}
            dataArr={genArr}
          />

          {/* {level == "CXO" && showMore && (
            <>
              {cxoArr.map((val, index) => {
                return (
                  <>
                    {index >= 3 && (
                      <Box
                        sx={{
                          ...levelStyle,
                          backgroundColor: color,
                          boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => {
                          navigate("/contactDetails", {
                            state: {
                              searchContact: val,
                            },
                          });
                        }}
                      >
                        <Typography sx={{ ...levelTextHead }}>
                          {val.name0}
                        </Typography>
                        <Typography sx={{ ...levelTextBody }}>
                          {val.designation}
                        </Typography>
                      </Box>
                    )}
                  </>
                );
              })}
            </>
          )} */}
        </Box>
      </Box>
    );
  };

  return (
    <MainScreen>
      <Box
        sx={{
          flex: 1,
          paddingBottom: 5,
          // backgroundColor: "#eceeef",
          // backgroundImage: `url(${bg3})`,
          // backgroundSize: "cover",
          minHeight: "100vh",
          // minWidth: "100vh",
          // paddingTop: 12,
          // backgroundColor: "#dde6ed",
          // paddingRight: 5,
          // paddingLeft: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
            ml: 2,
            pt: 2,
            pb: 2,
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            Company Data{" "}
          </Typography>
          {/* <h9 className="header-text">Company Data</h9> */}
        </Box>
        {/* <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          ml: 5,
          mt: 1,
          // mb: 1,
        }}
      >
        <Typography
          style={{ fontSize: 20, fontWeight: 600, color: COLORS.gray60 }}
        >
          Company Data
        </Typography>
      </Box> */}
        {/* <Box sx={{ ml: 5, mt: 2 }}>
        <Typography
          style={{ fontSize: 30, fontWeight: 600, color: COLORS.gray60 }}
        >
          User Onboarding
        </Typography>
      </Box> */}

        {(props.components_list.includes("show-cxo-view") ||
          props.components_list.includes("show-senior-management-view") ||
          props.components_list.includes("show-mid-management-view") ||
          props.components_list.includes("show-general-view")) && (
          <Box
            className="company-container"
            sx={{
              backgroundColor: "#fff",
              // backgroundColor: "#313c47",
              padding: 3,
              mr: "5%",
              ml: "5%",
              mt: "1%",
              mb: 1,
              borderRadius: 2,
            }}
          >
            {location.state && (
              <>
                <Box className="company-card">
                  <Typography
                    sx={{ fontSize: 17, fontWeight: 600, color: COLORS.gray60 }}
                  >
                    {data?.company?.toUpperCase()}
                  </Typography>
                </Box>
                {/* ---------------- */}

                {props.components_list.includes("show-cxo-view") && (
                  <LevelBox level="CXO" />
                )}
                {props.components_list.includes(
                  "show-senior-management-view"
                ) && <LevelBox level="Senior Mgmt" />}
                {props.components_list.includes("show-mid-management-view") && (
                  <LevelBox level="Mid Mgmt" />
                )}
                {props.components_list.includes("show-general-view") && (
                  <LevelBox level="General" />
                )}

                {/* --------------- */}
              </>
            )}
            {!location.state && (
              <Box
                className="no-data"
                style={{
                  backgroundColor: "#fff",
                  width: "80%",
                  margin: "auto",
                  height: "60vh",
                }}
              >
                <Lottie
                  play
                  //   speed={"1"}
                  animationData={noData}
                  style={{
                    width: 250,
                    height: 250,
                  }}
                />
                <Typography
                  sx={{ fontSize: 20, fontWeight: 500, color: "#b7beca" }}
                >
                  No Company Found
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </MainScreen>
  );
}

// export default CompanyView;
const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, { ContactDataGetAction })(CompanyView);
