import React, { useEffect, useState } from "react";
import MainScreen from "../../components/Navbar/MainScreen";
import toast, { Toaster } from "react-hot-toast";
import {
  Box,
  Card,
  Chip,
  Divider,
  Modal,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ReactDataTable from "../../components/ReactDataTable/ReactDataTable";
import CancelIcon from "@mui/icons-material/Cancel";
import { COLORS } from "../../constants/theme";
import { RxCross1 } from "react-icons/rx";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import EditIcon from "@mui/icons-material/Edit";

import Select from "react-select";
import CustomInput from "../../components/CustomInput/CustomInput";
import AXIOS from "../../utils/AXIOS";
import Spinner from "../../components/Spinner/Spinner";
import { connect } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  border: "none",
};

function ManageRoles(props) {
  const navigate = useNavigate();

  const [tbody, setTbody] = useState([]);
  const [screenComponentList, setScreenComponentList] = useState([]);
  const [SelectedComponentList, setSelectedComponentList] = useState([]);
  const [rolesAddModalState, setRolesAddModalState] = useState(false);
  const [editRolesAddModalState, setEditRolesAddModalState] = useState(false);

  const flowType = [
    {
      label: "All Data",
      value: "all",
    },
    {
      label: "Reportees Data",
      value: "reportingManager",
    },
    {
      label: "Own Data only",
      value: "own",
    },
  ];

  const [roleConfigureQuery, setRoleConfigureQuery] = useState({
    ROLE_NAME: "",
    SELECTED_SCREEN: [],
    SELECTED_COMPONENTS: [],
    SELECTED_FLOW_TYPE: "",
    ROLE_NAME_ERROR: false,
    SELECTED_SCREEN_ERROR: false,
    SELECTED_COMPONENTS_ERROR: false,
    SELECTED_FLOW_TYPE_ERROR: false,
  });
  const [editRoleConfigureQuery, setEditRoleConfigureQuery] = useState({
    ROLE_NAME: "",
    ROLE_ID: "",
    SELECTED_SCREEN: [],
    SELECTED_COMPONENTS: [],
    SELECTED_FLOW_TYPE: "",
    ROLE_NAME_ERROR: false,
    SELECTED_SCREEN_ERROR: false,
    SELECTED_COMPONENTS_ERROR: false,
    SELECTED_FLOW_TYPE_ERROR: false,
  });

  const [transactionFlags, setTransactionFlags] = useState({
    CONFIGURE_ROLE_SAVE_LOADING: false,
    UPDATE_ROLE_SAVE_LOADING: false,
  });
  useEffect(() => {
    getRolesList();
    getScreenComponentData();
  }, []);

  const screenListColumns = [
    {
      name: "Role Name",
      selector: (val) => val.ROLE_NAME,
      sortable: true,
    },
    {
      name: "Screens",
      selector: (val) => val.SCREENS,
      sortable: true,
      cell: (val) => {
        return (
          <>
            {val.SCREENS.map((item) => {
              return (
                <span
                  className="tag"
                  style={{
                    fontSize: 12,
                    backgroundColor: "#E6F2FF",
                  }}
                >
                  {item?.SCREEN_NAME}
                </span>
              );
            })}
          </>
        );
      },
    },
    {
      name: "Components",
      selector: (val) => val.COMPONENTS,
      sortable: true,
      cell: (val) => {
        return (
          <>
            {val.COMPONENTS.map((item) => {
              return (
                <>
                  <span
                    className="component-tag"
                    style={{
                      fontSize: 12,
                      backgroundColor: "#E6F2FF",
                    }}
                  >
                    {item?.COMPONENT_NAME}
                  </span>
                  <br />
                </>
              );
            })}
          </>
        );
      },
    },
    {
      name: "Flow Type",
      selector: (val) => val.FLOW_TYPE,
      sortable: true,
      width: "100px",
      cell: (val) => {
        return (
          <span
            style={{
              fontSize: 12,
              // backgroundColor: "#E6F2FF",
            }}
          >
            {flowType.find((item) => item.value == val.FLOW_TYPE)?.label ||
              "No Flow Found"}
          </span>
        );
      },
    },
    {
      name: "Action",
      selector: (val) => val.COMPONENTS,
      sortable: true,
      width: "100px",
      cell: (val) => {
        return (
          <span
            style={{
              cursor: "pointer",
              backgroundColor: "#E6F2FF",
              padding: 2,
              borderRadius: 5,
              margin: 1,
            }}
          >
            <EditIcon
              sx={{
                color: "#007bff",
                height: 20,
                width: 20,
              }}
              onClick={() => {
                setEditRolesAddModalState(true);

                setEditRoleConfigureQuery({
                  ROLE_NAME: val.ROLE_NAME,
                  SELECTED_SCREEN: val.SCREENS,
                  SELECTED_COMPONENTS: val.COMPONENTS,
                  ROLE_ID: val._id,
                  SELECTED_FLOW_TYPE:
                    flowType.find((item) => item.value == val.FLOW_TYPE) || "",
                });
              }}
            />
          </span>
        );
      },
    },
  ];

  const getRolesList = () => {
    fetch(AXIOS.defaultPort + AXIOS.rolesGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // SCREEN_NAME: registerScreenQuery.SCREEN_NAME,
        // SCREEN_PATH: registerScreenQuery.SCREEN_PATH,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        data.map((val) => {
          val.label = val.ROLE_NAME;
          val.value = val._id;
          val.SCREENS.map((screen) => {
            screen.label = screen.SCREEN_NAME;
            screen.value = screen._id;
          });
          val.COMPONENTS.map((component) => {
            component.label = component.COMPONENT_NAME;
            component.value = component._id;
          });
        });
        setTbody(data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const getScreenComponentData = () => {
    fetch(AXIOS.defaultPort + AXIOS.getComponentsByScreen, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // SCREEN_NAME: registerScreenQuery.SCREEN_NAME,
        // SCREEN_PATH: registerScreenQuery.SCREEN_PATH,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        data.map((val) => {
          val.label = val.SCREEN_NAME;
          val.value = val._id;

          val.COMPONENTS.map((item) => {
            item.label = item.COMPONENT_NAME;
            item.value = item._id;
          });
        });

        setScreenComponentList(data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const ConfigureNewRole = () => {
    let selectedScreens = [];
    let selectedComponents = [];
    roleConfigureQuery.SELECTED_SCREEN.map((val) => {
      selectedScreens.push(val._id);
    });
    roleConfigureQuery.SELECTED_COMPONENTS.map((val) => {
      selectedComponents.push(val._id);
    });

    if (roleConfigureQuery.ROLE_NAME == "") {
      setRoleConfigureQuery((prev) => ({
        ...prev,
        ROLE_NAME_ERROR: true,
      }));
    }
    if (selectedScreens.length <= 0) {
      setRoleConfigureQuery((prev) => ({
        ...prev,
        SELECTED_SCREEN_ERROR: true,
      }));
    }
    if (selectedComponents.length <= 0) {
      setRoleConfigureQuery((prev) => ({
        ...prev,
        SELECTED_COMPONENTS_ERROR: true,
      }));
    }
    if (roleConfigureQuery.SELECTED_FLOW_TYPE == "") {
      setRoleConfigureQuery((prev) => ({
        ...prev,
        SELECTED_FLOW_TYPE_ERROR: true,
      }));
    }
    if (
      roleConfigureQuery.ROLE_NAME != "" &&
      selectedComponents.length > 0 &&
      selectedScreens.length > 0 &&
      roleConfigureQuery.SELECTED_FLOW_TYPE != ""
    ) {
      setTransactionFlags((prev) => ({
        ...prev,
        CONFIGURE_ROLE_SAVE_LOADING: true,
      }));

      fetch(AXIOS.defaultPort + AXIOS.roleCreate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ROLE_NAME: roleConfigureQuery.ROLE_NAME,
          SCREENS: selectedScreens,
          COMPONENTS: selectedComponents,
          FLOW_TYPE: roleConfigureQuery.SELECTED_FLOW_TYPE.value,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();

          toast.success("Role Created Successfully");

          setTransactionFlags((prev) => ({
            ...prev,
            CONFIGURE_ROLE_SAVE_LOADING: false,
          }));
          setRolesAddModalState(false);
          setRoleConfigureQuery({
            ROLE_NAME: "",
            SELECTED_SCREEN: [],
            SELECTED_COMPONENTS: [],
            SELECTED_FLOW_TYPE: "",
            ROLE_NAME_ERROR: false,
            SELECTED_SCREEN_ERROR: false,
            SELECTED_COMPONENTS_ERROR: false,
            SELECTED_FLOW_TYPE_ERROR: false,
          });

          getRolesList();
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            CONFIGURE_ROLE_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    }
  };
  const updateRole = () => {
    let selectedScreens = [];
    let selectedComponents = [];

    editRoleConfigureQuery.SELECTED_SCREEN.map((val) => {
      selectedScreens.push(val._id);
    });
    editRoleConfigureQuery.SELECTED_COMPONENTS.map((val) => {
      selectedComponents.push(val._id);
    });

    if (editRoleConfigureQuery.ROLE_NAME == "") {
      setEditRoleConfigureQuery((prev) => ({
        ...prev,
        ROLE_NAME_ERROR: true,
      }));
    }
    if (selectedScreens.length <= 0) {
      setEditRoleConfigureQuery((prev) => ({
        ...prev,
        SELECTED_SCREEN_ERROR: true,
      }));
    }
    if (selectedComponents.length <= 0) {
      setEditRoleConfigureQuery((prev) => ({
        ...prev,
        SELECTED_COMPONENTS_ERROR: true,
      }));
    }
    if (editRoleConfigureQuery.SELECTED_FLOW_TYPE == "") {
      setEditRoleConfigureQuery((prev) => ({
        ...prev,
        SELECTED_FLOW_TYPE_ERROR: true,
      }));
    }
    if (
      editRoleConfigureQuery.ROLE_NAME != "" &&
      selectedComponents.length > 0 &&
      selectedScreens.length > 0 &&
      editRoleConfigureQuery.SELECTED_FLOW_TYPE != ""
    ) {
      setTransactionFlags((prev) => ({
        ...prev,
        UPDATE_ROLE_SAVE_LOADING: true,
      }));

      fetch(AXIOS.defaultPort + AXIOS.roleUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ROLE_NAME: editRoleConfigureQuery.ROLE_NAME,
          SCREENS: selectedScreens,
          COMPONENTS: selectedComponents,
          FLOW_TYPE: editRoleConfigureQuery.SELECTED_FLOW_TYPE.value,
          ROLE_ID: editRoleConfigureQuery.ROLE_ID,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();

          toast.success("Role Updated Successfully");

          setTransactionFlags((prev) => ({
            ...prev,
            UPDATE_ROLE_SAVE_LOADING: false,
          }));
          setEditRolesAddModalState(false);
          getRolesList();
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            UPDATE_ROLE_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    }
  };

  const handleScreenChange = (e) => {
    setRoleConfigureQuery((prev) => ({
      ...prev,
      SELECTED_SCREEN: e,
      SELECTED_SCREEN_ERROR: false,
    }));

    let newComponents = [];

    e.map((val) => {
      if (val.COMPONENTS) {
        val.COMPONENTS.map((item) => {
          newComponents.push({
            ...item,
          });
        });
      }
    });

    function syncArrays(array1, array2) {
      const idsSet = new Set(array1.map((item) => item._id));
      return array2.filter((item) => idsSet.has(item._id));
    }

    let tempComponents = [...roleConfigureQuery.SELECTED_COMPONENTS];
    const updatedArray2 = syncArrays(
      // newComponents,
      tempComponents,
      newComponents
    );

    setSelectedComponentList(newComponents);
  };

  const handleEditScreenChange = (e) => {
    setEditRoleConfigureQuery((prev) => ({
      ...prev,
      SELECTED_SCREEN: e,
      SELECTED_SCREEN_ERROR: false,
    }));

    let newComponents = [];

    e.map((val) => {
      if (val.COMPONENTS) {
        val.COMPONENTS.map((item) => {
          newComponents.push({
            ...item,
          });
        });
      }
    });

    function syncArrays(array1, array2) {
      const idsSet = new Set(array1.map((item) => item._id));
      return array2.filter((item) => idsSet.has(item._id));
    }

    let tempComponents = [...roleConfigureQuery.SELECTED_COMPONENTS];
    const updatedArray2 = syncArrays(
      // newComponents,
      tempComponents,
      newComponents
    );

    setSelectedComponentList(newComponents);
  };

  return (
    <MainScreen>
      <Toaster
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        gutter={24}
        toastOptions={{
          style: {
            border: "1px solid #grey",
            padding: "16px",
          },
          success: {
            style: {
              opacity: 0.1,
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 3,
          paddingBottom: 1,
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          {/* <h9 className="header-text">Contact Capture</h9> */}
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            Manage Roles
          </Typography>
        </Box>
      </Box>

      <Card
        sx={{
          m: 1,
          p: 1,
        }}
      >
        {props.components_list.includes("add-roles") && (
          <button
            className="filled-button"
            style={{
              minWidth: 100,

              backgroundColor: "#007bff",
            }}
            onClick={() => {
              // setSubjectDrawerState(false);
              setRolesAddModalState(true);
            }}
          >
            Add Roles
          </button>
        )}

        {/* <ReactDataTable columns={screenListColumns} data={tbody} /> */}
        {props.components_list.includes("view-roles") && (
          <tbody>
            <thead>
              <tr>
                <th>Role Name</th>
                <th>Screens</th>
                <th>Component</th>
                <th>Flow Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tbody.map((val) => {
                return (
                  <tr>
                    <td>
                      {/* <span
                      style={
                        {
                          // fontSize: 10,
                        }
                      }
                      className="tag"
                    >
                      {val.ROLE_NAME}
                    </span> */}
                      <Chip label={val?.ROLE_NAME} />
                    </td>
                    <td>
                      {val.SCREENS.map((item) => {
                        return (
                          <span
                            className="tag"
                            style={{
                              fontSize: 12,
                              backgroundColor: "#E6F2FF",
                              margin: 1,
                            }}
                          >
                            {item?.SCREEN_NAME}
                          </span>
                        );
                      })}
                    </td>
                    <td>
                      {val.COMPONENTS.map((item) => {
                        return (
                          <span
                            className="tag"
                            style={{
                              fontSize: 12,
                              backgroundColor: "#E6F2FF",
                              margin: 1,
                            }}
                          >
                            {item?.COMPONENT_NAME}
                          </span>
                        );
                      })}
                    </td>
                    <td>
                      <span
                        className="tag"
                        style={{
                          fontSize: 12,
                          backgroundColor: "#E6F2FF",
                          margin: 1,
                        }}
                      >
                        {flowType.find((item) => item.value == val.FLOW_TYPE)
                          ?.label || "No Flow Found"}
                      </span>
                      {/* {flowType.find((item) => item.value == val.FLOW_TYPE)
                      ?.label || "No Flow Found"} */}
                    </td>
                    <td>
                      {props.components_list.includes("edit-roles") && (
                        <span style={{}}>
                          <EditIcon
                            sx={{
                              color: "#007bff",
                              height: 30,
                              width: 30,
                              cursor: "pointer",
                              backgroundColor: "#E6F2FF",
                              padding: 0.7,
                              borderRadius: 1,
                              // margin: 1,
                            }}
                            onClick={() => {
                              setEditRolesAddModalState(true);

                              setEditRoleConfigureQuery({
                                ROLE_NAME: val.ROLE_NAME,
                                SELECTED_SCREEN: val.SCREENS,
                                SELECTED_COMPONENTS: val.COMPONENTS,
                                ROLE_ID: val._id,
                                SELECTED_FLOW_TYPE:
                                  flowType.find(
                                    (item) => item.value == val.FLOW_TYPE
                                  ) || "",
                              });
                            }}
                          />
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </tbody>
        )}
      </Card>
      {/* New Role Configure */}
      <Modal
        open={rolesAddModalState}
        disableAutoFocus
        onClose={() => {
          setRolesAddModalState(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="delete-modal-container"
          sx={{
            maxHeight: "95vh",
            overflowY: "auto",
          }}
        >
          <Box
            className="delete-modal-box"
            sx={{
              cursor: "auto",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: COLORS.gray80,
                fontWeight: "bold",
              }}
            >
              Configure Roles
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
            >
              <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                <RxCross1
                  onClick={() => {
                    setRolesAddModalState(false);
                  }}
                />
              </IconContext.Provider>
            </div>
          </Box>
          <Divider />
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Role Name
          </Typography>
          <CustomInput
            Placeholder={"Role Name"}
            Style={{
              height: 40,
            }}
            Value={roleConfigureQuery.ROLE_NAME}
            onChange={(e) => {
              setRoleConfigureQuery((prev) => ({
                ...prev,
                ROLE_NAME: e.target.value,
                ROLE_NAME_ERROR: false,
              }));
            }}
          />
          {roleConfigureQuery.ROLE_NAME_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Enter Role Name
            </span>
          )}
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Select Screens
          </Typography>
          <Select
            options={screenComponentList}
            isMulti
            value={roleConfigureQuery.SELECTED_SCREEN}
            onChange={(e) => {
              handleScreenChange(e);
            }}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: "#94a3b8",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "center",
                };
              },
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? "#70777d" : "white",
                color: state.isSelected ? "white" : "black",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                padding: 2,
                // marginTop: -5,
                paddingLeft: 5,
                height: 25,
                ":hover": {
                  backgroundColor: "#1770E0",
                  color: "white",
                },
              }),
              control: (provided, state) => ({
                ...provided,
                background: "#fff",
                borderColor: "#E0E0E0",
                borderRadius: 5,
                background: "#fdfdfe",
                boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
                ":hover": {
                  borderColor: "#E0E0E0",
                  cursor: "text",
                },
              }),

              valueContainer: (provided, state) => ({
                ...provided,
                // height: "30px",
                padding: "0 6px",
              }),

              input: (provided, state) => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: (state) => ({
                display: "none",
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
              }),
              menuPortal: (provided) => ({
                ...provided,
                // zIndex: 9999, // Ensure the menu appears above other elements
              }),
            }}
          />
          {roleConfigureQuery.SELECTED_SCREEN_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Select Screens
            </span>
          )}
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Components
          </Typography>
          <Select
            options={SelectedComponentList}
            value={roleConfigureQuery.SELECTED_COMPONENTS}
            isMulti
            onChange={(e) => {
              setRoleConfigureQuery((prev) => ({
                ...prev,
                SELECTED_COMPONENTS: e,
                SELECTED_COMPONENTS_ERROR: false,
              }));
            }}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: "#94a3b8",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "center",
                };
              },
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? "#70777d" : "white",
                color: state.isSelected ? "white" : "black",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                padding: 2,
                // marginTop: -5,
                paddingLeft: 5,
                height: 25,
                ":hover": {
                  backgroundColor: "#1770E0",
                  color: "white",
                },
              }),
              control: (provided, state) => ({
                ...provided,
                background: "#fff",
                borderColor: "#E0E0E0",
                borderRadius: 5,
                background: "#fdfdfe",
                boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
                ":hover": {
                  borderColor: "#E0E0E0",
                  cursor: "text",
                },
              }),

              valueContainer: (provided, state) => ({
                ...provided,
                // height: "30px",
                padding: "0 6px",
              }),

              input: (provided, state) => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: (state) => ({
                display: "none",
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
              }),
              menuPortal: (provided) => ({
                ...provided,
                // zIndex: 9999, // Ensure the menu appears above other elements
              }),
            }}
          />
          {roleConfigureQuery.SELECTED_COMPONENTS_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Select Components
            </span>
          )}
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Flow Type
          </Typography>
          <Select
            options={flowType}
            value={roleConfigureQuery.SELECTED_FLOW_TYPE}
            onChange={(e) => {
              setRoleConfigureQuery((prev) => ({
                ...prev,
                SELECTED_FLOW_TYPE: e,
                SELECTED_FLOW_TYPE_ERROR: false,
              }));
            }}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: "#94a3b8",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "center",
                };
              },
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? "#70777d" : "white",
                color: state.isSelected ? "white" : "black",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                padding: 2,
                // marginTop: -5,
                paddingLeft: 5,
                height: 25,
                ":hover": {
                  backgroundColor: "#1770E0",
                  color: "white",
                },
              }),
              control: (provided, state) => ({
                ...provided,
                background: "#fff",
                borderColor: "#E0E0E0",
                borderRadius: 5,
                background: "#fdfdfe",
                boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
                ":hover": {
                  borderColor: "#E0E0E0",
                  cursor: "text",
                },
              }),

              valueContainer: (provided, state) => ({
                ...provided,
                // height: "30px",
                padding: "0 6px",
              }),

              input: (provided, state) => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: (state) => ({
                display: "none",
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
              }),
              menuPortal: (provided) => ({
                ...provided,
                // zIndex: 9999, // Ensure the menu appears above other elements
              }),
            }}
          />
          {roleConfigureQuery.SELECTED_FLOW_TYPE_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Select Flow Type
            </span>
          )}
          <div
            className="footer-buttons"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: 10,
            }}
          >
            <button
              className="bordered-button"
              onClick={() => {
                setRolesAddModalState(false);
              }}
            >
              Cancel
            </button>
            <button
              className="filled-button"
              onClick={() => {
                // campaignNameUpdate();
                ConfigureNewRole();
              }}
              disabled={transactionFlags.CONFIGURE_ROLE_SAVE_LOADING}
            >
              {transactionFlags.CONFIGURE_ROLE_SAVE_LOADING == true ? (
                <Spinner />
              ) : (
                "Create"
              )}
            </button>
          </div>
        </Box>
      </Modal>

      {/* Edit Role  */}
      <Modal
        open={editRolesAddModalState}
        disableAutoFocus
        onClose={() => {
          setEditRolesAddModalState(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="delete-modal-container"
          sx={{
            maxHeight: "95vh",
            overflowY: "auto",
          }}
        >
          <Box
            className="delete-modal-box"
            sx={{
              cursor: "auto",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: COLORS.gray80,
                fontWeight: "bold",
              }}
            >
              Update Roles
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
            >
              <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                <RxCross1
                  onClick={() => {
                    setEditRolesAddModalState(false);
                  }}
                />
              </IconContext.Provider>
            </div>
          </Box>
          <Divider />
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Role Name
          </Typography>
          <CustomInput
            Placeholder={"Role Name"}
            Style={{
              height: 40,
            }}
            Value={editRoleConfigureQuery.ROLE_NAME}
            onChange={(e) => {
              setEditRoleConfigureQuery((prev) => ({
                ...prev,
                ROLE_NAME: e.target.value,
                ROLE_NAME_ERROR: false,
              }));
            }}
          />
          {editRoleConfigureQuery.ROLE_NAME_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Enter Role Name
            </span>
          )}
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Select Screens
          </Typography>
          <Select
            options={screenComponentList}
            isMulti
            onChange={(e) => {
              handleEditScreenChange(e);
            }}
            value={editRoleConfigureQuery.SELECTED_SCREEN}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: "#94a3b8",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "center",
                };
              },
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? "#70777d" : "white",
                color: state.isSelected ? "white" : "black",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                padding: 2,
                // marginTop: -5,
                paddingLeft: 5,
                height: 25,
                ":hover": {
                  backgroundColor: "#1770E0",
                  color: "white",
                },
              }),
              control: (provided, state) => ({
                ...provided,
                background: "#fff",
                borderColor: "#E0E0E0",
                borderRadius: 5,
                background: "#fdfdfe",
                boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
                ":hover": {
                  borderColor: "#E0E0E0",
                  cursor: "text",
                },
              }),

              valueContainer: (provided, state) => ({
                ...provided,
                // height: "30px",
                padding: "0 6px",
              }),

              input: (provided, state) => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: (state) => ({
                display: "none",
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
              }),
              menuPortal: (provided) => ({
                ...provided,
                // zIndex: 9999, // Ensure the menu appears above other elements
              }),
            }}
          />
          {editRoleConfigureQuery.SELECTED_SCREEN_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Select Screens
            </span>
          )}
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Components
          </Typography>
          <Select
            options={SelectedComponentList}
            value={editRoleConfigureQuery.SELECTED_COMPONENTS}
            isMulti
            onChange={(e) => {
              setEditRoleConfigureQuery((prev) => ({
                ...prev,
                SELECTED_COMPONENTS: e,
                SELECTED_COMPONENTS_ERROR: false,
              }));
            }}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: "#94a3b8",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "center",
                };
              },
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? "#70777d" : "white",
                color: state.isSelected ? "white" : "black",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                padding: 2,
                // marginTop: -5,
                paddingLeft: 5,
                height: 25,
                ":hover": {
                  backgroundColor: "#1770E0",
                  color: "white",
                },
              }),
              control: (provided, state) => ({
                ...provided,
                background: "#fff",
                borderColor: "#E0E0E0",
                borderRadius: 5,
                background: "#fdfdfe",
                boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
                ":hover": {
                  borderColor: "#E0E0E0",
                  cursor: "text",
                },
              }),

              valueContainer: (provided, state) => ({
                ...provided,
                // height: "30px",
                padding: "0 6px",
              }),

              input: (provided, state) => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: (state) => ({
                display: "none",
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
              }),
              menuPortal: (provided) => ({
                ...provided,
                // zIndex: 9999, // Ensure the menu appears above other elements
              }),
            }}
          />
          {editRoleConfigureQuery.SELECTED_COMPONENTS_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Select Components
            </span>
          )}
          <Typography
            sx={{
              color: COLORS.gray60,
              fontWeight: "bold",
              fontSize: 14,
              mt: 1,
            }}
          >
            Flow Type
          </Typography>
          <Select
            options={flowType}
            value={editRoleConfigureQuery.SELECTED_FLOW_TYPE}
            onChange={(e) => {
              setEditRoleConfigureQuery((prev) => ({
                ...prev,
                SELECTED_FLOW_TYPE: e,
                SELECTED_FLOW_TYPE_ERROR: false,
              }));
            }}
            styles={{
              placeholder: (defaultStyles) => {
                return {
                  ...defaultStyles,
                  color: "#94a3b8",
                  fontSize: 13,
                  display: "flex",
                  alignItems: "center",
                };
              },
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isSelected ? "#70777d" : "white",
                color: state.isSelected ? "white" : "black",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                padding: 2,
                // marginTop: -5,
                paddingLeft: 5,
                height: 25,
                ":hover": {
                  backgroundColor: "#1770E0",
                  color: "white",
                },
              }),
              control: (provided, state) => ({
                ...provided,
                background: "#fff",
                borderColor: "#E0E0E0",
                borderRadius: 5,
                background: "#fdfdfe",
                boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
                ":hover": {
                  borderColor: "#E0E0E0",
                  cursor: "text",
                },
              }),

              valueContainer: (provided, state) => ({
                ...provided,
                // height: "30px",
                padding: "0 6px",
              }),

              input: (provided, state) => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: (state) => ({
                display: "none",
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
              }),
              menuPortal: (provided) => ({
                ...provided,
                // zIndex: 9999, // Ensure the menu appears above other elements
              }),
            }}
          />
          {editRoleConfigureQuery.SELECTED_FLOW_TYPE_ERROR && (
            <span
              style={{
                color: "red",
                fontSize: 14,
              }}
            >
              Please Select Flow Type
            </span>
          )}
          <div
            className="footer-buttons"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: 10,
            }}
          >
            <button
              className="bordered-button"
              onClick={() => {
                setEditRolesAddModalState(false);
              }}
            >
              Cancel
            </button>
            <button
              className="filled-button"
              onClick={() => {
                updateRole();
              }}
              disabled={transactionFlags.CONFIGURE_ROLE_SAVE_LOADING}
            >
              {transactionFlags.CONFIGURE_ROLE_SAVE_LOADING == true ? (
                <Spinner />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, {})(ManageRoles);
