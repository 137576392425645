import React from "react";
import MainScreen from "../../components/Navbar/MainScreen";
import { Toaster } from "react-hot-toast";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./MarketingDashboard.css";
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

function MarketingDashboard() {
  const navigate = useNavigate();
  const data = {
    labels: [
      "Jan 01",
      "Jan 03",
      "Jan 05",
      "Jan 07",
      "Jan 09",
      "Jan 11",
      "Jan 13",
      "Jan 15",
    ],
    datasets: [
      {
        label: "Abuse report rate",
        data: [15, 8, 18, 5, 12, 14, 10, 8],
        borderColor: "purple",
        backgroundColor: "purple",
        borderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: "purple",
        pointBorderColor: "#fff",
      },
    ],
  };

  // Chart Options
  const options = {
    responsive: true,
    plugins: { legend: { display: false } },
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, max: 24 },
    },
  };
  return (
    <MainScreen>
      <div>
        <Toaster
          containerStyle={{
            top: 20,
            left: 20,
            bottom: 20,
            right: 20,
          }}
          gutter={24}
          toastOptions={{
            style: {
              border: "1px solid #grey",
              padding: "16px",
            },
            success: {
              style: {
                opacity: 0.1,
              },
            },
          }}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
          ml: 2,
          pt: 2,
          pb: 2,
        }}
      >
        <Box
          sx={{
            mr: 1,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <IconContext.Provider value={{ size: 25, color: "#000" }}>
            <FaArrowLeftLong />
          </IconContext.Provider>
        </Box>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: 600,
            color: "#000",
            marginRight: 10,
            textAlign: "start",
          }}
        >
          Marketing Dashboard
        </Typography>
      </Box>
      <div className="container">
        {/* Monitor Performance Card */}
        <Card className="performance-card">
          <CardContent>
            <Typography variant="h6" className="card-title">
              Monitor performance ✏️
            </Typography>
            <Typography variant="subtitle2" className="date-range">
              Feb 21, 2025 - Mar 22, 2025 • Compared to last 30 days •{" "}
              <span className="highlight">Includes</span> Apple MPP
            </Typography>
            <div className="stats-container">
              {[
                {
                  label: "Total sends",
                  value: "150",
                  change: "-50%",
                  color: "red",
                },
                {
                  label: "Open rate",
                  value: "30%",
                  change: "-25%",
                  color: "red",
                },
                {
                  label: "Click rate",
                  value: "24%",
                  change: "+20%",
                  color: "green",
                },
                {
                  label: "Unsubscribe rate",
                  value: "10%",
                  change: "-50%",
                  color: "green",
                },
              ].map((stat, index) => (
                <div key={index} className="stat-box">
                  <Typography variant="h6" className="stat-label">
                    {stat.label}
                  </Typography>
                  <Typography variant="h4" className="stat-value">
                    {stat.value}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`stat-change ${stat.color}`}
                  >
                    {stat.change}
                  </Typography>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Performance Over Time Card */}
        <Card className="performance-card">
          <CardContent>
            <Typography variant="h6" className="card-title">
              Performance over time
            </Typography>
            <Typography variant="body2" className="click-rate">
              Click rate <span className="bold-text">0%</span> -- Compared to
              last 30 days
            </Typography>
            <Typography variant="subtitle2" className="metric-label">
              Metric: <span className="bold-text">Click rate</span>
            </Typography>
            <div className="time-filters">
              <button className="filter-button active">Day</button>
              <button className="filter-button">Week</button>
              <button className="filter-button">Month</button>
            </div>
            <div className="message-stats">
              <Typography variant="body2">
                Total message count: <span className="bold-text">34</span>
              </Typography>
              <Typography variant="body2">
                Total messages sent: <span className="bold-text">600</span>
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card className="delivery-card">
          <CardContent>
            <Typography variant="h6" className="card-title">
              Delivery
            </Typography>
            <Typography variant="body2" className="email-tab">
              Email
            </Typography>
            <div className="total-deliveries">
              <Typography variant="h3">1,200</Typography>
              <span className="increase-rate">↑ 29.0%</span>
              <Typography variant="body2" className="comparison-text">
                Compared to last 30 days
              </Typography>
            </div>

            {/* Chart */}
            <div style={{
              display:"flex",
              justifyContent:"space-between",
            }}>
              <div className="chart-container">
                <Line data={data} options={options} />
              </div>

              {/* Metric Selector & Time Filters */}
              <div>
                <div className="metrics-filters">
                  <Typography variant="body2" className="metric-label">
                    Metric: <span className="bold-text">Abuse report rate</span>
                  </Typography>
                  <div className="time-filters">
                    <button className="filter-button active">Day</button>
                    <button className="filter-button">Week</button>
                    <button className="filter-button">Month</button>
                  </div>
                </div>

                {/* Delivery Details */}
                <div className="delivery-details">
                  <div className="detail-item">
                    <span>Emails sent</span> <span>1,000</span>
                  </div>
                  <div className="detail-item">
                    <span>Deliveries</span> <span>1,200</span>
                  </div>
                  <div className="detail-item">
                    <span>Bounces</span> <span>100</span>
                  </div>
                  <div className="detail-item">
                    <span>Unsubscribed</span> <span>50</span>
                  </div>
                  <div className="detail-item">
                    <span>Abuse reports</span> <span>2</span>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </MainScreen>
  );
}

export default MarketingDashboard;
