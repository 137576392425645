// const defaultPort = "https://pdhanewala.com:9001/"; // truetalent prd
const defaultPort = "https://pdhanewala.com:9002/"; // crm dev
const userLogin = "cms/login/login";
const userLogout = "cms/login/logout";
const forgetPassword = "cms/forgetPassword/forgetPassword";
const verifyOtp = "cms/forgetPassword/verifyOtp";
const changePassword = "cms/forgetPassword/changePassword";
const uploadContacts = "cms/contacts/uploadContacts";
const sessionTokenVerify = "apis/Login/sessionTokenVerify";
const meetingFilter = "cms/meetings/meetingFilter";
const getMeetingUpdate = "cms/meetingUpdate/getMeetingUpdate";
const LeadStatusCrManagerWise = "cms/meetings/LeadStatusCrManagerWise";
const employeeUpdate = "apis/sharepoint/employeeUpdate";
const contactDataGet = "apis/sharepoint/contactDataGet";
const updateCampaign = "cms/campaign/updateCampaign";
const emailRecipientGet = "cms/campaign/emailRecipientGet";
const getCampaignById = "cms/campaign/getCampaignById";
const recipientContactImport = "cms/campaign/recipientContactImport";
const getCampaign = "cms/campaign/getCampaign";
const createCampaign = "cms/campaign/createCampaign";
const getTemplate = "cms/emailTemplate/getTemplate";
const getTemplateByCampaignId =
  "cms/emailTemplateCampaign/getTemplateByCampaignId";
const createCampaignTemplate =
  "cms/emailTemplateCampaign/createCampaignTemplate";
const emailSend = "cms/campaign/emailSend";
const testEmailSend = "cms/campaign/testEmailSend";
const getCountsForCampaign = "cms/campaign/getCountsForCampaign";
const last24HrPerformanceGraph = "cms/campaign/last24HrPerformanceGraph";
const crmBulkUpdate = "cms/contacts/crmBulkUpdate";
const contactBulkDelete = "cms/contacts/contactBulkDelete";
const deleteRecipient = "cms/emailReceivers/deleteRecipient";
const createTemplate = "cms/emailTemplate/createTemplate";
const roleWiseEmpGet = "apis/sharepoint/roleWiseEmpGet";
const createSubCampaign = "cms/campaign/createSubCampaign";
const updatePersonalizationGreetings =
  "cms/campaign/updatePersonalizationGreetings";
const getCampaignAll = "cms/campaign/getCampaignAll";
const getCampaignRecipient = "cms/emailReceivers/getCampaignRecipient";
const recipientImportFromArray = "cms/campaign/recipientImportFromArray";
const screenCreateUpdate = "cms/screenMaster/screenCreateUpdate";
const screenGet = "cms/screenMaster/screenGet";
const componentGet = "cms/componentMaster/componentGet";
const componentCreateUpdate = "cms/componentMaster/componentCreateUpdate";
const screenComponentMap = "cms/screenComponentMap/screenComponentMap";
const screenComponentMapGet = "cms/screenComponentMap/screenComponentMapGet";
const rolesGet = "cms/roleMaster/rolesGet";
const getComponentsByScreen = "cms/screenComponentMap/getComponentsByScreen";
const roleCreate = "cms/roleMaster/roleCreate";
const roleUpdate = "cms/roleMaster/roleUpdate";
const rolesGetByEmpId = "cms/roleMaster/rolesGetByEmpId";
const meetingDeleteById = "cms/meetings/meetingDeleteById";
const contactCountByEngagementStatus =
  "apis/sharepoint/contactCountByEngagementStatus";
const deleteCampaignContent = "cms/campaign/deleteCampaignContent";
const getChildrenCampaigns = "cms/campaign/getChildrenCampaigns";
const notificationGet = "cms/notificationMaster/notificationGet";
const notificationSeenRead = "cms/notificationMaster/notificationSeenRead";
const getContactById = "cms/contacts/getContactById";

export default {
  defaultPort,
  userLogin,
  forgetPassword,
  verifyOtp,
  changePassword,
  userLogout,
  uploadContacts,
  sessionTokenVerify,
  meetingFilter,
  getMeetingUpdate,
  LeadStatusCrManagerWise,
  employeeUpdate,
  contactDataGet,
  updateCampaign,
  emailRecipientGet,
  getCampaignById,
  recipientContactImport,
  getCampaign,
  createCampaign,
  getTemplate,
  createCampaignTemplate,
  getTemplateByCampaignId,
  emailSend,
  testEmailSend,
  getCountsForCampaign,
  last24HrPerformanceGraph,
  crmBulkUpdate,
  contactBulkDelete,
  deleteRecipient,
  createTemplate,
  roleWiseEmpGet,
  createSubCampaign,
  updatePersonalizationGreetings,
  getCampaignAll,
  getCampaignRecipient,
  recipientImportFromArray,
  screenCreateUpdate,
  screenGet,
  componentGet,
  componentCreateUpdate,
  screenComponentMap,
  screenComponentMapGet,
  rolesGet,
  roleCreate,
  roleUpdate,
  getComponentsByScreen,
  rolesGetByEmpId,
  meetingDeleteById,
  contactCountByEngagementStatus,
  deleteCampaignContent,
  getChildrenCampaigns,
  notificationGet,
  notificationSeenRead,
  getContactById,
};
