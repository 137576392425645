import bg1 from "./bg1.png";
import bg2 from "./bg2.png";
import bg3 from "./bg3.png";
import loginPng from "./login.png";
import ImageUpload from "./image-upload.png";
import camera from "./camera.png";
import selectImage from "./upload-svgrepo-com.svg";
import empty_box_svg from "./empty-box-svgrepo-com.svg";
export default {
  bg1,
  bg2,
  bg3,
  loginPng,
  ImageUpload,
  camera,
  selectImage,
  empty_box_svg,
};
