import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Dashboard.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Divider,
  Modal,
  CircularProgress,
} from "@mui/material";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Images from "../assets/images/Images";
import Icons from "../constants/Icons";
// import * as microsoftTeams from "@microsoft/teams-js";
import { connect, useDispatch } from "react-redux";
import { COLORS } from "../constants/theme";
import Page404 from "./Page404";
import Lottie from "react-lottie-player";
import loadingAnime from "../assets/lottieFiles/loader.json";
import NoDataFoundAnime from "../assets/lottieFiles/no-data-found.json";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import welcomeGreetings from "../assets/images/welcome-greeting.svg";
import ReactDataTable from "../components/ReactDataTable/ReactDataTable";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DateRangePicker from "../components/CustomInput/DateRangePicker";
import AXIOS from "../utils/AXIOS";
import CustomDropdown from "../components/CustomDropDown/CustomDropDown";
import toast, { Toaster } from "react-hot-toast";
import CustomButton from "../components/CustomButton/CustomButton";
import { IconContext } from "react-icons";
import { RxCross1 } from "react-icons/rx";
import { engagementStatus } from "../utils/Constants";
import MainScreen from "../components/Navbar/MainScreen";
import UseContactLoading from "./UseContactLoading";
import { motion } from "framer-motion";
import CustomInput from "../components/CustomInput/CustomInput";
import axios from "axios";

function Dashboard(props) {
  const navigate = useNavigate();
  const [viewAccess, setViewAccess] = useState(false);
  const [empExist, setEmpExist] = useState("NODATA");
  const [role, setRole] = useState("");
  const dispatch = useDispatch();
  const [Tbody, setTbody] = useState([]);
  const [TbodyCopy, setTbodyCopy] = useState([]);

  const [TbodyLoading, setTbodyLoading] = useState(true);

  const [dashboardQuery, setDashboardQuery] = useState({
    START_DATE: "",
    END_DATE: "",
  });

  const [DeleteConfirmContactModal, setDeleteConfirmContactModal] =
    useState(false);
  const [CRManagerQuery, setCRManagerQuery] = useState({
    CR_MANAGER: {
      label: null,
      value: null,
    },
    ERROR_FLAG: false,
  });

  const [selectedContacts, setSelectedContacts] = useState({
    ids: [], // stores selected contact IDs
  });

  const updateBulkCRManagers = () => {
    let tempTbody = [...Tbody];
    let selectedData = [];
    tempTbody.map((item) => {
      if (item.checked == true) {
        selectedData.push(item._id);
      }
    });
    if (CRManagerQuery.CR_MANAGER.value != null) {
      fetch(AXIOS.defaultPort + AXIOS.crmBulkUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          crManager: CRManagerQuery.CR_MANAGER._id,
          contactIds: selectedData,
          allCrmUpdate: isAllSelected,
          updatedBy: props.auth_id,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          // setSelectedContacts({
          //   ids: [],
          // });
          toast.success("CR Manager updated");

          setPageNumber(1);
          setIsAllSelected(false);
          let tempTbody = [...Tbody];
          tempTbody.checked = false;
          setTbody(tempTbody);
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } else {
      toast.error("Please select CR Manager");
      setCRManagerQuery((prev) => ({
        ...prev,
        ERROR_FLAG: true,
      }));
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [selectedSearchKey, setSelectedSearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedEngagementStatus, setSelectedEngagementStatus] = useState("");

  const [isAllSelected, setIsAllSelected] = useState(false);
  const handleCheckboxChange = (_id, index) => {
    let tempTbody = [...Tbody];

    tempTbody[index].checked = !tempTbody[index].checked;
    let tempIsAllSelected = tempTbody.find((item) => item.checked == false);
    if (tempIsAllSelected) {
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
    }
    setTbody(tempTbody);
  };

  const { loading, error, ContactList, hasMore } = UseContactLoading(
    pageNumber,
    props.auth_id,
    selectedSearchKey,
    searchValue,
    setPageNumber,
    dashboardQuery,
    selectedEngagementStatus,
    isAllSelected
  );

  useEffect(() => {
    setTbody(ContactList);
    dispatch({
      type: "CONTACT_DATA_GET",
      payload: ContactList,
    });
    //  setTbodyCopy(Tickets);
    // setDashboardCardLengths(Tickets);
  }, [ContactList]);

  const [apiSearchQuery, setAPISearchQuery] = useState({
    SEARCH_KEY: { label: "Contact Name", value: "name0" },
    SEARCH_VALUE: "",
    SEARCH_LOADING: false,
    SEARCH_INPUT_TYPE: "text", //"dropdown",
    SEARCH_DROPDOWN_OPTIONS: [],
  });

  const [searchKeyOptions, setSearchKeyOptions] = useState([
    { label: "Contact Name", value: "name0", type: "text", option: [] },
    { label: "Designation", value: "designation", type: "text", option: [] },
    // { label: "Recipient Email", value: "RECIPIENT_EMAIL" },
    { label: "Email", value: "email", type: "text", option: [] },
    { label: "Contact", value: "contact", type: "text", option: [] },
    { label: "Company", value: "company", type: "text", option: [] },
    {
      label: "Engagement Status",
      value: "engagementStatus",
      type: "dropdown",
      option: engagementStatus,
    },
    {
      label: "CR Manager",
      value: "crManager",
      type: "dropdown",
      option: [],
    },
    { label: "Department", value: "department", type: "text", option: [] },
    { label: "Created By", value: "createdBy", type: "dropdown", option: [] },
  ]);

  useEffect(() => {
    if (props.employee_data && props.employee_data.length > 0) {
      let temEmployeeData = [];
      props.employee_data.map((val) => {
        temEmployeeData.push({
          ...val,
          value: val._id,
        });
      });
      let tempSearchKeyOptions = [...searchKeyOptions];
      let crManagerIndex = tempSearchKeyOptions.findIndex(
        (val) => val.value == "crManager"
      );
      let createdByIndex = tempSearchKeyOptions.findIndex(
        (val) => val.value == "createdBy"
      );
      tempSearchKeyOptions[crManagerIndex].option = temEmployeeData;
      tempSearchKeyOptions[createdByIndex].option = temEmployeeData;
      setSearchKeyOptions(tempSearchKeyOptions);
    }
  }, [props.employee_data]);

  const handleContactSearch = () => {
    setSearchValue(apiSearchQuery.SEARCH_VALUE);
    setSelectedSearchKey(apiSearchQuery.SEARCH_KEY.value);
  };

  useEffect(() => {}, [ContactList]);

  let observer = useRef();

  const lastContactRef = useCallback(
    (node) => {
      if (loading) return;

      // Disconnect previous observer if it exists
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, setPageNumber] // Ensure dependencies are correct
  );

  const deleteBulkContacts = () => {
    let tempTbody = [...Tbody];
    let selectedData = [];
    tempTbody.map((item) => {
      if (item.checked == true) {
        selectedData.push(item._id);
      }
    });

    if (selectedData.length > 0) {
      fetch(AXIOS.defaultPort + AXIOS.contactBulkDelete, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contactIds: selectedData,
          updatedBy: props.auth_id,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();

          toast.success("Contacts Deleted");
          setDeleteConfirmContactModal(false);
          // setSelectedContacts({
          //   ids: [],
          // });
          setPageNumber(1);
          setIsAllSelected(false);

          setTbody([]);
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } else {
      toast.error("Please select contact");
    }
  };

  useEffect(() => {
    props
      .EmployeeDetailsGetAction()
      .then((res) => {})
      .catch((err) => {
        setEmpExist("ECONNREF");
      });
  }, []);

  useEffect(() => {
    if (props.employee_data.length > 0 && props.auth_email_id) {
      if (
        props.employee_data.some((val) => val.email_id == props.auth_email_id)
      ) {
        setEmpExist(true);
        let empRole = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;
        setRole(empRole);
      } else {
        setEmpExist(false);
      }
    }
  }, [props.auth_email_id, props.employee_data]);

  useEffect(() => {
    if (props.employee_data) {
      if (
        props.employee_data.filter((val) => val.email_id == props.auth_email_id)
          .length > 0
      ) {
        let role = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;

        if (role == "view") {
          setViewAccess(true);
        }
      }
    }
  }, [props.employee_data]);

  const textStyle = {
    fontSize: 22,
    fontWeight: "bold",
    color: COLORS.gray70,
    mt: 2,
  };

  const [userDetails, setUserDetails] = useState(null);

  function formatDateToDDMMYYYY(isoDate) {
    const date = new Date(isoDate);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  }
  const getUserDetails = async (token) => {
    // Decode JWT to extract the payload
    const base64Url = token.split(".")[1]; // Get the payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Normalize base64 string
    const payload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const decodedToken = JSON.parse(payload);

    // Extract 'oid' from the decoded token
    const oid = decodedToken.oid;
    const upn = decodedToken.upn;
    // Instead of fetching user details, directly set OID and token in state
    setUserDetails({
      OBJECT_ID: oid,
      token: token, // Store the token directly
      EMAIL_ID: upn,
    });
  };

  const [authName, setAuthName] = useState("");
  useEffect(() => {
    setAuthName(props.auth_name);
  }, [props.auth_name]);

  const handleSelectAll = () => {
    let tempTbody = [...Tbody];

    tempTbody.map((val) => {
      val.checked = !isAllSelected;
    });
    setIsAllSelected(!isAllSelected);
  };

  const ContactColumns = [
    {
      name: (
        <input
          type="checkbox"
          checked={isAllSelected}
          onChange={() => {
            handleSelectAll();
          }}
        />
      ),
      selector: (row) => row._id,
      width: "50px",
      cell: (row) => (
        <input
          type="checkbox"
          // checked={selectedContacts.ids.includes(row._id}
          // onChange={() => handleCheckboxChange(row._id, index)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "View",
      selector: (val) => val.company,
      sortable: false,
      width: "60px",
      cell: (val) => {
        return (
          <span>
            <RemoveRedEyeIcon
              sx={{
                color: "#A0C7FA",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/contactDetails", {
                  state: {
                    searchContact: val,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
    {
      name: "Contact Name",
      width: "180px",
      selector: (val) => val.name0,
      sortable: true,
      cell: (val) => {
        return (
          <span
            style={{
              color: "#1770E0",
              cursor: "pointer",
              textAlign: "start",
            }}
            onClick={() => {
              navigate("/contactDetails", {
                state: {
                  searchContact: val,
                },
              });
            }}
          >
            {val.name0}
          </span>
        );
      },
    },
    {
      name: "Designation",
      width: "200px",
      selector: (val) => val.designation,
      sortable: true,
    },
    {
      name: "Email",
      width: "200px",
      selector: (val) => val.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.mobile,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.company,
      sortable: true,
      width: "200px",
      cell: (val) => {
        return (
          <span
            style={{
              color: "#1770E0",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/companyView", {
                state: {
                  companyName: val.company,
                },
              });
            }}
          >
            {val.company}
          </span>
        );
      },
    },
    {
      name: "CR Manager",
      width: "200px",
      selector: (val) => val.crManagerName,
      sortable: true,
    },
    {
      name: "Engagement Status",
      width: "160px",
      selector: (val) => val.engagementStatus,
      sortable: true,
      cell: (val) => {
        let engagementData = engagementStatus.find(
          (item) => item.value == val.engagementStatus
        );

        return (
          <span
            style={{
              backgroundColor: engagementData?.color,
              padding: 5,
              borderRadius: 5,
              color: "#fff",
            }}
          >
            {engagementData?.label}
          </span>
        );
      },
    },
    {
      name: "Address",
      selector: (val) => val.address,
      sortable: true,
      width: "200px",
    },
    {
      name: "City",
      selector: (val) => val.city,
      sortable: true,
    },
    {
      name: "Department",
      selector: (val) => val.department,
      sortable: true,
    },
    {
      name: "Industry",
      selector: (val) => val.industry,
      sortable: true,
    },
    {
      name: "Level",
      selector: (val) => val.level,
      sortable: true,
    },

    {
      name: "Created By",
      selector: (val) => val.CREATED_BY_NAME,
      sortable: true,
      width: "150px",
    },
    {
      name: "Created Date",
      selector: (val) => val.CREATED_DATE,
      sortable: true,
      width: "150px",
      cell: (val) => {
        return (
          <span>
            {formatDateToDDMMYYYY(val.CREATED_DATE)}{" "}
            {new Date(val.CREATED_DATE).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}{" "}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.auth_id) {
      setSelectedContacts({
        ids: [],
      });
      // getContactData();
    }
  }, [dashboardQuery, props.auth_id]);

  const [transactionFlags, setTransactionFlags] = useState({
    TBODY_EMPTY: false,
  });
  const getContactData = () => {
    fetch(AXIOS.defaultPort + AXIOS.contactDataGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        START_DATE: dashboardQuery.START_DATE,
        END_DATE: dashboardQuery.END_DATE,
        AUTH_ID: props.auth_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (data?.value) {
          if (data?.value.length <= 0) {
            setTransactionFlags({
              TBODY_EMPTY: true,
            });
          }

          setTbody(data.value);
          setTbodyLoading(false);
        }
      })
      .catch((err) => {
        setTbodyLoading(false);
      });
  };

  useEffect(() => {
    if (props.auth_id) {
      getEngagementCounts();
    }
  }, [props.auth_id]);
  const [dashboardCards, setDashboardCards] = useState([]);

  const [selectedEngagement, setSelectedEngagement] = useState("");

  const getEngagementCounts = () => {
    axios
      .post(AXIOS.defaultPort + AXIOS.contactCountByEngagementStatus, {
        AUTH_ID: props.auth_id,
      })
      .then((response) => {
        // const total = response.data.reduce((acc, val) => acc + val, 0);
        const total = Object.values(response.data).reduce(
          (acc, val) => acc + val,
          0
        );

        let tempEngagementStatus = [
          {
            label: "All",
            value: "",
            TOTAL: total,
            SHOW: false,
          },
        ];

        engagementStatus.map((val) => {
          tempEngagementStatus.push({
            ...val,
            TOTAL: response.data[Number(val.value)] ?? 0,
            SHOW: false,
            // [val.value]: response.data[val.value],
          });
        });
        setSelectedEngagement(tempEngagementStatus[0]);

        setDashboardCards(tempEngagementStatus);
      })
      .catch((err) => {});
  };

  return (
    <MainScreen>
      <Box
        className="dashboard-main-container contact-main dashboard-bg"
        sx={{}}
      >
        {/* <Toaster /> */}

        <Box
          sx={{
            pt: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontSize: 18,
              marginLeft: 15,
              fontWeight: "bold",
            }}
          >
            Welcome {authName} !
          </span>

          <Box
            sx={{
              mr: 2,
              gap: 1,
              width: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                // mr: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {}}
            >
              <div
                style={{
                  width: 180,
                }}
              >
                <CustomDropdown
                  Options={searchKeyOptions}
                  isSearchable={false}
                  Value={apiSearchQuery.SEARCH_KEY}
                  OnChange={(e) => {
                    setAPISearchQuery((prev) => ({
                      ...prev,
                      SEARCH_KEY: e,
                      SEARCH_INPUT_TYPE: e.type,
                      SEARCH_DROPDOWN_OPTIONS: e?.option,
                      SEARCH_VALUE:
                        e.type === "dropdown"
                          ? e.option.length > 0
                            ? e.option[0].value
                            : ""
                          : prev.SEARCH_INPUT_TYPE === "dropdown"
                            ? ""
                            : prev.SEARCH_VALUE,
                    }));
                  }}
                />
              </div>
            </Box>
            {apiSearchQuery.SEARCH_INPUT_TYPE == "text" && (
              <CustomInput
                Placeholder={"Search"}
                Value={apiSearchQuery.SEARCH_VALUE}
                Type={"text"}
                onChange={(e) => {
                  setAPISearchQuery((prev) => ({
                    ...prev,
                    SEARCH_VALUE: e.target.value,
                  }));
                }}
                onKeyDown={(e) => {
                  // If only Enter is pressed (without Shift or Ctrl)
                  if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
                    e.preventDefault(); // Prevent newline
                    setAPISearchQuery((prev) => ({
                      ...prev,
                      SEARCH_LOADING: true,
                    }));
                    handleContactSearch(); // Trigger submit
                  }
                }}
              />
            )}
            {apiSearchQuery.SEARCH_INPUT_TYPE == "dropdown" && (
              <div
                style={{
                  width: 180,
                }}
              >
                <CustomDropdown
                  Options={apiSearchQuery.SEARCH_DROPDOWN_OPTIONS}
                  isSearchable={false}
                  Value={
                    apiSearchQuery.SEARCH_DROPDOWN_OPTIONS &&
                    apiSearchQuery.SEARCH_DROPDOWN_OPTIONS.find(
                      (item) => item.value == apiSearchQuery.SEARCH_VALUE
                    )
                  }
                  OnChange={(e) => {
                    setAPISearchQuery((prev) => ({
                      ...prev,
                      SEARCH_VALUE: e.value,
                    }));
                  }}
                  onKeyDown={(e) => {
                    // If only Enter is pressed (without Shift or Ctrl)
                    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
                      e.preventDefault(); // Prevent newline
                      setAPISearchQuery((prev) => ({
                        ...prev,
                        SEARCH_LOADING: true,
                      }));
                      handleContactSearch(); // Trigger submit
                    }
                  }}
                />
              </div>
            )}
            <button
              className="status running"
              style={{
                cursor: "pointer",
                minWidth: 70,
                justifyContent: "center",
                minHeight: 30,
              }}
              onClick={() => {
                setAPISearchQuery((prev) => ({
                  ...prev,
                  SEARCH_LOADING: true,
                }));
                handleContactSearch();
              }}
            >
              Search
            </button>
            <button
              className="status running"
              style={{
                cursor: "pointer",
                minWidth: 70,
                justifyContent: "center",
                minHeight: 30,
                color: "red",
                borderColor: "red",
              }}
              onClick={() => {
                setAPISearchQuery((prev) => ({
                  ...prev,
                  SEARCH_VALUE: "",
                  SEARCH_LOADING: false,
                  SEARCH_KEY: { label: "Contact Name", value: "name0" },
                }));
                setSearchValue("");
                setSelectedSearchKey("");
                setPageNumber(1);
                setTbody(TbodyCopy);
                setSelectedEngagement(dashboardCards[0]);
              }}
            >
              Reset
            </button>
          </Box>
        </Box>

        <div className="dashboard-container" style={{ marginTop: 5 }}>
          {dashboardCards.length > 0
            ? dashboardCards.map((val, index) => (
                <div
                  className={`dashboard-card ${val.value === selectedEngagement?.value ? "active" : ""}`}
                  key={index}
                  onClick={() => {
                    setSelectedEngagement(val);
                    // setPageNumber(1);
                    lastContactRef.current = null;
                    if (val.value == "") {
                      setSelectedSearchKey("");
                    } else {
                      setSelectedSearchKey("engagementStatus");
                    }
                    setSearchValue(val.value);
                  }}
                >
                  <p
                    className={`card-title-tag ${val.value === selectedEngagement?.value ? "active" : ""}`}
                  >
                    {val.label}
                  </p>
                  <p
                    className={`card-total ${val.value === selectedEngagement?.value ? "active" : ""}`}
                  >
                    {val.TOTAL}
                  </p>
                </div>
              ))
            : [...Array(6)].map((_, index) => (
                <div className="dashboard-card-skelton skeleton" key={index}>
                  <div className="skeleton-title"></div>
                  <div className="skeleton-total"></div>
                </div>
              ))}
        </div>

        {empExist == false && <Page404 />}
        {empExist == "ECONNREF" && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                color: "#205aa3",
              }}
            >
              Oops! Server Error, Please try again after some time!!
            </h3>
          </Box>
        )}
        {/* <Box
        style={{
          marginTop: 50,
        }}
      /> */}
        {empExist == "NODATA" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              height: "100vh",
            }}
          >
            <Lottie
              // className="loading-lottie"
              style={{
                width: 250,
                height: 250,
              }}
              play
              //   speed={"1"}
              animationData={loadingAnime}
            />
          </Box>
        )}

        {props.components_list.includes("crm-dashboard") && (
          <Card
            sx={{
              pl: 1,
              ml: 1,
              pr: 1,
              mr: 1,
            }}
          >
            {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                width: 200,
                margin: 2,
              }}
            >
              <Typography
                style={{
                  fontSize: 18,
                  color: "#000",
                  textAlign: "start",
                }}
              >
                Date Range:
              </Typography>

              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "300px",
                  width: "100px",
                  height: "35px",
                  cursor: "pointer",
                  backgroundColor: "#219bcc",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  zIndex: 0,
                  fontSize: 13,
                }}
                // onClick={handleExport}
                onClick={() => {}}
              >
                <DateRangePicker
                  defaultStartDate={dashboardQuery.START_DATE}
                  defaultEndDate={dashboardQuery.END_DATE}
                  onSelectDateRange={(startDate, endDate) => {
                    setDashboardQuery((prev) => ({
                      ...prev,
                      START_DATE: startDate,
                      END_DATE: endDate,
                    }));
                  }}
                />
              </div>
            </Box>
          </Box> */}

            <Box
              className="as-inner-container"
              sx={{
                ml: 1,
                mb: 1,
              }}
            >
              <div className="fields-width">
                <Typography
                  style={{
                    fontSize: 15,
                    color: COLORS.gray50,
                    marginTop: 10,
                  }}
                >
                  Date Range
                </Typography>
                <DateRangePicker
                  defaultStartDate={dashboardQuery.START_DATE}
                  defaultEndDate={dashboardQuery.END_DATE}
                  onSelectDateRange={(startDate, endDate) => {
                    setDashboardQuery((prev) => ({
                      ...prev,
                      START_DATE: startDate,
                      END_DATE: endDate,
                    }));
                  }}
                />
              </div>
              {Tbody.find((item) => item.checked == true) && (
                <>
                  {props.components_list.includes("bulk-cr-edit") && (
                    <>
                      <div className="fields-width">
                        <Typography
                          style={{
                            fontSize: 15,
                            color: COLORS.gray50,
                            marginTop: 10,
                          }}
                        >
                          CR Manager
                        </Typography>
                        <CustomDropdown
                          menuPortalTarget={true}
                          Label={"CR Manager"}
                          Options={props.employee_data}
                          Value={CRManagerQuery?.CR_MANAGER}
                          placeholder={"Select CR Manager"}
                          error={CRManagerQuery.ERROR_FLAG}
                          OnChange={(e) => {
                            // Handle dropdown change
                            setCRManagerQuery((prev) => ({
                              ...prev,
                              CR_MANAGER: e,
                              ERROR_FLAG: false,
                            }));
                          }}
                        />
                        {CRManagerQuery.ERROR_FLAG == true && (
                          <>
                            <Typography
                              style={{
                                fontSize: 15,
                                color: COLORS.red1,
                              }}
                            >
                              Please enter CR Manager
                            </Typography>
                          </>
                        )}
                      </div>
                      <div className="fields-width">
                        <button
                          className="signup-button"
                          style={{
                            marginRight: 10,
                            minWidth: 150,
                            minHeight: 34,
                            maxHeight: 30,
                            marginTop: 30,
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#23a466",
                          }}
                          onClick={() => {
                            updateBulkCRManagers();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  )}
                  {props.components_list.includes("bulk-contact-delete") && (
                    <div className="fields-width">
                      <button
                        className="signup-button"
                        style={{
                          marginRight: 10,
                          minWidth: 120,
                          minHeight: 34,
                          maxHeight: 30,
                          marginTop: 30,
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#e63946",
                        }}
                        onClick={() => {
                          setDeleteConfirmContactModal(true);
                        }}
                      >
                        Delete Contacts
                      </button>
                    </div>
                  )}
                </>
              )}
            </Box>
            {transactionFlags?.TBODY_EMPTY == true && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Lottie
                  play
                  //   speed={"1"}
                  animationData={NoDataFoundAnime}
                  style={{
                    width: 300,
                    height: 300,
                  }}
                />
              </Box>
            )}
            <div
              className="dashboard-table-container"
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "calc(100vh - 240px)",
                flexGrow: 1, // Allows it to take available space
                justifyContent: loading ? "center" : "flex-start",
              }}
            >
              {/* <div
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   maxHeight: "calc(100vh - 190px)",
              //   flexGrow: 1, // Allows it to take available space
              //   justifyContent: loading ? "center" : "flex-start", // Centers loader when no data
              // }}
              > */}
              <table
                className="dashboard-table fade-in"
                style={{
                  minHeight: 0,
                }}
                // style={{ width: "100%", borderCollapse: "collapse" }}
              >
                {/* Sticky Header */}
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "#fff",
                    // zIndex: 10,
                  }}
                >
                  <tr>
                    <th
                      style={{
                        minWidth: "50px",
                        maxWidth: "50px",
                        textAlign: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th
                      style={{
                        minWidth: "60px",
                        maxWidth: "60px",
                        textAlign: "center",
                      }}
                    >
                      View
                    </th>
                    <th
                      style={{
                        minWidth: "180px",
                        maxWidth: "180px",
                        textAlign: "center",
                      }}
                    >
                      Contact Name
                    </th>
                    <th
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      Designation
                    </th>
                    <th
                      style={{
                        minWidth: "200px",
                        maxWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      Email
                    </th>
                    <th
                      style={{
                        minWidth: "120px",
                        maxWidth: "120px",
                        textAlign: "center",
                      }}
                    >
                      Mobile
                    </th>
                    <th
                      style={{
                        minWidth: "200px",
                        maxWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      Company
                    </th>
                    <th
                      style={{
                        minWidth: "200px",
                        maxWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      CR Manager
                    </th>
                    <th
                      style={{
                        minWidth: "80px",
                        maxWidth: "80px",
                        textAlign: "center",
                      }}
                    >
                      Engagement
                    </th>
                    <th
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        textAlign: "center",
                      }}
                    >
                      Address
                    </th>
                    <th
                      style={{
                        minWidth: "200px",
                        maxWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      City
                    </th>
                    <th
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      Department
                    </th>
                    <th
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      Industry
                    </th>
                    <th
                      style={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        textAlign: "center",
                      }}
                    >
                      Created By
                    </th>
                    <th
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        textAlign: "center",
                      }}
                    >
                      Created Date
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {Tbody.map((val, index) => {
                    let engagementData = engagementStatus.find(
                      (item) => item.value === val.engagementStatus
                    );
                    const isFifthLast =
                      ContactList.length - index === 5 ||
                      ContactList.length === 5;
                    return (
                      <tr
                        // ref={Tbody.length - 1 === index ? lastContactRef : null}
                        ref={isFifthLast ? lastContactRef : null}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        key={val._id}
                      >
                        <td style={{ textAlign: "center" }}>
                          <input
                            type="checkbox"
                            checked={val.checked}
                            onChange={() =>
                              handleCheckboxChange(val._id, index)
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <RemoveRedEyeIcon
                            sx={{
                              color: "#4D94F2",
                              cursor: "pointer",
                              width: 20,
                              height: 20,
                            }}
                            onClick={() =>
                              navigate("/contactDetails", {
                                state: { searchContact: val },
                              })
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            style={{
                              color: "#1770E0",
                              cursor: "pointer",
                              textAlign: "center",
                            }}
                            onClick={() =>
                              navigate("/contactDetails", {
                                state: { searchContact: val },
                              })
                            }
                          >
                            {val.name0}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {val.designation}
                        </td>
                        <td style={{ textAlign: "center" }}>{val.email}</td>
                        <td style={{ textAlign: "center" }}>{val.mobile}</td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            style={{
                              color: "#1770E0",
                              cursor: "pointer",
                              textAlign: "center",
                            }}
                            onClick={() =>
                              navigate("/companyView", {
                                state: { companyName: val.company },
                              })
                            }
                          >
                            {val.company}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {val.crManagerName}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: engagementData?.color,
                              padding: 5,
                              borderRadius: 5,
                              color: "#fff",
                            }}
                          >
                            {engagementData?.label}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>{val.address}</td>
                        <td style={{ textAlign: "center" }}>{val.city}</td>
                        <td style={{ textAlign: "center" }}>
                          {val.department}
                        </td>
                        <td style={{ textAlign: "center" }}>{val.industry}</td>
                        <td style={{ textAlign: "center" }}>{val.level}</td>
                        <td style={{ textAlign: "center" }}>
                          {val.CREATED_BY_NAME}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {formatDateToDDMMYYYY(val.CREATED_DATE)}{" "}
                          {new Date(val.CREATED_DATE).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    // textAlign: "center",
                    // minHeight: "200px",
                  }}
                >
                  <Lottie
                    style={{ width: 100, height: 100 }}
                    play
                    animationData={loadingAnime}
                  />
                </div>
              )}

              {/* No Data Found */}
              {!loading && Tbody.length <= 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 15,
                    marginBottom: 5,
                  }}
                >
                  No Data Found
                </div>
              )}
              {/* Loading Animation */}
            </div>
            {/* </div> */}
          </Card>
        )}

        <Modal
          open={DeleteConfirmContactModal}
          onClose={() => {
            setDeleteConfirmContactModal(false);
          }}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
        >
          <Box className="delete-modal-container">
            <Box
              className="delete-modal-box"
              sx={{
                cursor: "auto",
                mt: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: COLORS.gray80,
                  fontWeight: "bold",
                }}
              >
                Confirmation
              </Typography>
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                  <RxCross1
                    onClick={() => {
                      setDeleteConfirmContactModal(false);
                    }}
                  />
                </IconContext.Provider>
              </div>
            </Box>

            <Divider />
            <Typography
              sx={{
                fontSize: 16,
                color: COLORS.red,
                mt: 1,
              }}
            >
              Total Contacts to be deleted:{" "}
              {Tbody.filter((item) => item.checked == true).length}
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: COLORS.gray60,
                mt: 1,
              }}
            >
              Note: This action will also remove all associated meetings for the
              contact.
            </Typography>

            <Typography
              sx={{
                fontSize: 15,
                color: COLORS.gray60,
                mt: 1,
              }}
            >
              Attention: Ensure you have saved any necessary data before
              proceeding.
            </Typography>

            <Box
              sx={{
                mb: 1,
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 15,
                  color: COLORS.gray70,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeleteConfirmContactModal(false);
                }}
              >
                Cancel
              </Typography>
              <CustomButton
                Label="Delete"
                buttonColor="#e63946"
                style={{
                  width: 100,
                  height: 30,
                  borderRadius: 5,
                  marginRight: "0.5%",
                }}
                labelStyle={{
                  color: "#fff",
                  fontSize: 15,
                }}
                onClick={() => {
                  deleteBulkContacts();
                }}
              />
            </Box>
          </Box>
        </Modal>
      </Box>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
  role: state.contactData.role,
  contact_data: state.contactData.contactDataList,
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, { EmployeeDetailsGetAction })(
  Dashboard
);
