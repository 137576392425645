import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import Icons from "../../constants/Icons";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Toolbar,
  CssBaseline,
  AppBar,
  IconButton,
  Badge,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Modal,
  Menu,
  MenuItem,
  Divider,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { connect, useDispatch } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TrueTalentLogo from "../../assets/images/true-talent-logo.png";
import AXIOS from "../../utils/AXIOS";
import axios from "../Axios/axios";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BadgeIcon from "@mui/icons-material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "./Navbar.css";
import { priorityStatus } from "../../utils/Constants";
import images from "../../assets/images";

const Navbar = (props, { headText }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [adminAccess, setAdminAccess] = useState(null);
  const [viewAccess, setViewAccess] = useState(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [mobileView, setMobileView] = useState(window.innerWidth < 600);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    setMobileView(windowSize.width < 600);
  }, [windowSize]);
  useEffect(() => {
    if (props.employee_data) {
      if (
        props.employee_data.filter((val) => val.email_id == props.auth_email_id)
          .length > 0
      ) {
        let role = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;

        let tempRole = [];
        tempRole.push(role);

        setUserRoles(tempRole);

        if (role == "admin") {
          setAdminAccess(true);
          setViewAccess(true);
        }
        if (role == "view") {
          setViewAccess(false);
        }
      }
    }
  }, [props.employee_data]);

  const RoutesArray = [
    {
      label: "Home",
      icon: <HomeIcon />,
      path: "/dashboard",
      ACTIVE_KEY: "Dashboard",
      access: true,
    },
    {
      label: "Activity Status",
      icon: <AssessmentIcon />,
      path: "/activity-status",
      ACTIVE_KEY: "ACTIVITY_STATUS",
      access:
        userRoles.includes("admin") ||
        userRoles.includes("view") ||
        userRoles.includes("editDelete"),
      minWidth: 170,
    },
    {
      label: "Contact",
      icon: <PermContactCalendarIcon />,
      path: "/contactCapture",
      ACTIVE_KEY: "CONTACT_CAPTURE",
      access:
        userRoles.includes("admin") ||
        userRoles.includes("editDelete") ||
        userRoles.includes("view"),
    },
    {
      label: "Search",
      icon: <PersonSearchIcon />,
      path: "/searchPage",
      ACTIVE_KEY: "SEARCH_CONTACT",
      access: true,
    },
    {
      label: "Campaign",
      icon: <CampaignIcon />,
      path: "/sendEmail",
      ACTIVE_KEY: "CREATE_CAMPAIGN",
      access: userRoles.includes("admin") || userRoles.includes("editDelete"),
    },
    {
      label: "Admin",
      icon: <AdminPanelSettingsIcon />,
      path: "/admin",
      ACTIVE_KEY: "ADMIN",
      access: adminAccess,
    },
    {
      label: "Hierarchy",
      icon: <BadgeIcon />,
      path: "/employee-hierarchy",
      ACTIVE_KEY: "EMPLOYEE_HIERARCHY",
      access: adminAccess,
    },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    border: "none",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const SessionLogout = async () => {
    const token = window.localStorage.getItem("CMS_STOKEN");

    await axios
      .post(AXIOS.defaultPort + AXIOS.userLogout, {
        SESSION_TOKEN: token,
      })
      .then((response) => {})
      .catch((err) => {});
    window.localStorage.removeItem("CMS_STOKEN");
    window.localStorage.removeItem("notificationCount");
    dispatch({
      type: "AUTH_DATA",
      auth_name: "",
      auth_email_id: "",
      user_token: "",
      role: [],
    });
    dispatch({
      type: "ROLE_DATA",
      components_list: [],
      screen_list: [],
      flow_type: "",
    });
    navigate("/");
  };

  const handleDrawerToggle = () => {
    props.toggleDrawer(!props.showDrawer);
  };

  const [NotificationList, setNotificationList] = useState([]);
  const [NotificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const NotificationOpen = Boolean(NotificationAnchorEl);

  const handleNotificationModelOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (props.auth_id) {
      getNotificationData();
    }
  }, [props.auth_id]);

  const getNotificationData = () => {
    axios
      .post(AXIOS.defaultPort + AXIOS.notificationGet, {
        auth_id: props.auth_id,
      })
      .then((response) => {
        localStorage.setItem(
          "notificationCount",
          JSON.stringify(response.filter((item) => !item.READ).length)
        );
        let seenNotificationData = [];
        response.map((val) => {
          if (val.SEEN == false) {
            toast(val.DESCRIPTION, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              // onClick: () => {
              //   handleIconClick(val);
              //   changeNotificationSeen(val.NOTIFICATION_ID);
              // },
              progressClassName: "custom-progress-bar",
              className: "custom-toast",
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            seenNotificationData.push({
              NOTIFICATION_ID: val._id,
              SEEN: true,
            });
          }
        });
        handleNotificationSeen(seenNotificationData);

        setNotificationList(response);
      })
      .catch((err) => {});
  };

  const handleNotificationClick = async (NotificationData) => {
    await fetch(AXIOS.defaultPort + AXIOS.getContactById, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_id: props.auth_id,
        ID: NotificationData.CONTACT_ID,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        navigate("/contactDetails", {
          state: { searchContact: data },
        });
        console.log("ajksndkjasndjkasdasd", data);
      })
      .catch((err) => {
        console.log("ajksndkjasndjkasdasd", err);
      });
  };
  const handleNotificationSeen = async (NotificationList) => {
    await fetch(AXIOS.defaultPort + AXIOS.notificationSeenRead, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_id: props.auth_id,
        NOTIFICATIONS: NotificationList,
      }),
    })
      .then((response) => {
        console.log("Notification Seen");
      })
      .catch((err) => {});
  };
  const handleNotificationRead = async (
    NotificationList,
    showMarkAllReadNotification
  ) => {
    await fetch(AXIOS.defaultPort + AXIOS.notificationSeenRead, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_id: props.auth_id,
        NOTIFICATIONS: NotificationList,
      }),
    })
      .then((response) => {
        getNotificationData();
        if (showMarkAllReadNotification) {
          toast("All notifications marked as Read", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            // onClick: () => {
            //   handleIconClick(val);
            //   changeNotificationSeen(val.NOTIFICATION_ID);
            // },
            progressClassName: "custom-progress-bar",
            className: "custom-toast",
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }

        console.log("Notification Read");
      })
      .catch((err) => {});
  };

  const notificationCount = localStorage.getItem("notificationCount")
    ? JSON.parse(localStorage.getItem("notificationCount"))
    : 0;

  return (
    <React.Fragment>
      <CssBaseline />
      <ToastContainer stacked newestOnTop limit={5} />
      {/* <HideOnScroll> */}
      <AppBar
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              minWidth: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // cursor: "pointer",
                  marginRight: 10,
                }}
                className="type-cursor"
                onClick={() => {
                  handleDrawerToggle();
                }}
              >
                <MenuIcon />
              </div>
              <div>
                <img
                  src={TrueTalentLogo}
                  alt="betacode logo"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  className="img-responsive logo-img type-cursor"
                />
              </div>
            </div>
            <div>
              <Tooltip
                title={
                  notificationCount > 0
                    ? "You have unread notifications"
                    : "Notifications"
                }
                placement="bottom"
              >
                <IconButton
                  color="inherit"
                  onClick={handleNotificationModelOpen}
                >
                  <Badge badgeContent={notificationCount} color="primary">
                    <NotificationsIcon
                      sx={{
                        width: 30,
                        height: 30,
                        color: "#1770e0",
                      }}
                    />
                  </Badge>
                </IconButton>
              </Tooltip>

              <IconButton
                aria-label="show notification"
                size="large"
                color="default"
                sx={{ mr: -1 }}
                className="nav-bar-item"
                onClick={handleOpenUserMenu}
              >
                <AccountCircleIcon
                  sx={{
                    width: 35,
                    height: 35,
                    color: "#1770e0",
                  }}
                />
              </IconButton>
            </div>
          </Box>
          <Menu
            sx={{ mt: "45px", textAlign: "center" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMountedF
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                fontWeight: "bold",
                width: 250,
                height: 30,
              }}
            >
              Welcome {props?.auth_name} !
            </Box>

            <Divider />

            <div
              onClick={() => {
                SessionLogout();
              }}
            >
              <MenuItem
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Logout
              </MenuItem>
            </div>
          </Menu>
          <Menu
            anchorEl={NotificationAnchorEl}
            open={NotificationOpen}
            onClose={handleNotificationClose}
            PaperProps={{
              sx: {
                minWidth: 350,
                maxWidth: 320,
                borderRadius: 2,
                boxShadow: 3,
                marginRight: 10,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: 16, pl: 2 }}
              >
                Action Center
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 12,
                  pr: 2,
                  color: "#1770e0",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleNotificationRead(
                    NotificationList.map((val) => {
                      return {
                        NOTIFICATION_ID: val._id,
                        READ: true,
                      };
                    }),

                    true
                  );
                }}
              >
                Mark All Read
              </Typography>
            </Box>
            <Divider />

            {/* Tabs for Unread and All */}
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{ minHeight: 40 }}
            >
              <Tab
                label="Unread"
                sx={{ fontSize: 14, textTransform: "none" }}
              />
              <Tab label="All" sx={{ fontSize: 14, textTransform: "none" }} />
            </Tabs>

            <Divider />

            <Box
              sx={{
                maxHeight: 350,
                minHeight: 350,
                overflowY: "auto",
              }}
            >
              {NotificationList.filter((item) =>
                selectedTab === 0 ? !item.READ : true
              ).length == 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 350,
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={images.empty_box_svg}
                        style={{
                          width: 200,
                          height: 200,
                          margin: "auto",
                          objectFit: "contain",
                        }}
                        alt="Notification Empty"
                      />
                    </div>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginBottom: 1,
                      }}
                    >
                      All caught up!
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", color: "gray" }}
                    >
                      {/* Take a breather, connect with new prospects, and keep the
                      momentum going. */}
                      Take a breather and keep the momentum going, connect new
                      prospects!
                    </Typography>
                  </div>
                </div>
              )}
              {NotificationList.filter((item) =>
                selectedTab === 0 ? !item.READ : true
              ).map((NotificationItem) => (
                <MenuItem
                  key={NotificationItem._id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  onClick={() => {
                    handleNotificationClick(NotificationItem);
                    handleNotificationRead(
                      [
                        {
                          NOTIFICATION_ID: NotificationItem._id,
                          READ: true,
                        },
                      ],
                      false
                    );
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      borderRadius: 1,
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {/* Title with Dot */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {/* Tooltip with dot */}
                      <Tooltip
                        title={
                          "Priority " +
                          priorityStatus.find(
                            (item) => item.value == NotificationItem.PRIORITY
                          )?.label
                        }
                      >
                        <span
                          style={{
                            display: "inline-block",
                            width: 7,
                            height: 7,
                            backgroundColor: priorityStatus.find(
                              (item) => item.value == NotificationItem.PRIORITY
                            )?.color,
                            borderRadius: "50%",
                            marginLeft: -12,
                          }}
                        />
                      </Tooltip>

                      {/* Title */}
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                          marginLeft: -0.5,
                        }}
                      >
                        {NotificationItem.TITLE}
                      </Typography>
                    </Box>

                    {/* Description */}
                    <Typography
                      variant="body2"
                      sx={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {NotificationItem.DESCRIPTION}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Box>
          </Menu>
          {/* <Box
            sx={{
              display: "flex",
              // flexGrow: 1,
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          > */}
          {/* <Box
              sx={{
                // backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // mr: 1,
                // padding: 1,
              }}
            >
              <div
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  height: 100,
                  cursor:"pointer"
                }}
                onClick={() => {
                  handleDrawerToggle();
                }}
              >
                <MenuIcon />
              </div>
              <img
                src={TrueTalentLogo}
                alt="betacode logo"
                onClick={() => {
                  navigate("/dashboard");
                }}
                className="img-responsive logo-img"
              />
            </Box> */}
          {/* {mobileView == false && (
              <>
                {RoutesArray.map((val) => {
                  return (
                    <>
                      {val.access == true && (
                        <ListItem
                          disablePadding
                          sx={{
                            borderRadius: 4,
                            marginRight: 1,
                            overflow: "hidden",
                            minWidth: val?.minWidth,
                            color:
                              val.ACTIVE_KEY == props.headText
                                ? "#fff"
                                : "black",
                            backgroundColor:
                              val.ACTIVE_KEY == props.headText
                                ? "#1770E0"
                                : "#dedede",
                          }}
                          onClick={() => navigate(val.path)}
                        >
                          <ListItemButton
                            sx={{
                              // width: 150,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {val.icon}
                            <Typography
                              sx={{
                                padding: 0.5,
                              }}
                            >
                              {val.label}
                            </Typography>

                          </ListItemButton>
                        </ListItem>
                      )}
                    </>
                  );
                })}
              </>
            )} */}
          {/* </Box> */}

          {/* <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {mobileView == false && (
              <PowerSettingsNewIcon
                style={{
                  color: "white",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "gray",
                }}
                onClick={() => {
                  SessionLogout();
                }}
              />
            )}
            {mobileView == true && (
              <Box
                sx={{
                  cursor: "pointer",
                  color: "gray",
                }}
                onClick={handleOpen}
              >
                <MenuIcon />
              </Box>
            )}
          </Box> */}
          {/* <Modal
            open={open}
            disableAutoFocus
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                ...style,
                backgroundColor: "#fff",
                // minHeight: "80vh",
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                pb: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  p: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <img
                    src={TrueTalentLogo}
                    alt="betacode logo"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className="img-responsive logo-img"
                  />
                </div>
                {RoutesArray.map((val) => {

                  return (
                    val.access == true && (
                      <ListItem
                        className="nav-bar-item"
                        disablePadding
                        sx={{
                          borderRadius: 4,
                          overflow: "hidden",
                          backgroundColor:
                            val.ACTIVE_KEY == props.headText
                              ? "#1770E0"
                              : "#dedede",
                          color:
                            val.ACTIVE_KEY == props.headText ? "#fff" : null,
                          mb: 2,
                        }}
                        onClick={() => navigate(val.path)}
                      >
                        <ListItemButton>
                          {val.icon}
                          <ListItemText
                            primary={val.label}
                            sx={{ textAlign: "center" }}
                            className="text-label"
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  );
                })}
                <ListItem
                  className="nav-bar-item"
                  disablePadding
                  sx={{
                    borderRadius: 4,
                    overflow: "hidden",
                    backgroundColor: "#dedede",
                    mb: 2,
                  }}
                  onClick={() => {
                    SessionLogout();
                  }}
                >
                  <ListItemButton>
                    <PowerSettingsNewIcon />
                    <ListItemText
                      primary={"Logout"}
                      sx={{ textAlign: "center" }}
                      className="text-label"
                    />
                  </ListItemButton>
                </ListItem>
              </Box> */}

          {/* <ChildModal /> */}
          {/* </Box>
          </Modal> */}
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}

      {/* <Container>{children}</Container> */}
    </React.Fragment>
  );
};

// export default ;

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
  employee_data: state.contactData.employeeList,
});

export default connect(mapStateToProps, {})(Navbar);
