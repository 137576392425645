import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";

export const MeetingDataGetAction = () => (dispatch) => {
  const getSharepointData = (token) => {
    axios
      .post(`${AxiosDefaultPort}apis/sharepoint/meetingDataGet`)
      .then((response) => {
        dispatch({
          type: "MEETING_DATA_GET",
          payload: response.data.value,
        });
      });
  };
  getAccessToken(getSharepointData);
};
