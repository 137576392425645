import axios from "axios";
import clientData from "./clientData";
import { AxiosDefaultPort } from "./Axios";

const getAccessToken = (func) => {
  // let authData = new FormData();
  // authData.append("grant_type", clientData.grant_type);
  // authData.append("client_id", clientData.client_id);
  // authData.append("client_secret", clientData.client_secret);
  // authData.append("resource", clientData.resource);
  // let token = "";
  // axios
  //   // .post(
  //   //   ` https://accounts.accesscontrol.windows.net/${clientData.resourceId}/tokens/OAuth/2/`,
  //   .get(`${AxiosDefaultPort}apis/sharepoint/getAccessToken`)
  //   .then((response) => {
  //     token = response.data.access_token;
  func("token");
  // })
  // .catch((error) => {
  //   func("ECONNREF");
  // });
};
export default getAccessToken;
