import React, { useEffect, useState } from "react";

import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  useMediaQuery,
  useTheme,
  Box,
  Divider,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AXIOS from "../../utils/AXIOS";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BadgeIcon from "@mui/icons-material/Badge";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import BetaCodeLogo from "../../assets/images/beta-code-logo.png";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrueTalentLogo from "../../assets/images/true-talent-logo.png";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CampaignSvg from "../../assets/images/campaign-generation.svg";
import TryIcon from "@mui/icons-material/Try";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AssignmentIcon from "@mui/icons-material/Assignment";
import GroupIcon from "@mui/icons-material/Group";

import "./Navbar.css";
function AppDrawer(props) {
  const handleDrawerToggle = () => {
    props.toggleDrawer(!props.showDrawer);
  };

  const navigate = useNavigate();

  // const [userRoles, setUserRoles] = useState([]);
  // const [adminAccess, setAdminAccess] = useState(null);
  // const [viewAccess, setViewAccess] = useState(null);

  // useEffect(() => {
  // if (props.employee_data) {
  //   if (
  //     props.employee_data.filter((val) => val.email_id == props.auth_email_id)
  //       .length > 0
  //   ) {
  //     let role = props.employee_data.find(
  //       (val) => val.email_id == props.auth_email_id
  //     ).role;
  //     let tempRole = [];
  //     tempRole.push(role);
  //     setUserRoles(tempRole);
  //     if (role == "admin") {
  //       setAdminAccess(true);
  //       setViewAccess(true);
  //     }
  //     if (role == "view") {
  //       setViewAccess(false);
  //     }
  //   }
  // }
  // }, [props.employee_data]);

  const [RoutesArray, setRoutesArray] = useState([]);

  useEffect(() => {
    if (props.screen_list) {
      let tempRoutesArr = [
        {
          label: "Home",
          icon: <HomeIcon />,
          path: "/dashboard",
          ACTIVE_KEY: "Dashboard",
          access: props.screen_list.includes("dashboard"),
          SUB_ROUTES: [],
        },
        {
          label: "Activity Status",
          icon: <AssessmentIcon />,
          path: "/activity-status",
          ACTIVE_KEY: "ACTIVITY_STATUS",
          access: props.screen_list.includes("activity-status"),

          minWidth: 170,
          SUB_ROUTES: [],
        },
        {
          label: "Create Contact",
          icon: <PermContactCalendarIcon />,
          path: "/contactCapture",
          ACTIVE_KEY: "CONTACT_CAPTURE",
          access: props.screen_list.includes("contactCapture"),
          SUB_ROUTES: [],
        },
        {
          label: "Search Contact",
          icon: <PersonSearchIcon />,
          path: "/searchPage",
          ACTIVE_KEY: "SEARCH_CONTACT",
          access: props.screen_list.includes("searchPage"),

          SUB_ROUTES: [],
        },
        {
          label: "Quick Mailer",
          icon: <ContactMailIcon />,
          path: "/sendEmail",
          ACTIVE_KEY: "CREATE_CAMPAIGN",
          access: props.screen_list.includes("sendEmail"),

          SUB_ROUTES: [],
        },
        {
          label: "Employee Onboarding",
          icon: <ContactMailIcon />,
          path: "/employee-onboarding",
          ACTIVE_KEY: "CREATE_CAMPAIGN",
          access: props.screen_list.includes("sendEmail"),

          SUB_ROUTES: [],
        },
        // {
        //   label: "Admin",
        //   icon: <AdminPanelSettingsIcon />,
        //   path: "/admin",
        //   ACTIVE_KEY: "ADMIN",
        //   access: props.role.includes("admin"),
        //   SUB??

        {
          label: "Roles",
          icon: <AdminPanelSettingsIcon />,
          path: "/roles",
          ACTIVE_KEY: "EMPLOYEE_HIERARCHY",
          access:
            props.screen_list.includes("register-component") ||
            props.screen_list.includes("manage-roles") ||
            props.screen_list.includes("admin"),

          SUB_ROUTES: [
            {
              sub_label: "Screen & Components",
              sub_icon: <AddModeratorIcon />,
              sub_path: "/register-component",
              access: props.screen_list.includes("register-component"),
            },
            {
              sub_label: "Configure Roles",
              sub_icon: <AssignmentIcon />,
              sub_path: "/manage-roles",
              access: props.screen_list.includes("manage-roles"),
            },
            {
              sub_label: "Employees",
              sub_icon: <GroupIcon />,

              sub_path: "/admin",
              access: props.screen_list.includes("admin"),
            },
          ],
        },
        {
          label: "Hierarchy",
          icon: <BadgeIcon />,
          path: "/employee-hierarchy",
          ACTIVE_KEY: "EMPLOYEE_HIERARCHY",
          access: props.screen_list.includes("employee-hierarchy"),
          SUB_ROUTES: [],
        },
        {
          label: "Campaign",
          icon: <CampaignIcon />,
          path: "/campaign",
          ACTIVE_KEY: "EMPLOYEE_HIERARCHY",
          access:
            props.screen_list.includes("add-campaign") ||
            props.screen_list.includes("view-campaign") ||
            props.screen_list.includes("email-templates"),
          SUB_ROUTES: [
            {
              sub_label: "Add Campaign",
              sub_icon: <NoteAddIcon />,
              sub_path: "/add-campaign",
              access: true,
            },
            {
              sub_label: "View Campaign",
              sub_icon: <UpcomingIcon />,
              sub_path: "/view-campaign",
              access: true,
            },
            {
              sub_label: "Email Template",
              sub_icon: <TryIcon />,
              sub_path: "/email-templates",
              access: true,
            },
          ],
        },
        {
          label: "Marketing Dashboard",
          icon: <BadgeIcon />,
          path: "/marketing-dashboard",
          ACTIVE_KEY: "MARKETING_DASHBOARD",
          // access: props.screen_list.includes("marketing-dashboard"),
          access: true,
          SUB_ROUTES: [],
        },
      ];

      setRoutesArray(tempRoutesArr);
    }
  }, [props.components_list, props.screen_list, props.auth_name]);
  return (
    <Box className="parent">
      <Drawer
        open={props.showDrawer}
        // variant={`${breakPointlg ? "temporary" : "persistent"}`}
        variant={`temporary`}
        // variant="persistent"
        ModalProps={{
          keepMounted: false,
        }}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            zIndex: (theme) => theme.zIndex.drawer - 250,
            boxSizing: "border-box",
            width: 250,
            backgroundColor: "#fff",
          },
        }}
      >
        <div className="logo-container">
          <img src={TrueTalentLogo} className="img-responsive logo-img" />
        </div>
        <Divider />
        <div className="main-container-scroll">
          <List sx={{ width: 1, p: 1 }}>
            {RoutesArray.map((val) => {
              return (
                <>
                  {val.access == true && val.SUB_ROUTES.length <= 0 && (
                    <ListItem
                      disablePadding
                      sx={{
                        borderRadius: 4,
                        marginRight: 1,
                        overflow: "hidden",
                        minWidth: val?.minWidth,
                        color:
                          val.path == window.location.pathname
                            ? "#fff"
                            : "black",
                        backgroundColor:
                          val.path == window.location.pathname
                            ? "#1770E0"
                            : "#fff",
                      }}
                      onClick={() => {
                        navigate(val.path);
                        handleDrawerToggle();
                      }}
                    >
                      <ListItemButton
                        sx={{
                          // width: 150,
                          display: "flex",
                          // justifyContent: "center",
                        }}
                      >
                        {val.icon}
                        <Typography
                          sx={{
                            padding: 0.5,
                          }}
                        >
                          {val.label}
                        </Typography>

                        {/* <ListItemText
                              primary={val.label}
                              sx={{ textAlign: "center" }}
                              className="text-label"
                            /> */}
                      </ListItemButton>
                    </ListItem>
                  )}
                  {val.access == true && val?.SUB_ROUTES.length > 0 && (
                    <>
                      <Accordion
                        defaultExpanded={
                          val.SUB_ROUTES.find(
                            (val) => val.sub_path == window.location.pathname
                          ) != null && true
                        }
                        sx={{
                          boxShadow: "none",
                          border: "none",
                          margin: 0,
                          padding: 0,
                          "&:before": { display: "none" }, // Removes the default divider line
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{
                            borderRadius: 4,
                            marginRight: 1,
                            overflow: "hidden",
                            minWidth: val?.minWidth,
                            color:
                              val.path == window.location.pathname
                                ? "#fff"
                                : "black",
                            backgroundColor:
                              val.path == window.location.pathname
                                ? "#1770E0"
                                : "#fff",
                            "&:hover": {
                              backgroundColor: "#f5f5f5", // Hover background color
                            },
                          }}
                        >
                          {val.icon}
                          <Typography
                            sx={{
                              padding: 0.5,
                            }}
                          >
                            {val.label}
                          </Typography>
                        </AccordionSummary>
                        <div className="drawer-sub-container">
                          {val.SUB_ROUTES.map((innerVal) => {
                            return (
                              innerVal.access && (
                                <AccordionDetails
                                  sx={{
                                    boxShadow: "none",
                                    border: "none",
                                    margin: 0,
                                    padding: 0,
                                    "&:before": { display: "none" }, // Removes the default divider line
                                  }}
                                >
                                  <ListItem
                                    disablePadding
                                    sx={{
                                      borderRadius: 4,
                                      marginRight: 1,
                                      overflow: "hidden",
                                      minWidth: val?.minWidth,
                                      color:
                                        innerVal.sub_path ==
                                        window.location.pathname
                                          ? "#fff"
                                          : "black",
                                      backgroundColor:
                                        innerVal.sub_path ==
                                        window.location.pathname
                                          ? "#1770E0"
                                          : "#fff",
                                    }}
                                    onClick={() => {
                                      navigate(innerVal.sub_path);
                                      handleDrawerToggle();
                                    }}
                                  >
                                    <ListItemButton
                                      sx={{
                                        // width: 150,
                                        display: "flex",
                                        // justifyContent: "center",
                                      }}
                                    >
                                      {innerVal.sub_icon}
                                      <Typography
                                        sx={{
                                          // padding: 0.5,
                                          ml: 1,
                                        }}
                                      >
                                        {innerVal.sub_label}
                                      </Typography>

                                      {/* <ListItemText
                              primary={val.label}
                              sx={{ textAlign: "center" }}
                              className="text-label"
                            /> */}
                                    </ListItemButton>
                                  </ListItem>
                                </AccordionDetails>

                                // <div
                                //   className="drawer-sub-item active"
                                //   onClick={() => {
                                //     navigate(innerVal.sub_path);
                                //     props.toggleDrawer(false);
                                //   }}
                                //   style={{
                                //     color:
                                //       props.ActiveKey == innerVal.sub_path
                                //         ? "#22AAD2"
                                //         : "#000",
                                //     fontWeight:
                                //       props.ActiveKey == innerVal.sub_path
                                //         ? "bold"
                                //         : "",
                                //     cursor: "pointer",
                                //   }}
                                // >
                                //   {innerVal.sub_icon}

                                //   {innerVal.sub_label}
                                // </div>
                              )
                            );
                          })}
                        </div>
                      </Accordion>
                    </>
                  )}
                </>
              );
            })}
          </List>
        </div>
      </Drawer>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  employee_data: state.contactData.employeeList,
  role: state.contactData.role,
  components_list: state.contactData.components_list,
  screen_list: state.contactData.screen_list,
});

export default connect(mapStateToProps, {})(AppDrawer);
