import React, { useEffect, useRef, useState } from "react";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import MainScreen from "../../components/Navbar/MainScreen";
import loadingAnime from "../../assets/lottieFiles/loader.json";
import Lottie from "react-lottie-player";

// import "./EmailTemplateEdit.css"
import "./EmailTemplateEdit.css";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import AXIOS from "../../utils/AXIOS";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { COLORS } from "../../constants/theme";
import { RxCross1 } from "react-icons/rx";
import CustomButton from "../../components/CustomButton/CustomButton";
import { encodeData } from "../../utils/Constants";

function EmailTemplateEdit(props) {
  const emailEditorRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [templateID, setTemplateID] = useState();
  const [deleteEmailContentModal, setDeleteEmailContentModal] = useState(false);

  useEffect(() => {
    setTemplateID(location?.state?._id);
  }, []);
  const [editorLoading, setEditorLoading] = useState(true);

  const [transactionFlags, setTransactionFlags] = useState({
    EMAIL_TEMPLATE_SAVE_LOADING: false,
    TEMPLATE_SAVE_LOADING: false,
  });

  const [emailTemplateData, setEmailTemplateData] = useState({
    HTML: "",
    DESIGN: location.state.DESIGN,
  });
  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (unlayer) {
      unlayer.exportHtml((data) => {
        const { design, html } = data;
        setEmailTemplateData({
          HTML: html,
          DESIGN: design,
        });
      });
    }
  };

  const loadHtml = async (htmlContent) => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer.loadDesign(htmlContent);
  };

  const onReady = async (unlayer) => {
    if (location.state.DESIGN) {
      try {
        const response = await fetch(AXIOS.defaultPort + location.state.DESIGN);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const text = await response.text();

        loadHtml(JSON.parse(text));
      } catch (err) {}
    }
    setEditorLoading(false);
  };
  const updateEditorContent = async () => {
    loadHtml({ body: { rows: [] } }); // Provide a valid empty structure
  };
  const saveCampaignEmailContent = async () => {
    setTransactionFlags((prev) => ({
      ...prev,
      EMAIL_TEMPLATE_SAVE_LOADING: true,
    }));
    const unlayer = emailEditorRef.current?.editor;

    if (unlayer) {
      const exportHtmlAsync = () => {
        return new Promise((resolve, reject) => {
          unlayer.exportHtml((data) => {
            if (data) {
              const { design, html } = data;
              resolve({ design, html }); // Resolve the promise with design and html
            } else {
              reject(new Error("Failed to export HTML"));
            }
          });
        });
      };
      const exportedDesignHtml = await exportHtmlAsync();

      await fetch(AXIOS.defaultPort + AXIOS.createCampaignTemplate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CAMPAIGN_ID: location.state?.campaign_id,
          TEMPLATE_HTML: exportedDesignHtml.html,
          DESIGN: exportedDesignHtml.design,
          CREATED_BY: props.auth_id,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setTransactionFlags((prev) => ({
            ...prev,
            EMAIL_TEMPLATE_SAVE_LOADING: false,
          }));

          toast.success("Email Content Saved");
          navigate(
            "/campaign-details?CAMPAIGN_ID=" +
              encodeData(location.state?.campaign_id),
            {
              state: {
                campaign_id: location.state?.campaign_id,
                email_content_saved: true,
              },
            }
          );
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            EMAIL_TEMPLATE_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    } else {
      setTransactionFlags((prev) => ({
        ...prev,
        EMAIL_TEMPLATE_SAVE_LOADING: false,
      }));
    }
  };
  const saveCampaignTemplate = async () => {
    setTransactionFlags((prev) => ({
      ...prev,
      TEMPLATE_SAVE_LOADING: true,
    }));
    const unlayer = emailEditorRef.current?.editor;

    if (unlayer) {
      const exportHtmlAsync = () => {
        return new Promise((resolve, reject) => {
          unlayer.exportHtml((data) => {
            if (data) {
              const { design, html } = data;
              resolve({ design, html }); // Resolve the promise with design and html
            } else {
              reject(new Error("Failed to export HTML"));
            }
          });
        });
      };
      let exportedDesignHtml = await exportHtmlAsync();
      exportedDesignHtml.design = JSON.stringify(exportedDesignHtml.design);

      await fetch(AXIOS.defaultPort + AXIOS.createTemplate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          TEMPLATE_HTML: exportedDesignHtml.html,
          DESIGN: exportedDesignHtml.design,
          CREATED_BY: props.auth_id,
          _id: templateID,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();

          setTemplateID(data.templateId);
          setTransactionFlags((prev) => ({
            ...prev,
            TEMPLATE_SAVE_LOADING: false,
          }));

          toast.success("Template Saved");
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            TEMPLATE_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    } else {
      setTransactionFlags((prev) => ({
        ...prev,
        TEMPLATE_SAVE_LOADING: false,
      }));
    }
  };

  const deleteEmailContent = async () => {
    setTransactionFlags((prev) => ({
      ...prev,
      EMAIL_TEMPLATE_DELETE_LOADING: true,
    }));
    await fetch(AXIOS.defaultPort + AXIOS.deleteCampaignContent, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: location.state?.campaign_id,
        UPDATED_BY: props.auth_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        updateEditorContent();
        setTransactionFlags((prev) => ({
          ...prev,
          EMAIL_TEMPLATE_DELETE_LOADING: false,
        }));

        toast.success("Email Content Deleted");
      })
      .catch((err) => {
        updateEditorContent();
        setTransactionFlags((prev) => ({
          ...prev,
          EMAIL_TEMPLATE_DELETE_LOADING: false,
        }));
        toast.error("Something went wrong");
      });
  };
  return (
    <div>
      <AppBar
        position="static"
        sx={{
          padding: "1px",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures elements are spaced out
              alignItems: "center", // Centers items vertically
              width: "100%", // Ensures it takes full width of the Toolbar
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <IconContext.Provider value={{ size: 25, color: "#000" }}>
                  <FaArrowLeftLong />
                </IconContext.Provider>
              </Box>
              <Typography
                style={{
                  fontSize: 22,
                  fontWeight: 600,
                  color: "#000",
                  marginRight: 10,
                  textAlign: "start",
                }}
              >
                Edit Email Content
              </Typography>
            </Box>

            {editorLoading == false && (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Tooltip title="Delete Email Content">
                  <Box
                    sx={{
                      cursor: "pointer",
                      // mb: -1,

                      backgroundColor: "#FFEEEE",
                      padding: 1,
                      borderRadius: 20,
                      display: "flex",
                      alignItems: "center",
                      ml: 1,
                    }}
                    onClick={() => {
                      setDeleteEmailContentModal(true);
                      // setDeletedVal({
                      //   ...val,
                      //   SELECTED_CR_MANAGER: "",
                      //   SELECTED_CR_MANAGER_ERROR: false,
                      //   SELECTED_MANAGER: "",
                      //   SELECTED_MANAGER_ERROR: false,
                      // });
                    }}
                  >
                    {transactionFlags.EMAIL_TEMPLATE_DELETE_LOADING ? (
                      <CircularProgress
                        size="20px"
                        sx={{
                          color: "#e63946",
                        }}
                      />
                    ) : (
                      <DeleteForeverIcon
                        color="red"
                        sx={{ color: "#e63946" }}
                      />
                    )}
                  </Box>
                </Tooltip>
                <button
                  className="bordered-button-blue"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </button>

                {location?.state?.SAVE_DESIGN == false && (
                  <button
                    className="filled-button-blue"
                    onClick={() => {
                      saveCampaignEmailContent();
                    }}
                    style={{
                      minWidth: 150,
                    }}
                    disabled={transactionFlags.EMAIL_TEMPLATE_SAVE_LOADING}
                  >
                    {transactionFlags.EMAIL_TEMPLATE_SAVE_LOADING == true ? (
                      <Spinner />
                    ) : (
                      "Save and Exit"
                    )}
                  </button>
                )}

                {location?.state?.SAVE_DESIGN != false && (
                  <button
                    className="filled-button-blue"
                    onClick={() => {
                      saveCampaignTemplate();
                    }}
                    style={{
                      minWidth: 150,
                    }}
                    disabled={transactionFlags.TEMPLATE_SAVE_LOADING}
                  >
                    {transactionFlags.TEMPLATE_SAVE_LOADING == true ? (
                      <Spinner />
                    ) : (
                      "Save Template"
                    )}
                  </button>
                )}
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <div
        style={{
          height: 10,
        }}
      />
      <Toaster />

      <EmailEditor
        ref={emailEditorRef}
        onReady={onReady}
        style={{
          height: "88vh",
        }}
      />

      <Modal
        open={deleteEmailContentModal}
        onClose={() => {
          setDeleteEmailContentModal(false);
        }}
        disableAutoFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
      >
        <Box className="delete-modal-container">
          <Box
            className="delete-modal-box"
            sx={{
              cursor: "auto",
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: COLORS.gray80,
                fontWeight: "bold",
              }}
            >
              Confirmation
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
            >
              <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                <RxCross1
                  onClick={() => {
                    setDeleteEmailContentModal(false);
                  }}
                />
              </IconContext.Provider>
            </div>
          </Box>

          <Divider />

          <Typography
            sx={{
              fontSize: 15,
              color: COLORS.gray60,
              mt: 1,
            }}
          >
            Are you sure you want to delete the email content? This action
            cannot be undone.
          </Typography>

          <Box
            sx={{
              mb: 1,
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 15,
                color: COLORS.gray70,
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                setDeleteEmailContentModal(false);
              }}
            >
              Cancel
            </Typography>
            <CustomButton
              Label="Delete"
              buttonColor="#e63946"
              style={{
                width: 100,
                height: 30,
                borderRadius: 5,
                marginRight: "0.5%",
              }}
              labelStyle={{
                color: "#fff",
                fontSize: 15,
              }}
              onClick={() => {
                deleteEmailContent();
              }}
            />
            {/* <Box
                                className="delete-btn-box"
                                onClick={() => {
                                  deleteEmployee();
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 15,
                                    color: COLORS.white,
                                    
                                  }}
                                >
                                  Delete
                                </Typography>
                              </Box> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
});

export default connect(mapStateToProps, {})(EmailTemplateEdit);
