import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const InfiniteScrollTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadMoreData();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const loadMoreData = () => {
    setTimeout(() => {
      setData((prevData) => [
        ...prevData,
        ...Array.from({ length: 10 }, (_, i) => ({
          id: prevData.length + i + 1,
          name: `Item ${prevData.length + i + 1}`,
        })),
      ]);
      setPage((prev) => prev + 1);
    }, 1000);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 20
    ) {
      loadMoreData();
    }
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <motion.tr
            key={item.id}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <td>{item.id}</td>
            <td>{item.name}</td>
          </motion.tr>
        ))}
      </tbody>
    </table>
  );
};

export default InfiniteScrollTable;
