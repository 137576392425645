import axios from "axios";
import getAccessToken from "../../constants/getAccessToken";
import clientData from "../../constants/clientData";
import { AxiosDefaultPort } from "../../constants/Axios";
import { engagementStatus } from "../../utils/Constants";

export const ContactDataGetAction = (AuthID) => (dispatch) => {
  // const getSharepointData = (token) => {
  //   axios
  //     .post(`${AxiosDefaultPort}apis/sharepoint/contactDataGet`, {
  //       // token,
  //       AUTH_ID: AuthID,
  //     })
  //     .then((response) => {
  //       let temp = [...response.data.value];
  //       temp = temp.filter((val) => val.isRemoved == "No");

  //       temp.map((val) => {
  //         val.checked = false;
  //         val.ID = val.ID.toString();
  //         val.ENGAGEMENT_STATUS= engagementStatus.find(
  //           (item) => item.value == val.engagementStatus
  //         )?.label || "";

  //         // {
  //         //   engagementStatus.find((item) => item.value == val.engagementStatus)
  //         //     ?.label || "";
  //         // }
  //       });

  //       console.log("Akjasdasdsa", temp);
        

  //       dispatch({
  //         type: "CONTACT_DATA_GET",
  //         payload: temp,
  //       });
  //     });
  // };
  // getAccessToken(getSharepointData);
};
