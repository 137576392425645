import React, { useEffect, useRef, useState } from "react";
import MainScreen from "../../components/Navbar/MainScreen";
import ContactImport from "../../assets/icons/contact-import.svg";
import campaignImport from "../../assets/icons/campaign-import.svg";
import emailImport from "../../assets/images/Emails-bro.svg";
import deleteImage from "../../assets/icons/delete.png";
import leftArrow from "../../assets/icons/left-arrow.png";
import rightArrow from "../../assets/icons/right-arrow.png";
import "./CampaignDetail.css";
import { useLocation, useNavigate } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import EmailCampaignCreated from "../../assets/images/Email-campaign-created.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Drawer,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  styled,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CustomInput from "../../components/CustomInput/CustomInput";
import EmojiPicker from "emoji-picker-react";
import EmojiAdd from "../../assets/icons/emoji-round-plus-svgrepo-com.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import toast, { Toaster } from "react-hot-toast";
import AXIOS from "../../utils/AXIOS";
import ReactDataTable from "../../components/ReactDataTable/ReactDataTable";
import { connect } from "react-redux";
import Lottie from "react-lottie-player";
import confettiAnime from "../../assets/lottieFiles/confetti.json";
import { Tooltip as TooltipMaterial } from "@mui/material";
import sendEmailIcon from "../../assets/icons/send-email.svg";
import previewEmailIcon from "../../assets/icons/preview.svg";
import mailBoxIcon from "../../assets/icons/mail-box.svg";
import { RxCross1 } from "react-icons/rx";
import InfoIcon from "@mui/icons-material/Info";
import { TabPanel } from "@mui/lab";
import { saveAs } from "file-saver";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  Filler,
} from "chart.js";
import { Bar, Doughnut, Pie, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { LineElement } from "chart.js";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import CustomDropdown from "../../components/CustomDropDown/CustomDropDown";
import {
  campaignStatus,
  capitalizeFirstLetter,
  decodeData,
  deliveryStatusArr,
  encodeData,
  formatDateToDDMMYYYY,
  formatTime,
} from "../../utils/Constants";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Spinner from "../../components/Spinner/Spinner";
import { COLORS } from "../../constants/theme";
import JoditEditorCustom from "../../components/JoditEditorCustom/JoditEditorCustom";
import loadingAnime from "../../assets/lottieFiles/loader.json";
import noDataFoundAnime from "../../assets/lottieFiles/no-data-found.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels,
  ArcElement,
  BarElement
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#fff",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
function CampaignDetail(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const searchParams = new URLSearchParams(location.search);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  useEffect(() => {
    if (queryParams.get("CAMPAIGN_ID")) {
      setSelectedCampaignId(decodeData(queryParams.get("CAMPAIGN_ID")));
    }
  }, [queryParams]);

  const [customGreetings, setCustomGreetings] = useState({
    CUSTOM_GREETING_NAME: "",
    CUSTOM_GREETING_NAME_ERROR: false,
  });

  const [campaignGreetingData, setCampaignGreetingData] = useState({
    CAMPAIGN_GREETINGS_HTML: "",
    CAMPAIGN_LOADING: false,
  });

  const [existingContactsList, setExistingContactsList] = useState({
    EXISTING_CONTACT_DATA_LIST: [],
    SELECTED_EXISTING_CONTACT_DATA_LIST: [],
    isAllExistingContactSelected: false,
  });
  const [fromAnotherCampaignQuery, setFromAnotherCampaignQuery] = useState({
    SELECTED_CAMPAIGNS: [],
    SELECTED_CAMPAIGNS_RECIPIENTS: [],
    isAllExistingContactSelected: false,
  });

  const droppableMaster = [
    // { id: "1", content: "Draggable Item 1" },
    // { id: "2", content: "Draggable Item 2" },
    // { id: "3", content: "Draggable Item 3" },
    { content: "Hi", id: "Hi" },
    { content: "Hello", id: "Hello" },
    { content: "Hey", id: "Hey" },
    { content: "Dear", id: "Dear" },
    { content: "Greetings", id: "Greetings" },
    { content: "Warm greetings", id: "Warm greeting" },
    { content: "My dear ", id: "My dear" },
    { content: "Dearest", id: "Dearest" },
    { content: "Lovely", id: "Lovely" },
    { content: "Hi friend", id: "Hi friend" },
    { content: "Hello my friend", id: "Hello my friend" },
    { content: "Dear Sir/Madam", id: "Dear Sir/Madam" },
    { content: "Good day", id: "Good day" },
    { content: "Congratulations", id: "Congratulations" },
    { content: "Welcome", id: "Welcome" },
    { content: "Woohoo! Hi", id: "Woohoo ! Hi" },
    { content: "Namaste", id: "Namaste" },
    { content: "Hola", id: "Hola" },
    { content: "Good Morning", id: "Good Morning" },
    { content: "Good Afternoon", id: "Good Afternoon" },
    { content: "Good Evening", id: "Good Evening" },
    { content: "Hey there", id: "Hey there" },
    { content: "Hiya", id: "Hiya" },
    { content: "Howdy", id: "Howdy" },
    { content: "Yo", id: "Yo" },
    { content: "What’s up", id: "What’s up" },
    { content: "Hi team", id: "Hi team" },
    { content: "Hello folks", id: "Hello folks" },
    { content: "Hey everyone", id: "Hey everyone" },
    { content: "Mr.", id: "Mr." },
    { content: "Miss.", id: "Miss." },
    { content: "Name", id: "{{Name}}" },
    // { content: "Middle Name", id: "{{MiddleName}}" },
    // { content: "Last Name", id: "{{LastName}}" },
  ];

  const [DroppableItems, setDroppableItems] = useState([
    // { id: "1", content: "Draggable Item 1" },
    // { id: "2", content: "Draggable Item 2" },
    // { id: "3", content: "Draggable Item 3" },
    { content: "Hi", id: "Hi" },
    { content: "Hello", id: "Hello" },
    { content: "Hey", id: "Hey" },
    { content: "Dear", id: "Dear" },
    { content: "Greetings", id: "Greetings" },
    { content: "Warm greetings", id: "Warm greeting" },
    { content: "My dear ", id: "My dear" },
    { content: "Dearest", id: "Dearest" },
    { content: "Lovely", id: "Lovely" },
    { content: "Hi friend", id: "Hi friend" },
    { content: "Hello my friend", id: "Hello my friend" },
    { content: "Dear Sir/Madam", id: "Dear Sir/Madam" },
    { content: "Good day", id: "Good day" },
    { content: "Congratulations", id: "Congratulations" },
    { content: "Welcome", id: "Welcome" },
    { content: "Woohoo! Hi", id: "Woohoo ! Hi" },
    { content: "Namaste", id: "Namaste" },
    { content: "Hola", id: "Hola" },
    { content: "Good Morning", id: "Good Morning" },
    { content: "Good Afternoon", id: "Good Afternoon" },
    { content: "Good Evening", id: "Good Evening" },
    { content: "Hey there", id: "Hey there" },
    { content: "Hiya", id: "Hiya" },
    { content: "Howdy", id: "Howdy" },
    { content: "Yo", id: "Yo" },
    { content: "What’s up", id: "What’s up" },
    { content: "Hi team", id: "Hi team" },
    { content: "Hello folks", id: "Hello folks" },
    { content: "Hey everyone", id: "Hey everyone" },
    { content: "Mr.", id: "Mr." },
    { content: "Miss.", id: "Miss." },
    { content: "Name", id: "{{Name}}" },
    // { content: "Middle Name", id: "{{MiddleName}}" },
    // { content: "Last Name", id: "{{LastName}}" },
  ]);

  const [droppedItems, setDroppedItems] = useState([]);

  useEffect(() => {
    if (droppedItems.length > 0) {
      setCampaignGreetingData((prev) => ({
        ...prev,
        CAMPAIGN_GREETINGS_HTML: `<p>${droppedItems
          .map((val) => val.id)
          .join(" ")}</p>`,
      }));
    }
  }, [droppedItems]);

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return; // If dropped outside any droppable area, do nothing.

    if (
      source.droppableId === "draggable-list" &&
      destination.droppableId === "droppable-container"
    ) {
      // Remove item from draggable list
      const updatedItems = Array.from(DroppableItems);
      const [movedItem] = updatedItems.splice(source.index, 1);
      setDroppableItems(updatedItems);

      // Add item to droppable container
      const updatedDroppedItems = Array.from(droppedItems);
      updatedDroppedItems.splice(destination.index, 0, movedItem);
      setDroppedItems(updatedDroppedItems);
    } else if (
      source.droppableId === "droppable-container" &&
      destination.droppableId === "droppable-container"
    ) {
      // Rearrange within the droppable container
      const updatedDroppedItems = Array.from(droppedItems);
      const [movedItem] = updatedDroppedItems.splice(source.index, 1);
      updatedDroppedItems.splice(destination.index, 0, movedItem);
      setDroppedItems(updatedDroppedItems);
    }
  };

  const handleRemoveFromContainer = (item) => {
    // Remove the item from the droppable container
    const updatedDroppedItems = droppedItems.filter(
      (droppedItem) => droppedItem.id !== item.id
    );
    setDroppedItems(updatedDroppedItems);

    // Add it back to the draggable items list
    setDroppableItems([...DroppableItems, item]);
  };
  const [subCampaignOptions, setSubCampaignOptions] = useState([
    {
      label: "Target All Contacts",
      value: "1",
      TOTAL_COUNT: 0,
    },
    {
      label: "Create for All Delivered Emails",
      value: "2",
      TOTAL_COUNT: 0,
    },

    {
      label: "Create for opened Emails",
      value: "4",
      TOTAL_COUNT: 0,
    },
    {
      label: "Create for Not opened Email",
      value: "5",
      TOTAL_COUNT: 0,
    },
  ]);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useEffect(() => {
    // Sync tab state with query string if URL changes
    const tabFromUrl = queryParams.get("tab");
    if (tabFromUrl && parseInt(tabFromUrl, 10) !== ReportSelectTab) {
      setReportSelectTab(parseInt(tabFromUrl, 10));
    }
  }, [location.search]);

  const [emailContentData, setEmailContentData] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const [showCampaignDetailsPage, setShowCampaignDetailsPage] = useState({
    STATUS: null,
    STATUS_CODE: "",
  });

  const [transactionFlags, setTransactionFlags] = useState({
    ADD_RECIPIENT_LOADING: false,
    SEND_TEST_EMAIL_LOADING: false,
    SEND_EMAIL_LOADING: false,
    FROM_NAME_SAVE_LOADING: false,
    SUBJECT_SAVE_LOADING: false,
    PERSONALIZATION_SAVE_LOADING: false,
    ATTACHMENT_SAVE_LOADING: false,
    FROM_CAMPAIGN_SEARCH_LOADING: false,
    FROM_CAMPAIGN_SAVE_LOADING: false,
    EXISTING_CONTACT_SAVE_LOADING: false,
    EXISTING_CONTACT_LOADING: false,
    CAMPAIGN_LOADING: true,
    CAMPAIGN_FOUND: true,
  });

  const [sendTestEmailQuery, setSendTestEmailQuery] = useState({
    EMAILS: "",
    EMAIL_ERROR: false,
    EMAIL_SENT: false,
  });

  const getEmailContent = () => {
    fetch(AXIOS.defaultPort + AXIOS.getTemplateByCampaignId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: selectedCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        let tempCampaignSubjectData = {
          SUBJECT: "",
          PREVIEW_TEXT: "",
          SUBJECT_SET: true,
          FROM_NAME: "",
          FROM_NAME_DEFAULT: "",
          ATTACHMENT: [],
        };
        console.log("asdnasdkjasdsa", data, selectedCampaignId);

        if (data.length > 0) {
          if (data[0].TEMPLATE_HTML) {
            handlePreview(AXIOS.defaultPort + data[0].TEMPLATE_HTML);
          }

          setEmailContentData(data[0]);
          tempCampaignSubjectData = {
            SUBJECT: data[0]?.SUBJECT || "",
            PREVIEW_TEXT: data[0]?.PREVIEW_TEXT || "",
            SUBJECT_SET: true,
            FROM_NAME: data[0]?.FROM_NAME || "",
            FROM_NAME_DEFAULT: data[0]?.FROM_NAME || "",
            ATTACHMENT: data[0].ATTACHMENTS || [],
          };
          if (data[0]?.FROM_EMAIL) {
            tempCampaignSubjectData.FROM_EMAIL_DEFAULT = data[0]?.FROM_EMAIL;
            tempCampaignSubjectData.FROM_EMAIL = data[0]?.FROM_EMAIL;
          }
          if (data[0].ATTACHMENTS) {
            let tempAttachments = [];

            data[0].ATTACHMENTS.map((val) => {
              tempAttachments.push({
                name: val.FILE_NAME,
                size: val.FILE_SIZE,
                IS_DELETED: val.IS_DELETED,
              });
            });
            tempCampaignSubjectData.ATTACHMENT = tempAttachments;
          }
        }

        setCampaignSubjectData((prev) => ({
          ...prev,
          ...tempCampaignSubjectData,
        }));
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const [recipientFile, setRecipientFile] = useState("");
  const [AttachmentFiles, setAttachmentFiles] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setRecipientFile(file);
    }
  };

  const handleAttachmentUpload = (event) => {
    const newFiles = Array.from(event.target.files); // Convert FileList to an array

    setCampaignSubjectData((prevState) => {
      // Ensure prevFiles is always an array (handles potential undefined cases)
      const existingFiles = new Set(
        (prevState.ATTACHMENT || []).map((file) => file.name + file.size)
      );

      const filteredFiles = newFiles.filter(
        (file) => !existingFiles.has(file.name + file.size) // Check for duplicates
      );

      return {
        ...prevState, // Preserve other state properties
        ATTACHMENT: [...prevState.ATTACHMENT, ...filteredFiles], // Append only unique files
      };
    });
    event.target.value = "";
  };

  const handleDeleteFile = (index) => {
    setCampaignSubjectData((prevState) => {
      const updatedAttachments = prevState.ATTACHMENT.filter(
        (_, i) => i !== index
      );
      return {
        ...prevState, // Preserve other state properties
        ATTACHMENT: updatedAttachments, // Remove the file at the specified index
      };
    });
  };

  const createSubCampaign = () => {
    if (createCampaignData.CAMPAIGN_NAME == "") {
      setCreateCampaignData((prev) => ({
        ...prev,
        CAMPAIGN_NAME_ERROR: true,
      }));
    } else {
      fetch(AXIOS.defaultPort + AXIOS.createSubCampaign, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CAMPAIGN_NAME: createCampaignData.CAMPAIGN_NAME,
          CAMPAIGN_DESCRIPTION: createCampaignData.CAMPAIGN_DESCRIPTION,
          FROM_CAMPAIGN: selectedCampaignId,
          RECIPIENT_TYPE: createCampaignData?.SELECTED_RECIPIENT_TYPE,
          CREATED_BY: props.auth_id,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          setCreateCampaignData({
            CAMPAIGN_NAME: "",
            CAMPAIGN_DESCRIPTION: "",
            CAMPAIGN_NAME_ERROR: false,
            CAMPAIGN_DESCRIPTION_ERROR: false,
            CAMPAIGN_ID: data?.campaignId,
            CAMPAIGN_COMPLETED: true,
          });
          toast.success("Campaign Created");
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };

  const [SubjectDrawerState, setSubjectDrawerState] = useState(false);
  const [AttachmentDrawerState, setAttachmentDrawerState] = useState(false);
  const [PersonalizationDrawerState, setPersonalizationDrawerState] =
    useState(false);
  const [FromDrawerState, setFromDrawerState] = useState(false);
  const [CampaignSendDrawerState, setCampaignSendDrawerState] = useState(false);
  const [TestEmailDrawerState, setTestEmailDrawerState] = useState(false);
  const [recipientsDrawerState, setRecipientsDrawerState] = useState(false);

  const [ReportSelectTab, setReportSelectTab] = useState(0);

  const [campaignSubjectData, setCampaignSubjectData] = useState({
    SUBJECT: "",
    PREVIEW_TEXT: "",
    SUBJECT_SET: null,
    FROM_EMAIL: "DoNotReply@betacodeinfotech.com",
    FROM_EMAIL_DEFAULT: "DoNotReply@betacodeinfotech.com",
    FROM_NAME: "",
    FROM_NAME_DEFAULT: "",
    ATTACHMENT: [],
  });

  const [createCampaignData, setCreateCampaignData] = useState({
    CAMPAIGN_NAME: "",
    CAMPAIGN_DESCRIPTION: "",
    CAMPAIGN_NAME_ERROR: false,
    CAMPAIGN_DESCRIPTION_ERROR: false,
    CAMPAIGN_COMPLETED: false,
    SELECTED_RECIPIENT_TYPE: "",
  });

  const [campaignCreateModal, setCampaignCreateModal] = useState(false);

  const [campaignName, setCampaignName] = useState({
    NAME: "",
    DEFAULT_NAME: "",
    EDIT_FLAG: false,
    PARENT_CAMPAIGN: "",
    PARENT_CAMPAIGN_NAME: "",
  });

  const [campaignData, setCampaignData] = useState();
  const [campaignReportData, setCampaignReportData] = useState({
    DELIVERED_COUNT: 0,
    DELIVERED_PERCENT: null,
    OPENED_COUNT: 0,
    OPENED_PERCENT: null,
    RECIPIENT_COUNT: 0,
  });

  const [recipientStep, setRecipientStep] = useState("");
  const [recipientImportOptions, setRecipientImportOptions] = useState([
    {
      TITLE: "Upload a file",
      DeSCRIPTION: (
        <p>
          Use <strong>CSV</strong> or tab-delimited <strong>TXT</strong> files.
          Make sure files are well-formatted and the data is clean.
        </p>
      ),
      IMAGE: ContactImport,
      MARGIN: 60,
      onClick: () => {
        setRecipientStep("UPLOAD_FILE");
      },
    },
    {
      TITLE: "From Another Campaign ",
      DeSCRIPTION: (
        <p>
          Quickly import your contacts to grow your audience and start sending
          emails in no time.
        </p>
      ),
      IMAGE: campaignImport,
      // MARGIN: 30,
      onClick: () => {
        setRecipientStep("ANOTHER_CAMPAIGN");
      },
    },
    {
      TITLE: "From Existing Contacts ",
      DeSCRIPTION: (
        <p>
          Quickly import your contacts to grow your audience and start sending
          emails in no time.
        </p>
      ),
      IMAGE: emailImport,
      // MARGIN: 0,
      onClick: () => {
        setRecipientStep("EXISTING_CONTACTS");
      },
    },
  ]);

  const handleAnotherCampaignCheckboxChange = (clickedData) => {
    let tempAnotherCampaignList = JSON.parse(
      JSON.stringify(fromAnotherCampaignQuery)
    );

    let index = tempAnotherCampaignList.SELECTED_CAMPAIGNS_RECIPIENTS.findIndex(
      (item) => item._id == clickedData._id
    );

    if (index != -1) {
      tempAnotherCampaignList.SELECTED_CAMPAIGNS_RECIPIENTS[index].SELECTED =
        !tempAnotherCampaignList.SELECTED_CAMPAIGNS_RECIPIENTS[index].SELECTED;
    }

    let NotSelected =
      tempAnotherCampaignList.SELECTED_CAMPAIGNS_RECIPIENTS.some(
        (item) => item.SELECTED == false
      );

    if (NotSelected == false) {
      tempAnotherCampaignList.isAllExistingContactSelected = true;
    } else {
      tempAnotherCampaignList.isAllExistingContactSelected = false;
    }

    setFromAnotherCampaignQuery(tempAnotherCampaignList);
  };

  // Handle "Select All" checkbox
  const handleAnotherCampaignSelectAll = () => {
    let tempAnotherCampaignList = JSON.parse(
      JSON.stringify(fromAnotherCampaignQuery)
    );
    if (tempAnotherCampaignList.isAllExistingContactSelected == false) {
      tempAnotherCampaignList.SELECTED_CAMPAIGNS_RECIPIENTS.map((val) => {
        val.SELECTED = true;
      });
      tempAnotherCampaignList.isAllExistingContactSelected = true;
    } else {
      tempAnotherCampaignList.SELECTED_CAMPAIGNS_RECIPIENTS.map((val) => {
        val.SELECTED = false;
      });
      tempAnotherCampaignList.isAllExistingContactSelected = false;
    }

    setFromAnotherCampaignQuery(tempAnotherCampaignList);
  };

  const handleAntCampaignContactDelete = (index) => {
    let tempAnotherCampaignList = JSON.parse(
      JSON.stringify(fromAnotherCampaignQuery)
    );
    tempAnotherCampaignList.SELECTED_CAMPAIGNS_RECIPIENTS.splice(index, 1);
    setFromAnotherCampaignQuery(tempAnotherCampaignList);
  };

  const recipientTableColumns = [
    {
      name: "Name",
      selector: (val) => val.NAME,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (val) => val.EMAIL,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.MOBILE,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.COMPANY,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (val) => val.DESIGNATION,
      sortable: true,
    },
    {
      name: "Action",
      selector: (val) => val.DESIGNATION,
      sortable: false,
      cell: (val, index) => {
        return (
          <div className="delete-button">
            {val.DELETE_LOADING ? (
              <CircularProgress sx={{ color: "red" }} size={"20px"} />
            ) : (
              <img
                src={deleteImage}
                style={{
                  width: 20,
                  height: 20,
                }}
                onClick={() => {
                  deleteRecipient([val]);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
  const recipientAnotherCampaignTableColumns = [
    {
      name: (
        <input
          type="checkbox"
          checked={fromAnotherCampaignQuery.isAllExistingContactSelected}
          onChange={() => {
            handleAnotherCampaignSelectAll();
          }}
        />
      ),
      selector: (row) => row._id,
      width: "50px",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.SELECTED}
          onChange={() => handleAnotherCampaignCheckboxChange(row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (val) => val.NAME,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (val) => val.EMAIL,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.MOBILE,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.COMPANY,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (val) => val.DESIGNATION,
      sortable: true,
    },
    {
      name: "Action",
      selector: (val) => val.DESIGNATION,
      sortable: false,
      cell: (val, index) => {
        return (
          <div className="delete-button">
            <img
              src={deleteImage}
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => {
                handleAntCampaignContactDelete(index);
              }}
            />
          </div>
        );
      },
    },
  ];
  const handleExistingContactCheckboxChange = (clickedData) => {
    let tempExistingContactList = JSON.parse(
      JSON.stringify(existingContactsList)
    );

    let index = tempExistingContactList.EXISTING_CONTACT_DATA_LIST.findIndex(
      (item) => item._id == clickedData._id
    );
    if (index != -1) {
      tempExistingContactList.EXISTING_CONTACT_DATA_LIST[index].SELECTED =
        !tempExistingContactList.EXISTING_CONTACT_DATA_LIST[index].SELECTED;
    }

    let NotSelected = tempExistingContactList.EXISTING_CONTACT_DATA_LIST.some(
      (item) => item.SELECTED == false
    );

    if (NotSelected == false) {
      tempExistingContactList.isAllExistingContactSelected = true;
    } else {
      tempExistingContactList.isAllExistingContactSelected = false;
    }

    setExistingContactsList(tempExistingContactList);
  };

  // Handle "Select All" checkbox
  const handleExistingContactSelectAll = (clickedData) => {
    let tempExistingContactList = JSON.parse(
      JSON.stringify(existingContactsList)
    );
    if (tempExistingContactList.isAllExistingContactSelected == false) {
      tempExistingContactList.EXISTING_CONTACT_DATA_LIST.map((val) => {
        val.SELECTED = true;
      });
      tempExistingContactList.isAllExistingContactSelected = true;
    } else {
      tempExistingContactList.EXISTING_CONTACT_DATA_LIST.map((val) => {
        val.SELECTED = false;
      });
      tempExistingContactList.isAllExistingContactSelected = false;
    }

    setExistingContactsList(tempExistingContactList);
  };
  const handleExistingCampaignContactDelete = (index) => {
    let tempExistingContactList = JSON.parse(
      JSON.stringify(existingContactsList)
    );
    tempExistingContactList.EXISTING_CONTACT_DATA_LIST.splice(index, 1);
    setExistingContactsList(tempExistingContactList);
  };
  const existingContactListColumns = [
    {
      name: (
        <input
          type="checkbox"
          checked={existingContactsList.isAllExistingContactSelected}
          onChange={() => {
            handleExistingContactSelectAll();
          }}
        />
      ),
      selector: (row) => row._id,
      width: "50px",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.SELECTED}
          onChange={() => handleExistingContactCheckboxChange(row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (val) => val.NAME,
      sortable: true,
    },
    {
      name: "Name",
      selector: (val) => val.NAME,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (val) => val.EMAIL,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.MOBILE,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.COMPANY,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (val) => val.DESIGNATION,
      sortable: true,
    },
    {
      name: "Action",
      selector: (val) => val.DESIGNATION,
      sortable: false,
      cell: (val, index) => {
        return (
          <div className="delete-button">
            <img
              src={deleteImage}
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => {
                handleExistingCampaignContactDelete(index);
              }}
            />
          </div>
        );
      },
    },
  ];

  const deleteRecipient = async (deletedData) => {
    let payloadRecipient = [];
    let tempRecipientData = [...recipientData];

    deletedData.map((val) => {
      payloadRecipient.push({
        _id: val._id,
      });

      const recipientIndex = tempRecipientData.findIndex(
        (item) => item._id == val._id
      );
      tempRecipientData[recipientIndex].DELETE_LOADING = true;
    });

    setRecipientData(tempRecipientData);

    await fetch(AXIOS.defaultPort + AXIOS.deleteRecipient, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        RECIPIENTS: payloadRecipient,
        CAMPAIGN_ID: selectedCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Recipient Deleted");

        let tempRecipientData = [...recipientData];
        payloadRecipient.map((val) => {
          let index = tempRecipientData.findIndex(
            (item) => item._id == val._id
          );
          if (index) {
            tempRecipientData.splice(index, 1);
          }
        });
        setRecipientData(tempRecipientData);

        getRecipientData();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const recipientCampaignColumns = [
    {
      name: "Name",
      selector: (val) => val.NAME,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (val) => val.EMAIL,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.MOBILE,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.COMPANY,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (val) => val.DESIGNATION,
      sortable: true,
    },
    {
      name: "Email Delivered",
      selector: (val) => val.DELIVERED,
      sortable: true,
      width: "140px",
      cell: (val) => {
        return <span>{val.DELIVERED == true ? "Yes" : "No"}</span>;
      },
    },
    {
      name: "Open",
      selector: (val) => val.OPENED,
      sortable: true,
      cell: (val) => {
        return <span>{val.OPENED == true ? "Yes" : "No"}</span>;
      },
    },
    {
      name: "Delivery time",
      selector: (val) => val.DELIVERED_TIME,
      sortable: true,
      width: "170px",
      cell: (val) => {
        return (
          <>
            <span>
              {/* {val.DELIVERED_TIME
                ? new Date(val.DELIVERED_TIME).toDateString()
                : ""} */}
              {val.DELIVERED_TIME
                ? formatDateToDDMMYYYY(val.DELIVERED_TIME)
                : ""}{" "}
            </span>{" "}
            <span
              style={{
                marginLeft: 5,
              }}
            >
              {val.DELIVERED_TIME
                ? new Date(val.DELIVERED_TIME).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  })
                : "--"}
            </span>
          </>
        );
      },
    },

    {
      name: "Open time",
      selector: (val) => val.OPENED_TIME,
      sortable: true,
      width: "170px",
      cell: (val) => {
        return (
          <>
            <span>
              {val.OPENED_TIME ? formatDateToDDMMYYYY(val.OPENED_TIME) : ""}{" "}
            </span>
            <span
              style={{
                marginLeft: 5,
              }}
            >
              {val.OPENED_TIME
                ? new Date(val.OPENED_TIME).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  })
                : "--"}
            </span>
          </>
        );
      },
    },
  ];

  const [recipientData, setRecipientData] = useState(null);
  const [emailRecipientQuery, setEmailRecipientQuery] = useState({
    DELIVERY_STATUS: { label: "Show All", value: 1 },
  });

  useEffect(() => {
    // if (location.state.campaign_id) {
    if (selectedCampaignId) {
      getRecipientData();
      getCampaignData();
      getAllCampaignList();
      getCampaignReportCardData();
      getLast24HrPerformanceGraphData();
      getChildCampaignData();
      getEmailContent();
    }
    // }
  }, [selectedCampaignId]);

  useEffect(() => {
    if (props.auth_id) {
      getExistingContactData();
    }
  }, [props.auth_id]);

  const [childCampaignList, setChildCampaignList] = useState([]);
  const [childModalOpen, setChildModalOpen] = React.useState(false);
  const childModalOpenRef = React.useRef(null);
  const getChildCampaignData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.getChildrenCampaigns, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: selectedCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setChildCampaignList(data);
      })
      .catch((err) => {});
  };

  const [Last24HrPerformanceData, setLast24HrPerformanceData] = useState({
    LABELS: [],
    COUNT: [],
    COLOR: "rgb(91, 91, 240)",
    TITLE: "Opened",
  });
  const [Last24HrPerformanceQuery, setLast24HrPerformanceQuery] = useState({
    INTERVAL: { label: "2 Hours", value: 120 },
    EMAIL_STATUS: {
      label: "Opened",
      value: "OPENED",
    },
  });

  useEffect(() => {
    getLast24HrPerformanceGraphData();
  }, [Last24HrPerformanceQuery, selectedCampaignId]);

  const getLast24HrPerformanceGraphData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.last24HrPerformanceGraph, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: selectedCampaignId,
        INTERVAL: Last24HrPerformanceQuery.INTERVAL.value,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const labels = data.map((item) => formatTime(item.START_TIME)); // Generate time labels
        const deliveredCounts = data.map(
          (item) => item.DELIVERED_COUNT_PERCENT
        ); // Extract DELIVERED_COUNT
        const openedCounts = data.map((item) => item.OPENED_COUNT_PERCENT); // Extract OPENED_COUNT
        const BouncedCounts = data.map((item) => item.BOUNCED_COUNT_PERCENT); // Extract OPENED_COUNT

        let transformedData = { COLOR: "", COUNT: [], TITLE: "" };
        if (Last24HrPerformanceQuery.EMAIL_STATUS.value == "OPENED") {
          transformedData.COUNT = openedCounts;
          transformedData.COLOR = "rgb(91, 91, 240)";
          transformedData.TITLE = "Opened";
        } else if (Last24HrPerformanceQuery.EMAIL_STATUS.value == "DELIVERY") {
          transformedData.COUNT = deliveredCounts;
          transformedData.COLOR = "rgb(0, 128, 0)";
          transformedData.TITLE = "Delivery";
        } else if (Last24HrPerformanceQuery.EMAIL_STATUS.value == "BOUNCED") {
          transformedData.COUNT = BouncedCounts;
          transformedData.COLOR = "rgb(139, 0, 0)";
          transformedData.TITLE = "Bounced";
        }
        console.log("jsndajksdasd", transformedData);

        setLast24HrPerformanceData({
          LABELS: labels,
          ...transformedData,
          // DELIVERY_COUNTS: deliveredCounts,
          // OPEN_COUNTS: openedCounts,
          // BOUNCED_COUNTS: BouncedCounts,
        });
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const getCampaignReportCardData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.getCountsForCampaign, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: selectedCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setSubCampaignOptions([
          {
            label: "Target All Contacts",
            value: "1",
            TOTAL_COUNT: data.RECIPIENT_COUNT || 0,
          },
          {
            label: "Create for All Delivered Emails",
            value: "2",
            TOTAL_COUNT: data.DELIVERED_COUNT || 0,
          },

          {
            label: "Create for opened Emails",
            value: "4",
            TOTAL_COUNT: data.OPENED_COUNT || 0,
          },
          {
            label: "Create for Not opened Email",
            value: "5",
            TOTAL_COUNT:
              data.DELIVERED_COUNT - data.OPENED_COUNT > 0
                ? data.DELIVERED_COUNT - data.OPENED_COUNT
                : 0,
          },
        ]);

        setCampaignAnalysis([
          {
            TITLE: "Delivered Emails",
            ID: "1",
            VALUE: data.DELIVERED_COUNT || 0,
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Delivered Emails %",
            VALUE: data.DELIVERED_PERCENT || "0.00 %",

            ID: "2",

            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Opened Emails",
            VALUE: data.OPENED_COUNT || "0",
            ID: "3",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Opened Emails %",
            VALUE: data.OPENED_PERCENT || "0.00 %",
            ID: "4",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Recipients",
            VALUE: data.RECIPIENT_COUNT || "0.00 %",
            ID: "5",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Pending Email",
            VALUE: data.PENDING_COUNT || "0",
            ID: "6",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Bounced Emails",
            VALUE: data.BOUNCED_COUNT || "0",
            ID: "6",
            SHOW: true,
            HOVER_SHADOW: "",
          },
          {
            TITLE: "Bounced Emails %",
            VALUE: data.BOUNCED_PERCENT || "0.00",
            ID: "7",
            SHOW: true,
            HOVER_SHADOW: "",
          },
        ]);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const campaignSubjectUpdate = async (updatingPart) => {
    let campaignSubjectFormData = new FormData();

    if (updatingPart == "SUBJECT") {
      campaignSubjectFormData.append("SUBJECT", campaignSubjectData.SUBJECT);
      campaignSubjectFormData.append(
        "PREVIEW_TEXT",
        campaignSubjectData.PREVIEW_TEXT
      );
    } else if (updatingPart == "FROM_NAME") {
      campaignSubjectFormData.append(
        "FROM_NAME",
        campaignSubjectData.FROM_NAME
      );
      campaignSubjectFormData.append(
        "FROM_EMAIL",
        campaignSubjectData.FROM_EMAIL
      );
    } else if (updatingPart == "ATTACHMENTS") {
      setTransactionFlags((prev) => ({
        ...prev,
        ATTACHMENT_SAVE_LOADING: true,
      }));

      campaignSubjectData.ATTACHMENT.forEach((file, index) => {
        if (file?.IS_DELETED != null) {
        } else {
          campaignSubjectFormData.append(`ATTACHMENTS`, file);
        }
      });

      let AttachmentDrawerStateJson = [];

      campaignSubjectData.ATTACHMENT.map((val) => {
        if (val?.IS_DELETED != null) {
          AttachmentDrawerStateJson.push({
            FILE_NAME: val.name,
            FILE_SIZE: val.size,
            IS_DELETED: val.IS_DELETED,
          });
        }
      });

      campaignSubjectFormData.append(
        `ATTACHMENT_JSON`,
        JSON.stringify(AttachmentDrawerStateJson)
      );
    }
    campaignSubjectFormData.append("CAMPAIGN_ID", selectedCampaignId);
    campaignSubjectFormData.append("CREATED_BY", props.auth_id);
    setTransactionFlags((prev) => ({
      ...prev,
      SUBJECT_SAVE_LOADING: true,
    }));

    await fetch(AXIOS.defaultPort + AXIOS.createCampaignTemplate, {
      method: "POST",
      body: campaignSubjectFormData,
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (updatingPart == "SUBJECT") {
          toast.success("Campaign Subject Updated");
        } else if (updatingPart == "FROM_NAME") {
          toast.success("Campaign From Updated");
        } else if (updatingPart == "ATTACHMENT") {
          toast.success("Campaign Attachment Added");
        }
        setTransactionFlags((prev) => ({
          ...prev,
          SUBJECT_SAVE_LOADING: false,
          ATTACHMENT_SAVE_LOADING: false,
        }));
        getEmailContent();
      })
      .catch((err) => {
        setTransactionFlags((prev) => ({
          ...prev,
          SUBJECT_SAVE_LOADING: false,
          ATTACHMENT_SAVE_LOADING: false,
        }));
      });
  };
  const campaignNameUpdate = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.updateCampaign, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_NAME: campaignName.NAME,
        CAMPAIGN_ID: selectedCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Campaign Name Updated");
        getCampaignData();
      })
      .catch((err) => {});
  };
  const campaignPersonalizationUpdate = async () => {
    setTransactionFlags((prev) => ({
      ...prev,
      PERSONALIZATION_SAVE_LOADING: true,
    }));
    let tempCampaignPersonalizationArr = droppedItems.map((val) => val.id);
    await fetch(AXIOS.defaultPort + AXIOS.updateCampaign, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_GREETING: tempCampaignPersonalizationArr,
        CAMPAIGN_GREETING_HTML: campaignGreetingData.CAMPAIGN_GREETINGS_HTML,
        CAMPAIGN_ID: selectedCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Campaign Personalization Updated");
        setTransactionFlags((prev) => ({
          ...prev,
          PERSONALIZATION_SAVE_LOADING: false,
        }));
        getCampaignData();
      })
      .catch((err) => {
        setTransactionFlags((prev) => ({
          ...prev,
          PERSONALIZATION_SAVE_LOADING: true,
        }));
      });
  };

  const getRecipientData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.emailRecipientGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ CAMPAIGN_ID: selectedCampaignId }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.length > 0) {
          setRecipientStep("RECIPIENT_TABLE");
          setRecipientData(data);
        }
      })
      .catch((err) => {});
  };
  const getCampaignData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.getCampaignById, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ CAMPAIGN_ID: selectedCampaignId }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.length > 0) {
          setCampaignData(data[0]);
          setTransactionFlags((prev) => ({
            ...prev,
            CAMPAIGN_LOADING: false,
            CAMPAIGN_FOUND: true,
          }));
          setCampaignName({
            NAME: data[0].CAMPAIGN_NAME,
            DEFAULT_NAME: data[0].CAMPAIGN_NAME,
            PARENT_CAMPAIGN: data[0]?.PARENT_CAMPAIGN,
            PARENT_CAMPAIGN_NAME: data[0]?.PARENT_CAMPAIGN_NAME,
          });
          let tempShowCampaignDetails = {};
          if (data[0].CAMPAIGN_STATUS == "1") {
            tempShowCampaignDetails = {
              STATUS: false,
              STATUS_CODE: data[0].CAMPAIGN_STATUS,
            };
          } else if (
            data[0].CAMPAIGN_STATUS == "2" ||
            data[0].CAMPAIGN_STATUS == "3"
          ) {
            tempShowCampaignDetails = {
              STATUS: true,
              STATUS_CODE: data[0].CAMPAIGN_STATUS,
            };
          }

          setShowCampaignDetailsPage(tempShowCampaignDetails);

          let TempDroppedItem = [];

          if (
            data[0]?.CAMPAIGN_GREETING &&
            data[0].CAMPAIGN_GREETING.length > 0
          ) {
            data[0].CAMPAIGN_GREETING.map((val) => {
              let tempDroppableItem = droppableMaster.find(
                (item) => item.id.toLowerCase() == val.toLowerCase()
              );

              TempDroppedItem.push({ ...tempDroppableItem });
            });
          }
          setDroppedItems(TempDroppedItem);
        }
      })
      .catch((err) => {
        setTransactionFlags((prev) => ({
          ...prev,
          CAMPAIGN_LOADING: false,
          CAMPAIGN_FOUND: false,
        }));
      });
  };
  const getAllCampaignList = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.getCampaignAll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        AUTH_ID: props.auth_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.length > 0) {
          let tempData = [];

          function shortenDescription(description) {
            if (description) {
              const words = description.split(" "); // Split the string into an array of words
              if (words.length > 10) {
                return words.slice(0, 10).join(" ") + " ..."; // Join the first 10 words and add "..."
              }
              return "( " + description + " )"; // If there are 10 or fewer words, return the original string
            }
            return "";
          }

          data.map((val) => {
            tempData.push({
              ...val,
              label:
                val.CAMPAIGN_NAME +
                shortenDescription(val.CAMPAIGN_DESCRIPTION),
              value: val._id,
            });
          });
          setAllCampaignList(tempData);
        }
      })
      .catch((err) => {});
  };
  const getRecipientByCampaigns = async () => {
    setTransactionFlags((prev) => ({
      ...prev,
      FROM_CAMPAIGN_SEARCH_LOADING: true,
    }));

    let tempCampaignId = fromAnotherCampaignQuery.SELECTED_CAMPAIGNS.map(
      (val) => val._id
    );

    await fetch(AXIOS.defaultPort + AXIOS.getCampaignRecipient, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        AUTH_ID: props.auth_id,
        CAMPAIGN_ID: tempCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.length > 0) {
          let tempFromAnotherCampaignQuery = JSON.parse(
            JSON.stringify(fromAnotherCampaignQuery)
          );

          let tempData = [];
          data.map((val) => {
            tempData.push({
              ...val,
              SELECTED: false,
            });
          });
          tempFromAnotherCampaignQuery.SELECTED_CAMPAIGNS_RECIPIENTS = tempData;

          setFromAnotherCampaignQuery(tempFromAnotherCampaignQuery);
        }
        setTransactionFlags((prev) => ({
          ...prev,
          FROM_CAMPAIGN_SEARCH_LOADING: false,
        }));
      })
      .catch((err) => {
        setTransactionFlags((prev) => ({
          ...prev,
          FROM_CAMPAIGN_SEARCH_LOADING: false,
        }));
      });
  };

  const uploadRecipientFile = async () => {
    if (recipientFile != "") {
      let recipientFormData = new FormData();
      recipientFormData.append("emailFile", recipientFile);
      recipientFormData.append("campaignId", selectedCampaignId);
      await fetch(AXIOS.defaultPort + AXIOS.recipientContactImport, {
        method: "POST",
        body: recipientFormData,
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          if (data.NAME_ERROR == true) {
            toast.error("Contact Name not found in the sheet");
          }
          if (data.EMAIL_ERROR == true) {
            toast.error("Contact Email not found in the sheet");
          }
          if (data.SUCCESS == true) {
            toast.success("Contact Import Successfully");
          }

          getRecipientData();
        })
        .catch((error) => {
          //  setUploadStatus("An error occurred during upload.");
        });
    } else {
      toast.error("Please upload the file");
    }
  };

  const getExistingContactData = async () => {
    await fetch(AXIOS.defaultPort + AXIOS.contactDataGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        START_DATE: "",
        END_DATE: "",
        AUTH_ID: props.auth_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (data?.value) {
          if (data?.value.length <= 0) {
          }
          let tempData = [];

          data.value.map((val) => {
            tempData.push({
              _id: val._id,
              NAME: val.name0,
              EMAIL: val.email,
              MOBILE: val.mobile,
              COMPANY: val.company,
              DESIGNATION: val.designation,
              SELECTED: false,
            });
          });

          setExistingContactsList((prev) => ({
            ...prev,
            EXISTING_CONTACT_DATA_LIST: tempData,
          }));
          setTransactionFlags((prev) => ({
            ...prev,
            EXISTING_CONTACT_LOADING: false,
          }));
        }
      })
      .catch((err) => {
        setTransactionFlags((prev) => ({
          ...prev,
          EXISTING_CONTACT_LOADING: false,
        }));
      });
  };
  const saveRecipientFromAnotherCampaign = async () => {
    let tempRecipientList = [];
    fromAnotherCampaignQuery.SELECTED_CAMPAIGNS_RECIPIENTS.map((val) => {
      if (val.SELECTED == true) {
        tempRecipientList.push({
          ...val,
        });
      }
    });

    if (tempRecipientList.length > 0) {
      setTransactionFlags((prev) => ({
        ...prev,
        FROM_CAMPAIGN_SAVE_LOADING: true,
      }));

      const blob = new Blob([JSON.stringify(tempRecipientList)]);
      const file = new File([blob], "RECIPIENTS.json", {
        type: "application/json",
      });

      const recipientFormData = new FormData();

      recipientFormData.append("file", file);
      recipientFormData.append("CAMPAIGN_ID", selectedCampaignId);

      await fetch(AXIOS.defaultPort + AXIOS.recipientImportFromArray, {
        method: "POST",
        body: recipientFormData,
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (data.length > 0) {
          }
          if (data.SUCCESS == true) {
          }
          getRecipientData();
          setTransactionFlags((prev) => ({
            ...prev,
            FROM_CAMPAIGN_SAVE_LOADING: false,
          }));
          toast.success("Contacts Imported Successful");
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            FROM_CAMPAIGN_SAVE_LOADING: false,
          }));
        });
    } else {
      toast.error("No data selected to import");
    }
  };
  const saveRecipientFromExistingContacts = async () => {
    let tempRecipientData = [];

    let tempExistingContactsList = { ...existingContactsList };

    tempExistingContactsList.EXISTING_CONTACT_DATA_LIST.map((val) => {
      if (val.SELECTED) {
        tempRecipientData.push({
          NAME: val.NAME,
          EMAIL: val.EMAIL,
          MOBILE: val.MOBILE,
          COMPANY: val.COMPANY,
          DESIGNATION: val.DESIGNATION,
        });
      }
    });

    if (tempRecipientData.length > 0) {
      setTransactionFlags((prev) => ({
        ...prev,
        EXISTING_CONTACT_SAVE_LOADING: true,
      }));

      const blob = new Blob([JSON.stringify(tempRecipientData)]);
      const file = new File([blob], "RECIPIENTS.json", {
        type: "application/json",
      });

      const recipientFormData = new FormData();

      recipientFormData.append("file", file);
      recipientFormData.append("CAMPAIGN_ID", selectedCampaignId);

      await fetch(AXIOS.defaultPort + AXIOS.recipientImportFromArray, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify({
        //   CAMPAIGN_ID: selectedCampaignId,
        //   RECIPIENTS: tempRecipientData,
        // }),
        body: recipientFormData,
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (data.length > 0) {
          }

          getRecipientData();
          setTransactionFlags((prev) => ({
            ...prev,
            EXISTING_CONTACT_SAVE_LOADING: false,
          }));

          tempExistingContactsList.EXISTING_CONTACT_DATA_LIST.map((val) => {
            val.SELECTED = false;
          });

          setExistingContactsList((prev) => ({
            ...prev,
            isAllExistingContactSelected: false,
            EXISTING_CONTACT_DATA_LIST:
              tempExistingContactsList.EXISTING_CONTACT_DATA_LIST,
          }));
          toast.success("Contacts Imported Successful");
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            EXISTING_CONTACT_SAVE_LOADING: false,
          }));
        });
    } else {
      toast.error("Please select the data to import");
    }
  };

  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let MailToIndex = tempMainScreenFields.findIndex((val) => val.id == 1);
    if (MailToIndex !== -1) {
      if (recipientData && recipientData.length > 0) {
        tempMainScreenFields[MailToIndex].value =
          `${recipientData.length} Recipient`;
        tempMainScreenFields[MailToIndex].status = "complete";
      } else {
        tempMainScreenFields[MailToIndex].value = "";
        tempMainScreenFields[MailToIndex].status = "error";
      }
    }
    setMainScreenFields(tempMainScreenFields);
  }, [recipientData]);
  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let PersonalizationIndex = tempMainScreenFields.findIndex(
      (val) => val.id == 6
    );
    if (PersonalizationIndex !== -1) {
      if (
        campaignData?.CAMPAIGN_GREETING &&
        campaignData?.CAMPAIGN_GREETING.length > 0
      ) {
        tempMainScreenFields[PersonalizationIndex].value =
          campaignData.CAMPAIGN_GREETING.map((val) => val).join(" ");
        tempMainScreenFields[PersonalizationIndex].status = "complete";
      } else {
        tempMainScreenFields[PersonalizationIndex].value = "";
        tempMainScreenFields[PersonalizationIndex].status = "error";
      }
    }
    setMainScreenFields(tempMainScreenFields);
  }, [campaignData]);
  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let contentIndex = tempMainScreenFields.findIndex((val) => val.id == 5);
    if (contentIndex !== -1) {
      if (emailContentData?.TEMPLATE_HTML != null) {
        tempMainScreenFields[contentIndex].status = "complete";
      } else {
        tempMainScreenFields[contentIndex].status = "error";
      }
    }
    setMainScreenFields(tempMainScreenFields);
  }, [emailContentData]);

  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    let subjectIndex = tempMainScreenFields.findIndex((val) => val.id == 3);
    // let FromIndex = tempMainScreenFields.findIndex((val) => val.id == 2);

    if (subjectIndex !== -1) {
      if (
        campaignSubjectData.SUBJECT_SET == true &&
        campaignSubjectData.SUBJECT == ""
      ) {
        tempMainScreenFields[subjectIndex].status = "error";
      } else {
        tempMainScreenFields[subjectIndex].status = "complete";
      }
      tempMainScreenFields[subjectIndex].value =
        campaignSubjectData.SUBJECT || "";
      tempMainScreenFields[subjectIndex].PREVIEW_TEXT =
        campaignSubjectData.PREVIEW_TEXT || "";
    }

    // if (FromIndex != -1) {
    //   if (campaignSubjectData.SUBJECT_SET == true) {
    //     if (campaignSubjectData.FROM_NAME_DEFAULT != "") {
    //       tempMainScreenFields[FromIndex].value =
    //         campaignSubjectData.FROM_NAME_DEFAULT || "";
    //       tempMainScreenFields[FromIndex].status = "complete";
    //     } else {
    //       tempMainScreenFields[FromIndex].value = "";
    //       tempMainScreenFields[FromIndex].status = "error";
    //     }
    //     if (campaignSubjectData.FROM_EMAIL_DEFAULT != "") {
    //       tempMainScreenFields[FromIndex].description =
    //         campaignSubjectData.FROM_EMAIL_DEFAULT || "";
    //     } else {
    //     }
    //   }
    // }
    setMainScreenFields(tempMainScreenFields);
  }, [campaignSubjectData]);

  const [mainScreenFields, setMainScreenFields] = useState([
    {
      id: 1,
      label: "To: ",
      description: "Who are you sending this email to?",
      buttonLabel: "Add recipients",
      value: `${recipientData ? recipientData.length : ""} Recipient`,
      status: "complete",
      onClick: () => {
        setRecipientsDrawerState(true);
      },
    },
    // {
    //   id: 2,
    //   label: "From: ",
    //   description: "",
    //   buttonLabel: "Edit from",
    //   status: "complete",
    //   onClick: () => {
    //     setFromDrawerState(true);
    //   },
    // },
    {
      id: 3,
      label: "Subject: ",
      description: "What's the subject line for this email?",
      buttonLabel: "Add subject",
      value: "",
      PREVIEW_TEXT: "",
      status: "error",
      onClick: () => {
        setSubjectDrawerState(true);
      },
    },
    {
      id: 6,
      label: "Personalization: ",
      description: "How would you Address the recipient by name in this email?",
      buttonLabel: "Add",
      value: "",
      PREVIEW_TEXT: "",
      status: "error",
      onClick: () => {
        setPersonalizationDrawerState(true);
      },
    },
    {
      id: 4,
      label: "Send time",
      description: "Send now",
      buttonLabel: "Edit send time",
      status: "complete",
      onClick: () => {
        toast.success("Feature coming soon...");
        // setSubjectDrawerState(true);
      },
    },
    {
      id: 5,
      label: "Content",
      description: "Design the Content for your email",
      buttonLabel: "Design Mail",
      status: "complete",
      onClick: () => {},
    },
    {
      id: 5,
      label: "Attachments",
      description: "Attach the file for your email",
      buttonLabel: "Attach File",
      status: "complete",
      onClick: () => {
        setAttachmentDrawerState(true);
        // toast.success("Feature coming soon...");
      },
    },
  ]);

  useEffect(() => {
    let tempMainScreenFields = [...mainScreenFields];
    const contentIndex = tempMainScreenFields.findIndex((val) => val.id == 5);

    if (contentIndex != -1) {
      if (emailContentData?.TEMPLATE_IMAGE) {
        tempMainScreenFields[contentIndex].buttonLabel = "Edit Mail";
        tempMainScreenFields[contentIndex].onClick = () => {
          navigate("/email-template-edit", {
            state: {
              campaign_id: selectedCampaignId,
              DESIGN: emailContentData.DESIGN,
              SAVE_DESIGN: false,
            },
          });
        };
      } else {
        tempMainScreenFields[contentIndex].buttonLabel = "Design Mail";
        tempMainScreenFields[contentIndex].onClick = () => {
          navigate("/email-templates", {
            state: {
              campaign_id: selectedCampaignId,
              SAVE_DESIGN: false,
            },
          });
        };
      }
    }
  }, [emailContentData, selectedCampaignId]);
  const [showSubjectPicker, setShowSubjectPicker] = useState(false);
  const [allCampaignList, setAllCampaignList] = useState([]);

  const [showPreviewTxtPicker, setShowPreviewTxtPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const SubjectPickerRef = useRef(null);
  const PreviewTxtPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        SubjectPickerRef.current &&
        !SubjectPickerRef.current.contains(event.target)
      ) {
        setShowSubjectPicker(false);
      }
      if (
        PreviewTxtPickerRef.current &&
        !PreviewTxtPickerRef.current.contains(event.target)
      ) {
        setShowPreviewTxtPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [CampaignErrors, setCampaignErrors] = useState({
    ERROR_FOUND: false,
    TRANSACTION_STARTED: false,
  });

  const sendCampaignEmails = async () => {
    setCampaignErrors((prev) => ({
      ...prev,
      TRANSACTION_STARTED: true,
    }));
    await fetch(AXIOS.defaultPort + AXIOS.emailSend, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CAMPAIGN_ID: selectedCampaignId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        let errorFound = false;
        if (data.RECIPIENTS_ERROR) {
          errorFound = true;
          toast.error("Please add recipients");
        }

        if (data.TEMPLATE_SUBJECT_ERROR) {
          errorFound = true;
          toast.error("Please save Subject");
        }
        if (data.TEMPLATE_HTML_ERROR) {
          errorFound = true;
          toast.error("Please save email content");
        }
        if (data.TEMPLATE_DESIGN_ERROR) {
          errorFound = true;
          toast.error(
            "Something went wrong, Please try saving Email content again"
          );
        }
        if (errorFound == false) {
          // toast.success("Campaign Started Successfully");
          setShowConfetti(true);

          // getCampaignData();

          getEmailContent();
          setCampaignErrors({
            ...data,
            ERROR_FOUND: errorFound,
            TRANSACTION_STARTED: false,
          });
        } else {
          setCampaignErrors({
            ...data,
            ERROR_FOUND: errorFound,
            TRANSACTION_STARTED: false,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (showConfetti == true) {
      setTimeout(() => {
        setShowConfetti(false);
        setShowCampaignDetailsPage((prev) => ({
          ...prev,
          STATUS: true,
          STATUS_CODE: "2",
        }));
      }, 3000);
    }
  }, [showConfetti]);
  const openURLInBrowser = (url) => {
    window.open(url, "_blank"); // Opens the URL in a new tab
  };
  const [url, setUrl] = useState("");
  const [previewUrl, setPreviewUrl] = useState({
    HTML_PREVIEW: "",
    HTML_TEXT: "",
  });

  const handlePreview = (url) => {
    if (url) {
      try {
        const isValidUrl = new URL(url); // Validate URL format
        setPreviewUrl({
          HTML_PREVIEW: url,
        }); // Set the validated URL for preview
        fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.text(); // Get response as plain text
          })
          .then((text) => {
            setPreviewUrl((prev) => ({
              ...prev,
              HTML_TEXT: text,
            })); // Update the content state
          })
          .catch((err) => {
            console.error("Failed to fetch content:", err);
            // setError("Failed to load content. Please try again later.");
          });
      } catch (error) {
        alert("Invalid URL! Please enter a valid URL.");
      }
    }
  };

  const [campaignAnalysis, setCampaignAnalysis] = useState([
    {
      TITLE: "Delivered Emails",
      ID: "1",
      VALUE: "",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Delivered Emails %",
      VALUE: "",
      ID: "2",

      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Opened Emails",
      VALUE: "",
      ID: "3",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Opened Emails %",
      VALUE: "",
      ID: "4",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Recipients",
      VALUE: "",
      ID: "5",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    // {
    //   TITLE: "Sent Email",
    //   VALUE: "",
    //   SHOW: true,
    //   HOVER_SHADOW: "",
    // },
    {
      TITLE: "Pending Email",
      VALUE: "",
      ID: "6",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Bounced Emails",
      VALUE: "",
      ID: "7",
      SHOW: true,
      HOVER_SHADOW: "",
    },
    {
      TITLE: "Bounced Emails %",
      VALUE: "",
      ID: "8",
      SHOW: true,
      HOVER_SHADOW: "",
    },
  ]);
  const [copied, setCopied] = useState(false);
  const handleCopy = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  const sendTestEmail = () => {
    if (sendTestEmailQuery.EMAILS != "") {
      setTransactionFlags((prev) => ({
        ...prev,
        SEND_TEST_EMAIL_LOADING: true,
      }));
      const tempSendTestEmailQuery = sendTestEmailQuery.EMAILS.split(",").map(
        (email) => email.trim()
      );

      fetch(AXIOS.defaultPort + AXIOS.testEmailSend, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CAMPAIGN_ID: selectedCampaignId,
          RECIPIENTS: tempSendTestEmailQuery,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSendTestEmailQuery((prev) => ({
            ...prev,
            EMAIL_SENT: true,
          }));
          setTransactionFlags((prev) => ({
            ...prev,
            SEND_TEST_EMAIL_LOADING: false,
          }));
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            SEND_TEST_EMAIL_LOADING: true,
          }));
        });
    } else {
      toast.error("Please enter Email ID");
      setSendTestEmailQuery((prev) => ({
        ...prev,
        EMAIL_ERROR: true,
      }));
    }
  };

  const CsvHeader = [
    {
      name: "Name",
      selector: "NAME",
    },
    {
      name: "Email",
      selector: "EMAIL",
    },
    {
      name: "Mobile",
      selector: "MOBILE",
    },

    {
      name: "Company",
      selector: "COMPANY",
    },
    {
      name: "Designation",
      selector: "DESIGNATION",
    },
    {
      name: "Delivered",
      selector: "DELIVERED",
    },
    {
      name: "Opened",
      selector: "OPENED",
    },
    {
      name: "Opened Time",
      selector: "OPENED_TIME",
    },
  ];
  const handleClickPerformanceDataExport = () => {
    let tempData = [];

    let excelData = [...recipientData];

    if (excelData.length > 0) {
      // Exclude the "Action" column from csvColumns
      const csvColumns = CsvHeader.filter(
        (column) => column.name !== "Action"
      ).map((column) => column.name);

      const csvRows = excelData.map((item) =>
        csvColumns.map((columnName) => {
          const column = CsvHeader.find((col) => col.name === columnName);
          if (column) {
            let cellValue = "";
            if (typeof column.name === "function") {
              cellValue = column.selector(item);
            } else {
              if (column.name == "Opened Time") {
                if (item[column.selector]) {
                  cellValue =
                    new Date(item[column.selector]).toDateString() +
                    new Date(item[column.selector]).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });
                } else {
                  cellValue = "--";
                }
              } else if (column.selector == "DELIVERED") {
                cellValue = item[column.selector] == true ? "Yes" : "No";
              } else if (column.selector == "OPENED") {
                cellValue = item[column.selector] == true ? "Yes" : "No";
              } else {
                cellValue = item[column.selector] || "";
              }
            }
            // Wrap cell value in double quotes to handle commas
            return `"${cellValue}"`;
          }
          return ""; // Return an empty value for excluded columns
        })
      );
      const csvContent =
        csvColumns.join(",") +
        "\n" +
        csvRows.map((row) => row.join(",")).join("\n");

      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(
        blob,
        capitalizeFirstLetter(campaignName.NAME || "") +
          " Click Performance.csv"
      ); // Use the saveAs function to download the CSV file
    } else {
    }
  };
  return (
    <MainScreen>
      <Toaster />

      {showConfetti && (
        <div className="fullscreen-loader">
          <Lottie
            style={{
              width: 450,
              height: 450,
            }}
            play
            animationData={confettiAnime}
          />
          <p>Campaign is live !</p>
        </div>
      )}
      {transactionFlags.CAMPAIGN_LOADING == true && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Lottie
            style={{
              width: 250,
              height: 250,
            }}
            play
            animationData={loadingAnime}
          />
        </Box>
      )}
      {transactionFlags.CAMPAIGN_FOUND == false && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Box>
            <Lottie
              style={{
                width: 250,
                height: 250,
              }}
              play
              animationData={noDataFoundAnime}
            />
            <p>No data found for this Campaign</p>
          </Box>
        </Box>
      )}

      {showCampaignDetailsPage?.STATUS == false && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 2,
              mt: 3,
            }}
          >
            <Box
              sx={{
                mr: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate("/view-campaign");
              }}
            >
              <IconContext.Provider value={{ size: 25, color: "#000" }}>
                <FaArrowLeftLong />
              </IconContext.Provider>
            </Box>
            <Typography
              style={{
                fontSize: 22,
                fontWeight: 600,
                color: "#000",
                marginRight: 10,
                textAlign: "start",
              }}
            >
              Campaign Details
            </Typography>
          </Box>
          <Box
            className="campaign-details-container"
            sx={{
              mt: 3,
              p: 1,
            }}
          >
            <div className="container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {emailContentData?.TEMPLATE_HTML && (
                  <button
                    className="prev-email-template"
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setTestEmailDrawerState(true);
                    }}
                  >
                    Send Sample
                    <img
                      src={mailBoxIcon}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  </button>
                )}

                {emailContentData?.TEMPLATE_HTML && (
                  <button
                    className="prev-email-template"
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      openURLInBrowser(
                        AXIOS.defaultPort + emailContentData.TEMPLATE_HTML
                      );
                    }}
                  >
                    Email Preview
                    <img
                      src={previewEmailIcon}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  </button>
                )}

                <div className="email-button-container">
                  <label
                    className="email-label"
                    onClick={() => {
                      setCampaignSendDrawerState(true);
                    }}
                  >
                    <span className="email-circle">
                      <svg
                        className="email-icon"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M12 19V5m0 14-4-4m4 4 4-4"
                        />
                      </svg>
                      <div className="email-square" />
                    </span>
                    <p className="email-title">Send Email</p>
                  </label>
                </div>
              </div>
              {campaignName.EDIT_FLAG ? (
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <CustomInput
                    type="text"
                    Value={campaignName.NAME}
                    Style={{
                      height: 40,
                      fontSize: 20,
                    }}
                    onChange={(e) => {
                      setCampaignName({
                        ...campaignName,
                        NAME: e.target.value,
                      });
                    }}
                  />

                  <div
                    className="footer-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "20px",
                    }}
                  >
                    <button
                      className="filled-button"
                      onClick={() => {
                        campaignNameUpdate();
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="bordered-button"
                      onClick={() => {
                        setCampaignName({
                          ...campaignName,
                          EDIT_FLAG: false,
                          NAME: campaignName.DEFAULT_NAME,
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <span className="edit-name-title">{campaignName.NAME}</span>
                  <br />
                  <span
                    className="edit-name"
                    onClick={() => {
                      setCampaignName({ ...campaignName, EDIT_FLAG: true });
                    }}
                  >
                    Edit name
                  </span>
                </div>
              )}

              {mainScreenFields.map((item) => (
                <div key={item.id} className={`email-item ${item.status}`}>
                  <div className="email-item-label">
                    <span
                      className={`status-icon ${
                        item.status === "error" ? "error" : ""
                      }`}
                      style={{
                        color: "#fff",
                      }}
                    >
                      {item.status === "complete"
                        ? "✔"
                        : // : item.status === "error"
                          //   ? "⛔"
                          "✖"}
                    </span>
                    <div>
                      <span>
                        {item.label} {item.value}
                        {item?.PREVIEW_TEXT && (
                          <>
                            <br />{" "}
                            <span
                              className="email-item-description"
                              style={{
                                fontSize: 15,
                              }}
                            >
                              Preview Text: {item?.PREVIEW_TEXT}
                            </span>
                          </>
                        )}
                      </span>
                      <p className="email-item-description">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <button onClick={item.onClick}>{item.buttonLabel}</button>
                </div>
              ))}
            </div>
            <div className="right-container">
              <Box className="right-sub-container">
                <div style={{ textAlign: "center", margin: "20px" }}>
                  {previewUrl?.HTML_PREVIEW && (
                    <div
                      style={{
                        marginTop: "20px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        overflow: "hidden",
                      }}
                    >
                      <iframe
                        src={previewUrl.HTML_PREVIEW}
                        title="Link Preview"
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "none",
                        }}
                      />
                    </div>
                  )}
                </div>
              </Box>
            </div>
          </Box>

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0",
                margin: "0 10px 0 10px",
                marginTop: "10px",
                overflow: "hidden",
              },
            }}
            open={recipientsDrawerState}
            onClose={() => {
              setRecipientsDrawerState(false);
            }}
            onOpen={() => {
              setRecipientsDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Import Recipients
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setRecipientsDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Box
                sx={{
                  maxHeight: "95vh",
                  overflowY: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    // sx={{
                    //   width: "80%",
                    // }}
                    className="import-recipient-wrapper"
                  >
                    {recipientStep != "RECIPIENT_TABLE" && (
                      <p
                        className="text-means-web recipient-title"
                        style={{
                          textAlign: "start",
                        }}
                      >
                        How would you prefer to add contacts?
                      </p>
                    )}
                    <p className="text-means-web recipient-description">
                      Your contacts are your audience—the people you'll email.
                      In this step, we'll guide you through importing your data
                      and setting you up for success.
                    </p>

                    {recipientData &&
                      recipientData.length > 0 &&
                      recipientStep == "" && (
                        <button
                          className="back-button"
                          style={{
                            height: 40,
                            marginTop: 5,
                          }}
                          onClick={() => {
                            setRecipientStep("RECIPIENT_TABLE");
                          }}
                        >
                          <span className="back-text">
                            {" "}
                            Show Recipient List
                          </span>
                          <img
                            src={rightArrow}
                            alt="Back"
                            className="back-icon"
                          />
                        </button>
                      )}
                    {recipientStep == "" && (
                      <div className="import-card-main-container">
                        {recipientImportOptions.map((val) => {
                          return (
                            <div
                              className="import-card-container"
                              onClick={val.onClick}
                            >
                              <div
                                className="image-wrapper"
                                style={{
                                  marginLeft: val.MARGIN,
                                }}
                              >
                                <img
                                  src={val.IMAGE}
                                  alt="Contact Import"
                                  className="card-image"
                                />
                              </div>
                              <div className="card-content">
                                <h3>{val.TITLE}</h3>
                                {val.DeSCRIPTION}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {recipientStep == "UPLOAD_FILE" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            className="back-button"
                            onClick={() => {
                              setRecipientStep("");
                              setRecipientFile("");
                            }}
                          >
                            <img
                              src={leftArrow}
                              alt="Back"
                              className="back-icon"
                            />
                            {/* <span className="back-text">Back</span> */}
                          </button>
                          <Typography
                            style={{
                              fontSize: 25,
                              color: "#000",
                              textAlign: "start",
                              fontWeight: "bold",
                              fontFamily: "poppin",
                              marginLeft: 10,
                            }}
                          >
                            Upload File
                          </Typography>
                        </div>
                        <div className="import-csv-main-container">
                          <div className="import-csv-container">
                            <input
                              type="file"
                              id="csvInput"
                              accept=".csv,.xlsx"
                              onChange={handleFileUpload}
                              style={{ display: "none" }}
                            />
                            <label htmlFor="csvInput" className="upload-label">
                              <div className="upload-box">
                                <img
                                  src="https://cdn-icons-png.flaticon.com/512/158/158749.png" // Replace with your preferred upload icon
                                  alt="Upload"
                                  className="upload-icon"
                                />
                                <p className="upload-text">
                                  {recipientFile?.name ||
                                    "Click to upload a .csv file"}
                                </p>
                              </div>
                            </label>
                          </div>
                          <a
                            className="csv-template-download"
                            href="https://pdhanewala.com:9002/recipientExcelTemplate.xlsx"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="70"
                              height="70"
                              fill="currentColor"
                            >
                              <path d="M5 20h14a1 1 0 0 0 1-1v-2h-2v1H6v-1H4v2a1 1 0 0 0 1 1zM11 4v8.586L8.707 10.293l-1.414 1.414L12 16.414l4.707-4.707-1.414-1.414L13 12.586V4h-2z" />
                            </svg>
                            <h3>Download</h3>
                            <span>
                              CSV template for a well-structured and formatted
                              reference.
                            </span>
                          </a>
                        </div>

                        <div
                          className="footer-buttons"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "20px",
                          }}
                        >
                          <button
                            className="filled-button"
                            onClick={() => {
                              uploadRecipientFile();
                            }}
                          >
                            Save
                          </button>
                          <button
                            className="bordered-button"
                            onClick={() => {
                              setRecipientStep("");
                              setRecipientFile("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}

                    {recipientStep == "RECIPIENT_TABLE" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            className="back-button"
                            style={{
                              height: 40,
                              marginTop: 5,
                            }}
                            onClick={() => {
                              setRecipientStep("");
                              setRecipientFile("");
                            }}
                          >
                            <img
                              src={leftArrow}
                              alt="Back"
                              className="back-icon"
                            />
                            <span className="back-text">
                              Show Upload options
                            </span>
                          </button>
                        </div>
                        <Box sx={{}}>
                          <ReactDataTable
                            columns={recipientTableColumns}
                            data={recipientData}
                            pageCount={10}
                          />
                        </Box>
                      </>
                    )}
                    {recipientStep == "ANOTHER_CAMPAIGN" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            className="back-button"
                            onClick={() => {
                              setRecipientStep("");
                              setRecipientFile("");
                            }}
                          >
                            <img
                              src={leftArrow}
                              alt="Back"
                              className="back-icon"
                            />
                            {/* <span className="back-text">Back</span> */}
                          </button>

                          <Typography
                            style={{
                              fontSize: 25,
                              color: "#000",
                              textAlign: "start",
                              fontWeight: "bold",
                              fontFamily: "poppin",
                              marginLeft: 10,
                            }}
                          >
                            From Another Campaign
                          </Typography>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              minWidth: "60%",
                            }}
                          >
                            <CustomDropdown
                              isSearchable={false}
                              isMulti={true}
                              menuPlacement={"top"}
                              Options={allCampaignList}
                              Value={
                                fromAnotherCampaignQuery.SELECTED_CAMPAIGNS
                              }
                              OnChange={(e) => {
                                setFromAnotherCampaignQuery((prev) => ({
                                  ...prev,
                                  SELECTED_CAMPAIGNS: e,
                                }));
                              }}
                            />
                          </div>

                          <div
                            className="footer-buttons"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "10px",
                              marginLeft: 10,
                            }}
                          >
                            <button
                              className="filled-button"
                              onClick={() => {
                                getRecipientByCampaigns();
                              }}
                              style={{
                                minWidth: 100,
                              }}
                              disabled={
                                transactionFlags.FROM_CAMPAIGN_SEARCH_LOADING
                              }
                            >
                              {transactionFlags.FROM_CAMPAIGN_SEARCH_LOADING ==
                              true ? (
                                <Spinner />
                              ) : (
                                "Search"
                              )}
                            </button>

                            <button
                              className="filled-button"
                              onClick={() => {
                                saveRecipientFromAnotherCampaign();
                              }}
                              style={{
                                minWidth: 100,
                                backgroundColor: "#1770e0",
                              }}
                              disabled={
                                transactionFlags.FROM_CAMPAIGN_SAVE_LOADING
                              }
                            >
                              {transactionFlags.FROM_CAMPAIGN_SAVE_LOADING ==
                              true ? (
                                <Spinner />
                              ) : (
                                "Import"
                              )}
                            </button>
                          </div>
                        </div>

                        <div
                          style={
                            {
                              // marginTop: -30,
                            }
                          }
                        >
                          <ReactDataTable
                            columns={recipientAnotherCampaignTableColumns}
                            data={
                              fromAnotherCampaignQuery.SELECTED_CAMPAIGNS_RECIPIENTS
                            }
                            pageCount={10}
                          />
                        </div>
                      </>
                    )}
                    {recipientStep == "EXISTING_CONTACTS" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            className="back-button"
                            onClick={() => {
                              setRecipientStep("");
                              setRecipientFile("");
                            }}
                          >
                            <img
                              src={leftArrow}
                              alt="Back"
                              className="back-icon"
                            />
                          </button>

                          <Typography
                            style={{
                              fontSize: 25,
                              color: "#000",
                              textAlign: "start",
                              fontWeight: "bold",
                              fontFamily: "poppin",
                              marginLeft: 10,
                            }}
                          >
                            From Existing Contacts
                          </Typography>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="footer-buttons"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "10px",
                              marginLeft: 10,
                            }}
                          >
                            <button
                              className="filled-button"
                              onClick={() => {
                                saveRecipientFromExistingContacts();
                              }}
                              style={{
                                minWidth: 100,
                                backgroundColor: "#1770e0",
                              }}
                              disabled={
                                transactionFlags.EXISTING_CONTACT_SAVE_LOADING
                              }
                            >
                              {transactionFlags.EXISTING_CONTACT_SAVE_LOADING ==
                              true ? (
                                <Spinner />
                              ) : (
                                "Import"
                              )}
                            </button>
                            <button
                              className="filled-button"
                              onClick={() => {
                                setTransactionFlags((prev) => ({
                                  ...prev,
                                  EXISTING_CONTACT_LOADING: true,
                                }));
                                getExistingContactData();
                                toast.success("Contact List refreshed");
                              }}
                              style={{
                                minWidth: 100,
                                // backgroundColor: "#1770e0",
                              }}
                              disabled={
                                transactionFlags.EXISTING_CONTACT_LOADING
                              }
                            >
                              {transactionFlags.EXISTING_CONTACT_LOADING ==
                              true ? (
                                <Spinner />
                              ) : (
                                "Refresh"
                              )}
                            </button>
                          </div>
                        </div>

                        <div
                          style={
                            {
                              // marginTop: -30,
                            }
                          }
                        >
                          <ReactDataTable
                            columns={existingContactListColumns}
                            data={
                              existingContactsList.EXISTING_CONTACT_DATA_LIST
                            }
                            pageCount={10}
                          />
                        </div>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwipeableDrawer>
          {/* Subject Modal */}
          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={SubjectDrawerState}
            onClose={() => {
              setSubjectDrawerState(false);
            }}
            onOpen={() => {
              setSubjectDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Add Subject
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSubjectDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Typography
                sx={{
                  fontSize: 20,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  fontFamily: "poppins",
                }}
              >
                What's the subject line for this email?
              </Typography>
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                Subject
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <CustomInput
                  Placeholder={"Subject"}
                  Style={{
                    height: 40,
                  }}
                  Value={campaignSubjectData.SUBJECT}
                  onChange={(e) => {
                    setCampaignSubjectData((prev) => ({
                      ...prev,
                      SUBJECT: e.target.value,
                    }));
                  }}
                />
                <div style={{ position: "relative" }}>
                  <button
                    onClick={() => setShowSubjectPicker((prev) => !prev)}
                    style={{
                      borderRadius: 10,
                      height: 40,
                      width: 40,
                      border: "1px solid gray",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    {/* {selectedEmoji || "😊"} */}
                    <img
                      src={EmojiAdd}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                    />
                  </button>

                  {/* Emoji Picker */}
                  {showSubjectPicker && (
                    <div
                      ref={SubjectPickerRef}
                      style={{
                        position: "absolute",
                        top: "100%",
                        right: "0",
                        marginTop: "5px",
                        zIndex: 1000,
                      }}
                    >
                      <EmojiPicker
                        onEmojiClick={(emojiData) => {
                          // setSelectedEmoji(emojiData.emoji);
                          let tempCampaignSubjectData = {
                            ...campaignSubjectData,
                          };
                          tempCampaignSubjectData.SUBJECT += emojiData.emoji;
                          setCampaignSubjectData(tempCampaignSubjectData);
                          // setShowSubjectPicker(false); // Close the picker after selection
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                Preview Text
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <CustomInput
                  Placeholder={"Preview Text"}
                  Style={{
                    height: 40,
                  }}
                  Value={campaignSubjectData.PREVIEW_TEXT}
                  onChange={(e) => {
                    setCampaignSubjectData((prev) => ({
                      ...prev,
                      PREVIEW_TEXT: e.target.value,
                    }));
                  }}
                />

                <div style={{ position: "relative" }}>
                  <button
                    onClick={() => setShowPreviewTxtPicker((prev) => !prev)}
                    style={{
                      // padding: 5,
                      borderRadius: 10,
                      height: 40,
                      width: 40,
                      border: "1px solid gray",
                      background: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    {/* {selectedEmoji || "😊"} */}
                    <img
                      src={EmojiAdd}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                    />
                  </button>

                  {/* Emoji Picker */}
                  {showPreviewTxtPicker && (
                    <div
                      ref={PreviewTxtPickerRef}
                      style={{
                        position: "absolute",
                        top: "100%",
                        right: "0",
                        marginTop: "5px",
                        zIndex: 1000,
                      }}
                    >
                      <EmojiPicker
                        onEmojiClick={(emojiData) => {
                          // setSelectedEmoji(emojiData.emoji);
                          let tempCampaignSubjectData = {
                            ...campaignSubjectData,
                          };
                          tempCampaignSubjectData.PREVIEW_TEXT +=
                            emojiData.emoji;
                          setCampaignSubjectData(tempCampaignSubjectData);
                          // setShowPreviewTxtPicker(false); // Close the picker after selection
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <span className="text-means-web">
                Preview text appears in the inbox after the subject line.
              </span>

              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  marginTop: 15,
                }}
              >
                <button
                  className="bordered-button"
                  onClick={() => {
                    setSubjectDrawerState(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="filled-button"
                  onClick={() => {
                    campaignSubjectUpdate("SUBJECT");
                  }}
                  style={{
                    minWidth: 100,
                  }}
                  disabled={transactionFlags.SUBJECT_SAVE_LOADING}
                >
                  {transactionFlags.SUBJECT_SAVE_LOADING == true ? (
                    <Spinner />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className="effective-subject-card-container">
                <h2 className="card-title">Craft the Perfect Subject Line</h2>
                <ul
                  className="card-content"
                  style={{
                    textAlign: "start",
                  }}
                >
                  <li>
                    <strong>Keep it concise:</strong> Subject lines with fewer
                    than 9 words tend to perform better.
                  </li>
                  <li>
                    <strong>Use emojis wisely:</strong> A single emoji can
                    enhance engagement—use no more than one.
                  </li>
                  <li>
                    <strong>Stay within the limit:</strong> Keep your subject
                    line under 60 characters for clarity.
                  </li>
                  <li>
                    <strong>Avoid clutter:</strong> Minimize punctuation to keep
                    your message clean and clear.
                  </li>
                </ul>
                <p className="card-footer">
                  Your subject line is a perfect mix of creativity and clarity!
                </p>
              </div>
            </Box>
          </SwipeableDrawer>

          {/* Send test Email  */}

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={TestEmailDrawerState}
            onClose={() => {
              setTestEmailDrawerState(false);
            }}
            onOpen={() => {
              setTestEmailDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Send Sample Email
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setTestEmailDrawerState(false);
                  }}
                />
              </Box>
              <Divider />

              {sendTestEmailQuery.EMAIL_SENT == true && (
                <>
                  <Typography
                    sx={{
                      fontSize: 19,
                      color: "#000",
                      textAlign: "start",
                      fontFamily: "poppins",
                      mt: 4,
                      mb: 4,
                    }}
                  >
                    Your test email should be delivered to the marked recipients
                    . If the recipients do not receive the email, please try
                    resending it.
                  </Typography>
                  <div
                    className="footer-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      marginTop: 15,
                    }}
                  >
                    <button
                      className="bordered-button"
                      onClick={() => {
                        setSendTestEmailQuery({
                          EMAIL_ERROR: false,
                          EMAILS: "",
                          EMAIL_SENT: false,
                        });
                      }}
                    >
                      Send Another Sample Email
                    </button>
                    <button
                      className="filled-button"
                      onClick={() => {
                        setTestEmailDrawerState(false);
                        setSendTestEmailQuery({
                          EMAIL_ERROR: false,
                          EMAILS: "",
                          EMAIL_SENT: false,
                        });
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </>
              )}

              {sendTestEmailQuery.EMAIL_SENT == false && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: 17,
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "start",
                      mt: 2,
                      fontFamily: "poppins",
                    }}
                  >
                    Send Sample Email to
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CustomInput
                      Placeholder={
                        "Ex: john@betacodeinfotech.com, joy@betacodeinfotech.com..."
                      }
                      Style={{
                        height: 40,
                      }}
                      Value={sendTestEmailQuery.EMAILS}
                      onChange={(e) => {
                        setSendTestEmailQuery((prev) => ({
                          ...prev,
                          EMAILS: e.target.value,
                          EMAIL_ERROR: false,
                        }));
                      }}
                    />
                  </div>
                  {sendTestEmailQuery.EMAIL_ERROR && (
                    <Typography style={{ fontSize: 14, color: "#d32f2f" }}>
                      Please enter Email ID
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontSize: 17,
                      color: "gray",
                      textAlign: "start",
                      fontFamily: "poppins",
                    }}
                  >
                    Use commas to separate multiple emails
                  </Typography>

                  <div
                    className="footer-buttons"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      marginTop: 15,
                    }}
                  >
                    <button
                      className="bordered-button"
                      onClick={() => {
                        setTestEmailDrawerState(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="filled-button"
                      onClick={() => {
                        sendTestEmail();
                      }}
                      style={{
                        minWidth: 100,
                      }}
                      disabled={transactionFlags.SEND_TEST_EMAIL_LOADING}
                    >
                      {transactionFlags.SEND_TEST_EMAIL_LOADING == true ? (
                        <Spinner />
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </Box>
              )}
            </Box>
          </SwipeableDrawer>

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={CampaignSendDrawerState}
            onClose={() => {
              setCampaignSendDrawerState(false);
            }}
            onOpen={() => {
              setCampaignSendDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Confirmation
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCampaignSendDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <p className="text-means-web recipient-description">
                Are you sure you want to launch the campaign? Please review and
                confirm the recipient details, subject, and email content before
                proceeding with sending.
              </p>
              <div className="campaign-review-container">
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    Subject:
                  </strong>
                  <p className=" text-means-web recipient-description">
                    {emailContentData?.SUBJECT} {emailContentData?.PREVIEW_TEXT}
                  </p>
                </div>
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    To:
                  </strong>
                  <p className=" text-means-web recipient-description">
                    {recipientData && `${recipientData.length} Recipient`}
                  </p>
                </div>
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    Attachments:
                  </strong>
                  <p className=" text-means-web recipient-description"></p>
                </div>
                <div className="campaign-detail">
                  <strong className="text-means-web recipient-description">
                    Email Content:
                  </strong>
                  <div>
                    {emailContentData?.TEMPLATE_HTML && (
                      <button
                        className="prev-email-template"
                        style={{
                          marginRight: 10,
                          height: 50,
                          width: 200,
                        }}
                        onClick={() => {
                          openURLInBrowser(
                            AXIOS.defaultPort + emailContentData?.TEMPLATE_HTML
                          );
                        }}
                      >
                        View Email Preview
                        <img
                          src={previewEmailIcon}
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <button
                  className="filled-button"
                  onClick={() => {
                    sendCampaignEmails();
                  }}
                  style={{
                    minWidth: 200,
                  }}
                  disabled={CampaignErrors.TRANSACTION_STARTED}
                >
                  {CampaignErrors.TRANSACTION_STARTED == true ? (
                    <Spinner />
                  ) : (
                    "Launch Campaign"
                  )}
                </button>
                <button
                  className="bordered-button"
                  onClick={() => {
                    setCampaignSendDrawerState(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Box>
          </SwipeableDrawer>

          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={FromDrawerState}
            onClose={() => {
              setFromDrawerState(false);
            }}
            onOpen={() => {
              setFromDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  From
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFromDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                From Name
                {/* <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span> */}
              </Typography>
              <CustomInput
                Placeholder={"From Name"}
                Style={{
                  height: 40,
                }}
                Value={campaignSubjectData.FROM_NAME}
                onChange={(e) => {
                  setCampaignSubjectData((prev) => ({
                    ...prev,
                    FROM_NAME: e.target.value,
                  }));
                }}
              />
              <Typography
                sx={{
                  fontSize: 17,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                  mt: 2,
                  fontFamily: "poppins",
                }}
              >
                From Email ID
                {/* <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span> */}
              </Typography>
              <CustomInput
                Placeholder={"From Email ID"}
                Style={{
                  height: 40,
                }}
                Disabled={true}
                Value={campaignSubjectData.FROM_EMAIL}
                onChange={(e) => {
                  setCampaignSubjectData((prev) => ({
                    ...prev,
                    FROM_EMAIL: e.target.value,
                  }));
                }}
              />
              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <button
                  className="bordered-button"
                  onClick={() => {
                    setCampaignSendDrawerState(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="filled-button"
                  onClick={() => {
                    campaignSubjectUpdate("FROM_NAME");
                  }}
                  style={{
                    minWidth: 100,
                  }}
                  disabled={transactionFlags.SUBJECT_SAVE_LOADING}
                >
                  {transactionFlags.SUBJECT_SAVE_LOADING == true ? (
                    <Spinner />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Box>
          </SwipeableDrawer>
          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={PersonalizationDrawerState}
            onClose={() => {
              setPersonalizationDrawerState(false);
            }}
            onOpen={() => {
              setPersonalizationDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Add Personalization
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPersonalizationDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Box
                sx={{
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                {/* </Box> */}
                <div className="row" style={{ gap: "10px", padding: "5px" }}>
                  <div className="col-4">
                    <Typography
                      style={{
                        fontSize: 15,
                        color: "#000",
                        fontWeight: 500,
                        textAlign: "start",
                      }}
                    >
                      Custom Greetings
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <CustomInput
                        Placeholder={"Custom Greetings"}
                        // icon={Icons.campaign}
                        Value={customGreetings.CUSTOM_GREETING_NAME}
                        onChange={(e) => {
                          setCustomGreetings((prev) => ({
                            ...prev,
                            CUSTOM_GREETING_NAME: e.target.value,
                            CUSTOM_GREETING_NAME_ERROR: false,
                          }));
                        }}
                      />
                      {customGreetings.CUSTOM_GREETING_NAME_ERROR && (
                        <p className="helper-text">
                          Please enter Campaign Description
                        </p>
                      )}
                      <button
                        className="filled-button"
                        onClick={() => {
                          let tempItems = [...DroppableItems];
                          tempItems.push({
                            id: customGreetings.CUSTOM_GREETING_NAME,
                            content: customGreetings.CUSTOM_GREETING_NAME,
                          });
                          setDroppableItems(tempItems);
                          setCustomGreetings((prev) => ({
                            ...prev,
                            CUSTOM_GREETING_NAME: "",
                            CUSTOM_GREETING_NAME_ERROR: false,
                          }));
                        }}
                        style={{
                          minWidth: 100,
                          marginLeft: 10,
                          backgroundColor: "#007bff",
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <div style={{ display: "flex", gap: "10px", padding: "5px" }}>
                    <Droppable
                      droppableId="draggable-list"
                      isDropDisabled={true}
                    >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            padding: "16px",
                            background: "#e8e8e8",
                            borderRadius: "8px",
                            minHeight: "200px",
                            width: "600px",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "2px",
                          }}
                        >
                          <span style={{ width: "100%", fontWeight: "bold" }}>
                            Select from Greetings and Names
                          </span>

                          {DroppableItems.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    padding: "8px",
                                    background: "#ffffff",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    textAlign: "center",
                                    cursor: "grab",
                                    fontSize: 10,
                                    // width: "calc(20% - 8px)", // 20% width for 5 items per row
                                  }}
                                >
                                  {item.content}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>

                    <Droppable droppableId="droppable-container">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            padding: "16px",
                            background: "#f4f4f4",
                            borderRadius: "8px",
                            // minHeight: "200px",
                            width: "600px", // Adjust the width for 5 items per row
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "8px",
                          }}
                        >
                          <span style={{ width: "100%", fontWeight: "bold" }}>
                            Selected Greetings
                          </span>
                          {droppedItems.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    padding: "8px",
                                    background: "#E0F2E0",
                                    border: "1px solid #E0F2E0",
                                    borderRadius: "4px",
                                    display: "flex",
                                    height: 40,
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    cursor: "grab",
                                    fontSize: 10,
                                    // width: "calc(20% - 8px)",
                                  }}
                                >
                                  {item.content}
                                  <button
                                    onClick={() =>
                                      handleRemoveFromContainer(item)
                                    }
                                    style={{
                                      marginLeft: "8px",
                                      padding: "2px",
                                      background: "#ff4d4d",
                                      color: "#ffffff",
                                      border: "none",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    ✕
                                  </button>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
                <div
                  style={{
                    // backgroundColor: "#E0F2E0",
                    padding: 5,
                    borderRadius: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Style the Greetings here
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 12,
                          color: "#1770e0",
                          display: "flx",
                          alignItems: "center",
                        }}
                      >
                        Not able to style the greeting?
                      </p>
                      <TooltipMaterial title="Please select your desired greeting and copy the line displayed in the editor. Then, paste the copied line into the content section while configuring the email body. Ensure that you do not remove or alter any keywords enclosed within {{ }} brackets, as these are essential for the proper functioning of the email content.">
                        <InfoIcon sx={{ color: "#1770e0" }} />
                      </TooltipMaterial>
                    </div>
                  </div>

                  <div>
                    {droppedItems.length > 0 ? (
                      // <p>{droppedItems.map((val) => val.content).join(" ")}</p>
                      <JoditEditorCustom
                        value={`<p>${droppedItems
                          .map((val) => val.id)
                          .join(" ")}</p>`}
                        onChange={(e) => {
                          setCampaignGreetingData((prev) => ({
                            ...prev,
                            CAMPAIGN_GREETINGS_HTML: e,
                          }));
                        }}
                      />
                    ) : (
                      // <JoditEditorCustom
                      //   value={`<p>${droppedItems.map((val) => val.content).join(" ")}</p>`}
                      // />
                      "Please drag and drop greeting"
                    )}
                  </div>
                </div>

                <div
                  className="footer-buttons"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                  }}
                >
                  <button
                    className="bordered-button"
                    onClick={() => {
                      setPersonalizationDrawerState(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="filled-button"
                    onClick={() => {
                      campaignPersonalizationUpdate();
                    }}
                    style={{
                      minWidth: 100,
                    }}
                    disabled={transactionFlags.PERSONALIZATION_SAVE_LOADING}
                  >
                    {transactionFlags.PERSONALIZATION_SAVE_LOADING == true ? (
                      <Spinner />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Box>
            </Box>
          </SwipeableDrawer>
          <SwipeableDrawer
            anchor={"bottom"}
            PaperProps={{
              sx: {
                borderRadius: "16px 16px 0 0", // Rounded top-left and top-right corners only
                margin: "0 10px 0 10px", // Margin applied to left and right edges
                marginTop: "10px", // Margin applied to the top edge
                overflow: "hidden", // Preserve rounded corners
              },
            }}
            open={AttachmentDrawerState}
            onClose={() => {
              setAttachmentDrawerState(false);
            }}
            onOpen={() => {
              setAttachmentDrawerState(true);
            }}
          >
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 25,
                    color: "#000",
                    textAlign: "start",
                    fontWeight: "bold",
                    fontFamily: "poppin",
                  }}
                >
                  Add Attachments
                </Typography>
                <CancelIcon
                  sx={{
                    width: 40,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setAttachmentDrawerState(false);
                  }}
                />
              </Box>
              <Divider />
              <Box
                sx={{
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                {/* <a
                  className="csv-template-download"
                  style={{
                    marginTop: 10,
                  }}
                  htmlFor="upload"
                  onClick={() => {
                    document.getElementById("upload").click();
                  }}

                  // href="https://pdhanewala.com:9002/recipientExcelTemplate.xlsx"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="70"
                    height="70"
                    fill="currentColor"
                  >
                    <path d="M5 20h14a1 1 0 0 0 1-1v-2h-2v1H6v-1H4v2a1 1 0 0 0 1 1zM11 4v8.586L8.707 10.293l-1.414 1.414L12 16.414l4.707-4.707-1.414-1.414L13 12.586V4h-2z" />
                  </svg>
                  <h3>Upload File</h3>
                  <span>Attach Any file .png, .jpeg, .pdf, .xlsx</span>
                  <input
                    type="file"
                    name="upload"
                    id="upload"
                    multiple
                    hidden={true}
                    onChange={(e) => {}}
                  />
                </a> */}
                <div
                  className="import-csv-main-container"
                  style={{
                    justifyContent: "start",
                  }}
                >
                  <div className="import-csv-container">
                    <input
                      type="file"
                      id="csvInput"
                      multiple
                      onChange={handleAttachmentUpload}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="csvInput" className="upload-label">
                      <div className="upload-box">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/158/158749.png" // Replace with your preferred upload icon
                          alt="Upload"
                          className="upload-icon"
                        />
                        <h3>Upload File</h3>
                        <p className="upload-text">
                          Attach any file .png, .jpeg, .pdf, .xlsx .docx
                        </p>
                      </div>
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        maxHeight: "40vh",
                        overflowY: "auto",
                      }}
                    >
                      {campaignSubjectData.ATTACHMENT.length > 0 && (
                        <div
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          {campaignSubjectData.ATTACHMENT.map((file, index) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#E8F9FF",
                                paddingLeft: 5,
                                paddingRight: 5,
                                borderRadius: 5,
                                marginBottom: 5,
                              }}
                              title={`${file.name} (${(
                                file.size /
                                (1024 * 1024)
                              ).toFixed(2)} MB)`} // Tooltip with full name & size
                            >
                              <p
                                key={index}
                                style={{
                                  margin: 5, // Remove default margin
                                  display: "flex", // Ensure inline arrangement
                                  alignItems: "center",
                                  maxWidth: "250px", // Restrict total width
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    maxWidth: "200px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                  }}
                                >
                                  {file.name}
                                </span>

                                {/* File Size (Low Priority) */}
                                <span
                                  style={{
                                    fontSize: "12px", // Smaller text
                                    color: "#666", // Light color for low priority
                                    marginLeft: "5px",
                                    flexShrink: 0, // Prevents shrinking of file size
                                  }}
                                >
                                  ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                                </span>
                              </p>

                              <span
                                style={{
                                  marginLeft: 10,
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={deleteImage}
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  onClick={() => {
                                    handleDeleteFile(index);
                                  }}
                                />
                              </span>
                            </div>
                          ))}
                          <Divider />
                        </div>
                      )}
                    </div>
                    {campaignSubjectData.ATTACHMENT.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 10,
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#333",
                            marginTop: "10px",
                          }}
                        >
                          Total Size:{" "}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#333",
                            marginTop: "10px",
                          }}
                        >
                          {(
                            campaignSubjectData.ATTACHMENT.reduce(
                              (acc, file) => acc + file.size,
                              0
                            ) /
                            (1024 * 1024)
                          ).toFixed(2)}{" "}
                          MB
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="footer-buttons"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                  }}
                >
                  <button
                    className="bordered-button"
                    onClick={() => {
                      setAttachmentDrawerState(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="filled-button"
                    onClick={() => {
                      campaignSubjectUpdate("ATTACHMENTS");
                    }}
                    style={{
                      minWidth: 100,
                    }}
                    disabled={transactionFlags.ATTACHMENT_SAVE_LOADING}
                  >
                    {transactionFlags.ATTACHMENT_SAVE_LOADING == true ? (
                      <Spinner />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Box>
            </Box>
          </SwipeableDrawer>
        </>
      )}

      {showCampaignDetailsPage.STATUS == true && (
        <Box
          // className="campaign-details-container"
          sx={{
            mt: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 2,
              pb: 1,
            }}
          >
            <Box
              sx={{
                mr: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate("/view-campaign");
              }}
            >
              <IconContext.Provider value={{ size: 25, color: "#000" }}>
                <FaArrowLeftLong />
              </IconContext.Provider>
            </Box>
            <Typography
              style={{
                fontSize: 22,
                fontWeight: 600,
                color: "#000",
                marginRight: 10,
                textAlign: "start",
              }}
            >
              {campaignName.DEFAULT_NAME}{" "}
              <Chip
                label={
                  campaignStatus.find(
                    (item) => item.value == showCampaignDetailsPage.STATUS_CODE
                  )?.label || ""
                }
                // color="success"
                sx={{
                  backgroundColor: "#eaf8e4", // Light green background
                  color: "#2d572c", // Darker green text for contrast
                  fontWeight: "bold", // Make the text stand out
                  border: "1px solid #90ee90", // Border to match the green tone
                  borderRadius: "8px", // Rounded edges for a softer look
                  padding: "4px 12px", // Custom padding for better proportions
                }}
              />
              {/* <Chip
                label={campaignName.PARENT_CAMPAIGN}
                sx={{
                  backgroundColor: "rgba(91, 91, 240, 0.1)", // Light background with transparency
                  color: "rgb(51, 51, 180)", // Darker text color for contrast
                  fontWeight: "bold",
                  border: "1px solid rgb(141, 141, 255)", // Lighter border for a subtle effect
                  borderRadius: "8px",
                  padding: "4px 12px",
                }}
              /> */}
              {((campaignName?.PARENT_CAMPAIGN_NAME &&
                campaignName.PARENT_CAMPAIGN_NAME != "") ||
                childCampaignList.length > 0) && (
                <span
                  onClick={() => {}}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <ButtonGroup
                    ref={childModalOpenRef}
                    aria-label="Button group with a nested menu"
                  >
                    {/* <Chip
                        label={
                          campaignName.PARENT_CAMPAIGN_NAME.length > 50
                            ? campaignName.PARENT_CAMPAIGN_NAME.substring(
                                0,
                                50
                              ) + " ..."
                            : "Parent:" + campaignName.PARENT_CAMPAIGN_NAME
                        }
                        sx={{
                          backgroundColor: "rgba(91, 91, 240, 0.1)", // Light background with transparency
                          color: "rgb(51, 51, 180)", // Darker text color for contrast
                          fontWeight: "bold",
                          border: "1px solid rgb(141, 141, 255)", // Lighter border for a subtle effect
                          borderRadius: "8px",
                          padding: "4px 12px",
                        }}
                      /> */}
                    {campaignName?.PARENT_CAMPAIGN_NAME &&
                    campaignName.PARENT_CAMPAIGN_NAME ? (
                      <span
                        onClick={() => {
                          const searchParams = new URLSearchParams(
                            location.search
                          );
                          searchParams.set(
                            "CAMPAIGN_ID",
                            encodeData(campaignName.PARENT_CAMPAIGN)
                          );

                          // Navigate to the updated URL
                          navigate(
                            `${location.pathname}?${searchParams.toString()}`
                          );
                        }}
                      >
                        <TooltipMaterial
                          title={"Parent:" + campaignName.PARENT_CAMPAIGN_NAME}
                          arrow
                        >
                          <span
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            <Chip
                              label={
                                campaignName.PARENT_CAMPAIGN_NAME
                                  ? campaignName.PARENT_CAMPAIGN_NAME.length >
                                    50
                                    ? campaignName.PARENT_CAMPAIGN_NAME.substring(
                                        0,
                                        50
                                      ) + " ..."
                                    : "Parent:" +
                                      campaignName.PARENT_CAMPAIGN_NAME
                                  : ""
                              }
                              sx={{
                                // backgroundColor: "rgba(91, 91, 240, 0.1)",
                                backgroundColor: "#fff",
                                color: "rgb(51, 51, 180)", // Darker text color for contrast
                                fontWeight: "bold",
                                border: "1px solid rgb(141, 141, 255)", // Lighter border for a subtle effect
                                borderRadius: "0px 10px 0px 10px !important",
                                padding: "4px 12px",
                              }}
                            />
                          </span>
                        </TooltipMaterial>
                      </span>
                    ) : (
                      <Chip
                        label={"Sub Campaigns"}
                        sx={{
                          // backgroundColor: "rgba(91, 91, 240, 0.1)",
                          backgroundColor: "#fff",
                          color: "rgb(51, 51, 180)", // Darker text color for contrast
                          fontWeight: "bold",
                          border: "1px solid rgb(141, 141, 255)", // Lighter border for a subtle effect
                          borderRadius: "0px 10px 0px 10px !important",
                          padding: "4px 12px",
                        }}
                      />
                    )}

                    <Button
                      size="small"
                      aria-controls={
                        childModalOpen ? "split-button-menu" : undefined
                      }
                      aria-expanded={childModalOpen ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={() => {
                        setChildModalOpen((prevOpen) => !prevOpen);
                      }}
                      sx={{
                        // backgroundColor: "rgba(91, 91, 240, 0.1)",
                        backgroundColor: "#fff",
                        color: "rgb(51, 51, 180)", // Darker text color for contrast
                        fontWeight: "bold",
                        border: "1px solid rgb(141, 141, 255)", // Lighter border for a subtle effect
                        borderRadius: "10px 0px 10px 0px !important",
                        padding: "4px 12px",

                        "&:hover": {
                          backgroundColor: "rgba(91, 91, 240, 0.2)", // Slightly darker on hover
                        },
                      }}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{ zIndex: 1 }}
                    open={childModalOpen}
                    anchorEl={childModalOpenRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={(event) => {
                              if (
                                childModalOpenRef.current &&
                                childModalOpenRef.current.contains(event.target)
                              ) {
                                return;
                              }

                              setChildModalOpen(false);
                            }}
                          >
                            <MenuList
                              id="split-button-menu"
                              autoFocusItem
                              sx={{
                                width: 230,
                              }}
                            >
                              {childCampaignList.length == 0 && (
                                <MenuItem>No Sub Campaign found</MenuItem>
                              )}
                              {childCampaignList.length > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      textAlign: "center",
                                    }}
                                  >
                                    Sub Campaigns
                                  </span>
                                </div>
                              )}
                              {childCampaignList.length > 0 && <Divider />}
                              {childCampaignList.map((val, index) => {
                                return (
                                  <MenuItem
                                    key={val._id}
                                    onClick={() => {
                                      // Get the current URL and update only the CAMPAIGN_ID parameter
                                      const searchParams = new URLSearchParams(
                                        location.search
                                      );
                                      searchParams.set(
                                        "CAMPAIGN_ID",
                                        encodeData(val._id)
                                      );

                                      // Navigate to the updated URL
                                      navigate(
                                        `${location.pathname}?${searchParams.toString()}`
                                      );
                                    }}
                                  >
                                    {val.CAMPAIGN_NAME}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  {/* <TooltipMaterial
                      title={campaignName.PARENT_CAMPAIGN_NAME}
                      arrow
                    >
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <Chip
                          label={
                            campaignName.PARENT_CAMPAIGN_NAME.length > 50
                              ? campaignName.PARENT_CAMPAIGN_NAME.substring(
                                  0,
                                  50
                                ) + " ..."
                              : "Parent:" + campaignName.PARENT_CAMPAIGN_NAME
                          }
                          sx={{
                            backgroundColor: "rgba(91, 91, 240, 0.1)", // Light background with transparency
                            color: "rgb(51, 51, 180)", // Darker text color for contrast
                            fontWeight: "bold",
                            border: "1px solid rgb(141, 141, 255)", // Lighter border for a subtle effect
                            borderRadius: "8px",
                            padding: "4px 12px",
                          }}
                        />
                      </span>
                    </TooltipMaterial> */}
                </span>
              )}
            </Typography>
            {/* <h9 className="header-text">Company Data</h9> */}
          </Box>
          <Box sx={{ bgcolor: "rgb(91, 91, 240)" }}>
            <StyledTabs
              value={ReportSelectTab}
              onChange={(event, newValue) => {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set("tab", newValue); // Update only `tab` parameter

                // Navigate while preserving `campaign_id` in state
                navigate(`?${searchParams.toString()}`, {
                  state: { campaign_id: location.state?.campaign_id },
                });
                // navigate(`?tab=${newValue}`, {
                //   state: {
                //     campaign_id: selectedCampaignId,
                //   },
                // });
                setReportSelectTab(newValue);
              }}
              aria-label="styled tabs example"
            >
              <StyledTab label="Overview" />
              <StyledTab label="Click Performance" />
              <StyledTab label="Campaign Preview" />
              <StyledTab label="HTML Source" />
              <StyledTab label="Details" />
            </StyledTabs>
          </Box>
          {ReportSelectTab == 0 && (
            <>
              <Box className="campaign-analysis-grid-container">
                {campaignAnalysis.map((val) => {
                  return (
                    <div className="campaign-analysis-card">
                      <span className="campaign-analysis-time-text">
                        {val.VALUE}
                      </span>
                      <br />
                      <span className="campaign-analysis-text">
                        {val.TITLE}
                      </span>
                    </div>
                  );
                })}
              </Box>

              <Card
                sx={{
                  p: 1,
                  m: 1,
                  // pb: 10,
                  // height: 500,
                }}
              >
                <Box className="performance-container">
                  {/* Left Section */}
                  <Box className="left-section">
                    <Typography className="title">
                      24 hour performance snapshot
                    </Typography>
                    {/* <Typography className="subtitle">
                      Nov 17, 2024 - Nov 18, 2024
                    </Typography> */}
                  </Box>

                  {/* Right Section */}
                  <Box className="right-section">
                    {/* Email Status Dropdown */}
                    <Box className="dropdown-box">
                      <Typography className="dropdown-label">
                        Email Status
                      </Typography>
                      <CustomDropdown
                        isSearchable={false}
                        Options={[
                          { label: "Delivery", value: "DELIVERY" },
                          { label: "Opened", value: "OPENED" },
                          { label: "Bounced", value: "BOUNCED" },
                        ]}
                        Value={Last24HrPerformanceQuery.EMAIL_STATUS}
                        OnChange={(e) => {
                          setLast24HrPerformanceQuery((prev) => ({
                            ...prev,
                            EMAIL_STATUS: e,
                          }));
                        }}
                      />
                    </Box>

                    {/* Time Slot Dropdown */}
                    <Box className="dropdown-box">
                      <Typography className="dropdown-label">
                        Time slot
                      </Typography>
                      <CustomDropdown
                        isSearchable={false}
                        Options={[
                          { label: "30 Minutes", value: 30 },
                          { label: "1 Hour", value: 60 },
                          { label: "2 Hour", value: 120 },
                          { label: "4 Hour", value: 240 },
                        ]}
                        Value={Last24HrPerformanceQuery.INTERVAL}
                        OnChange={(e) => {
                          setLast24HrPerformanceQuery((prev) => ({
                            ...prev,
                            INTERVAL: e,
                          }));
                        }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Line
                  style={{
                    width: "100%",
                  }}
                  data={{
                    labels: Last24HrPerformanceData.LABELS,
                    datasets: [
                      {
                        label: Last24HrPerformanceData.TITLE,
                        data: Last24HrPerformanceData.COUNT, // Example data
                        borderColor: Last24HrPerformanceData.COLOR, // Purple color
                        backgroundColor: Last24HrPerformanceData.COLOR,
                        pointBorderColor: Last24HrPerformanceData.COLOR,
                        pointBackgroundColor: "#fff",
                        pointHoverBackgroundColor:
                          Last24HrPerformanceData.COLOR,
                        pointHoverBorderColor: Last24HrPerformanceData.COLOR,
                        borderWidth: 2,
                        tension: 0.4, // Smooth curve
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      title: {
                        display: true,
                        text: "24-hour performance snapshot",
                        font: {
                          size: 18,
                          weight: "bold",
                        },
                      },
                      tooltip: {
                        enabled: false, // Disable tooltips
                      },
                    },
                    scales: {
                      x: {
                        title: {
                          display: true,
                          text: "Time",
                        },
                      },
                      y: {
                        title: {
                          display: true,
                          text: "Percentage",
                        },
                        min: 0,
                        max: 100,
                        ticks: {
                          callback: (value) => `${value}%`,
                        },
                      },
                    },
                    elements: {
                      point: {
                        radius: 3, // Customize the dot radius
                        pointStyle: "circle", // Keep dots visible
                        hoverRadius: 4,
                      },
                      line: {
                        tension: 0.4, // Smooth line
                      },
                    },
                    plugins: {
                      datalabels: {
                        display: false, // Disable data labels entirely
                      },
                    },
                  }}
                />
              </Card>
            </>
          )}
          {ReportSelectTab == 1 && (
            <Box
              sx={{
                mt: 1,
              }}
            >
              <Card
                sx={{
                  p: 1,
                }}
              >
                <Box
                  className="as-inner-container"
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: 0.5,
                  }}
                >
                  <div
                    className="fields-width"
                    style={{
                      minWidth: 200,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#000",
                        textAlign: "start",
                      }}
                    >
                      Delivery Status
                    </Typography>
                    <CustomDropdown
                      menuPortalTarget={true}
                      Options={deliveryStatusArr}
                      Value={emailRecipientQuery.DELIVERY_STATUS}
                      OnChange={(e) => {
                        setEmailRecipientQuery((prev) => ({
                          ...prev,
                          DELIVERY_STATUS: e,
                        }));
                      }}
                    />
                  </div>
                  {/* <div className="fields-width">
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#000",
                        textAlign: "start",
                      }}
                    ></Typography>
                    <br />
                   
                  </div> */}
                  <div className="fields-width">
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#000",
                        textAlign: "start",
                      }}
                    ></Typography>
                    <br />
                    <button
                      className="filled-button"
                      onClick={() => {
                        handleClickPerformanceDataExport();
                      }}
                      style={{
                        minWidth: 100,
                        marginRight: 10,
                        backgroundColor: "#1770e0",
                      }}
                    >
                      Export
                    </button>

                    <button
                      className="filled-button"
                      onClick={handleOpenUserMenu}
                      style={{
                        minWidth: 100,
                      }}
                    >
                      Create Sub Campaign
                    </button>
                  </div>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMountedF
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {subCampaignOptions.map((val, index) => {
                      return (
                        <>
                          <MenuItem
                            onClick={() => {
                              setCampaignCreateModal(true);
                              setCreateCampaignData((prev) => ({
                                ...prev,
                                SELECTED_RECIPIENT_TYPE: val.value,
                              }));
                            }}
                          >
                            {val.label}
                          </MenuItem>
                          {subCampaignOptions.length - 1 != index && (
                            <Divider />
                          )}
                        </>
                      );
                    })}
                  </Menu>
                </Box>

                <ReactDataTable
                  columns={recipientCampaignColumns}
                  data={recipientData}
                  pageCount={9}
                />
              </Card>
            </Box>
          )}

          {ReportSelectTab == 2 && (
            <Box>
              <div style={{ textAlign: "center", margin: "20px" }}>
                {previewUrl?.HTML_PREVIEW && (
                  <div
                    style={{
                      marginTop: "20px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      src={previewUrl?.HTML_PREVIEW}
                      title="Link Preview"
                      style={{
                        width: "100%",
                        height: "500px",
                        border: "none",
                      }}
                    />
                  </div>
                )}
              </div>
            </Box>
          )}
          {ReportSelectTab == 3 && (
            // <Box>{previewUrl.HTML_TEXT}</Box>
            <div
              style={{
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#f9f9f9",
                padding: "10px",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  position: "relative",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  backgroundColor: "#f9f9f9",
                  padding: "10px",
                  overflowX: "auto",
                }}
              >
                {/* Copy Button */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={() => {
                      handleCopy(previewUrl?.HTML_TEXT);
                    }}
                    style={{
                      backgroundColor: copied ? "#4caf50" : "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "3px",
                      padding: "5px 10px",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {copied ? (
                      <>
                        <CheckCircleIcon style={{ fontSize: "16px" }} />
                        Copied
                      </>
                    ) : (
                      <>
                        <ContentCopyIcon style={{ fontSize: "16px" }} />
                        Copy Code
                      </>
                    )}
                  </button>
                </div>

                {/* Syntax Highlighter */}
                <SyntaxHighlighter
                  language="html"
                  showLineNumbers
                  lineNumberStyle={{ color: "#999", marginRight: "10px" }}
                >
                  {previewUrl?.HTML_TEXT}
                </SyntaxHighlighter>
              </div>
            </div>
          )}

          {/* Details tab */}
          {ReportSelectTab == 4 && (
            <Box>
              <div className="campaign-report-wrapper">
                <div className="campaign-report-container">
                  <h1 className="campaign-report-heading">
                    Campaign Report Details
                  </h1>
                  <div className="campaign-detail">
                    <strong>To:</strong>
                    <p>{recipientData && recipientData.length} Recipient</p>
                  </div>
                  <div className="campaign-detail">
                    <strong>Subject line:</strong>
                    <p>{emailContentData?.SUBJECT}</p>
                  </div>

                  <div className="campaign-detail">
                    <strong>Preview text:</strong>
                    <p>{emailContentData?.PREVIEW_TEXT}</p>
                  </div>

                  <div className="campaign-detail">
                    <strong>Campaign Create at:</strong>
                    <p>
                      {campaignData.CREATED_TIME
                        ? new Date(campaignData.CREATED_TIME).toDateString()
                        : ""}{" "}
                      {campaignData.CREATED_TIME
                        ? new Date(campaignData.CREATED_TIME).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                              hour12: true,
                            }
                          )
                        : "No Date Available"}
                    </p>
                  </div>
                  <div className="campaign-detail">
                    <strong>Campaign started date & time:</strong>
                    <p>
                      {campaignData.CAMPAIGN_START_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_START_TIME
                          ).toDateString()
                        : ""}{" "}
                      {campaignData.CAMPAIGN_START_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_START_TIME
                          ).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                          })
                        : "No Date Available"}
                    </p>
                  </div>
                  <div className="campaign-detail">
                    <strong>Delivery date & time:</strong>
                    <p>
                      {campaignData.CAMPAIGN_COMPLETE_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_COMPLETE_TIME
                          ).toDateString()
                        : ""}{" "}
                      {campaignData.CAMPAIGN_COMPLETE_TIME
                        ? new Date(
                            campaignData.CAMPAIGN_COMPLETE_TIME
                          ).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true,
                          })
                        : "No Date Available"}
                    </p>
                  </div>
                  <div className="campaign-detail">
                    <strong>Email Content URL:</strong>
                    <a
                      href={AXIOS.defaultPort + emailContentData?.TEMPLATE_HTML}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {emailContentData?.TEMPLATE_HTML &&
                        AXIOS.defaultPort + emailContentData?.TEMPLATE_HTML}
                    </a>
                  </div>
                  {/* <div className="campaign-detail">
                    <strong>From name:</strong>
                    <p>{emailContentData?.FROM_NAME}</p>
                  </div> */}
                  {/* <div className="campaign-detail">
                    <strong>From email:</strong>
                    <a href={`mailto:${emailContentData?.FROM_EMAIL}`}>
                      {emailContentData?.FROM_EMAIL}
                    </a>
                  </div> */}
                </div>
              </div>
            </Box>
          )}
        </Box>
      )}
      <Modal
        open={campaignCreateModal}
        onClose={() => {
          setCampaignCreateModal(false);
          setCreateCampaignData((prev) => ({
            ...prev,
            CAMPAIGN_COMPLETED: false,
          }));
        }}
        disableAutoFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="edit-modal-container"
          sx={{
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: 17,
                color: COLORS.gray70,
                fontWeight: "bold",
              }}
            >
              Create Sub Campaign
            </Typography>

            <div
              style={{
                cursor: "pointer",
              }}
            >
              <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                <RxCross1
                  onClick={() => {
                    setCampaignCreateModal(false);
                    setCreateCampaignData((prev) => ({
                      ...prev,
                      CAMPAIGN_COMPLETED: false,
                    }));
                  }}
                />
              </IconContext.Provider>
            </div>
          </Box>
          <Divider />

          {createCampaignData?.CAMPAIGN_COMPLETED == false && (
            <>
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <Typography
                  style={{
                    fontSize: 15,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Sub Campaign Name
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <CustomInput
                  Placeholder={"Campaign Name"}
                  // icon={Icons.campaign}
                  Value={createCampaignData.CAMPAIGN_NAME}
                  onChange={(e) => {
                    setCreateCampaignData((prev) => ({
                      ...prev,
                      CAMPAIGN_NAME: e.target.value,
                      CAMPAIGN_NAME_ERROR: false,
                    }));
                  }}
                />
                {createCampaignData.CAMPAIGN_NAME_ERROR && (
                  <p className="helper-text">
                    Please enter Campaign Description
                  </p>
                )}

                <Typography
                  style={{
                    fontSize: 15,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Description
                </Typography>
                <div className="group">
                  <textarea
                    rows={5}
                    className="text-box-style"
                    style={{
                      height: 70,
                      fontFamily: "sans-serif",
                    }}
                    placeholder={"Description"}
                    // icon={Icons.campaign}
                    value={createCampaignData.NAME}
                    onChange={(e) => {
                      setCreateCampaignData((prev) => ({
                        ...prev,
                        CAMPAIGN_DESCRIPTION: e.target.value,
                        CAMPAIGN_DESCRIPTION_ERROR: false,
                      }));
                    }}
                  />
                </div>
                {createCampaignData.CAMPAIGN_DESCRIPTION_ERROR && (
                  <p className="helper-text">
                    Please enter Campaign Description
                  </p>
                )}
              </Box>
              <div
                className="as-inner-container"
                style={{
                  paddingTop: 20,
                  minHeight: 120,
                }}
              >
                {subCampaignOptions.map((val) => {
                  return (
                    <Card
                      onClick={() => {
                        setCreateCampaignData((prev) => ({
                          ...prev,
                          SELECTED_RECIPIENT_TYPE: val.value,
                        }));
                      }}
                      sx={{
                        p: 1,

                        border:
                          createCampaignData.SELECTED_RECIPIENT_TYPE ==
                          val.value
                            ? "2px solid #1770e0"
                            : "",
                        borderRadius: 3,

                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <span>{val.label}</span>

                        {createCampaignData.SELECTED_RECIPIENT_TYPE ==
                          val.value && (
                          <CheckCircleIcon
                            sx={{
                              color: "#1770e0", // Blue color for the check mark
                              fontSize: 24, // Adjust size as needed
                              position: "absolute", // Absolute positioning
                              right: -2, // Distance from the right edge
                              top: 12, // Vertically center
                              transform: "translateY(-50%)", // Adjust to center relative to the card height
                            }}
                          />
                        )}
                      </div>
                      <span
                        style={{
                          fontSize: 13,
                          color: val.TOTAL_COUNT == 0 ? "red" : "#1770e0",
                        }}
                      >
                        Total Contacts: {val.TOTAL_COUNT}
                      </span>
                      {/* <br />
                      {val.TOTAL_COUNT == 0 && (
                        <span
                          style={{
                            color: "red",
                            fontSize: 13,
                          }}
                        >
                          (Not suggested)
                        </span>
                      )} */}
                    </Card>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={{
                    minWidth: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    padding: 5,
                    marginTop: 10,
                    backgroundColor: "#fff",
                    color: "black",
                  }}
                  onClick={() => {
                    setCampaignCreateModal(false);
                  }}
                  className="signup-button"
                >
                  Cancel
                </button>
                <button
                  style={{
                    minWidth: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    padding: 5,
                    marginTop: 10,
                    backgroundColor: "#1770e0",
                  }}
                  onClick={() => {
                    createSubCampaign();
                  }}
                  className="signup-button"
                >
                  Add Campaign
                  <CampaignIcon />
                </button>
              </div>
            </>
          )}
          {createCampaignData?.CAMPAIGN_COMPLETED == true && (
            <>
              <img
                src={EmailCampaignCreated}
                style={{
                  width: "100%",
                  height: 300,
                }}
              />
              <p
                className="text-means-web recipient-description"
                style={{
                  textAlign: "center",
                }}
              >
                The campaign has been created Successfully
              </p>
              <div
                className="footer-buttons"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <button
                  className="bordered-button"
                  onClick={() => {
                    setCampaignCreateModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="filled-button"
                  onClick={() => {
                    setCampaignCreateModal(false);
                    if (createCampaignData.CAMPAIGN_ID) {
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set(
                        "CAMPAIGN_ID",
                        encodeData(createCampaignData.CAMPAIGN_ID)
                      );
                      navigate(
                        `${location.pathname}?${searchParams.toString()}`
                      );
                    } else {
                      toast.error("Campaign ID not found");
                    }
                  }}
                >
                  View Details
                </button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </MainScreen>
  );
}

// export default CampaignDetail;
const mapStateToProps = (state) => ({
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
});

export default connect(mapStateToProps, {})(CampaignDetail);
